import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  checkIsPlaceholder,
  getPictureById,
} from "../../selectors/pictures.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  ContainerQueries,
  ImageModuleSettings,
  ImagesModuleSettings,
  Modules,
  OverlayModuleSettings,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getModulesByParentId,
  getPlaceholderImage,
  getRecursiveOverlayModule,
  getTranslatedModule,
  keys,
} from "../../utils/utils.js";
import { minWidthBreakpoints } from "../ContainerQueries.js";
import ImageSlider from "../ImageSlider.js";
import ImagesSeparator from "../ImagesSeparator.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

interface Props extends BaseModuleProps<ImagesModuleSettings> {
  isInsideHeader?: boolean;
  parentScheme?: ColorScheme;
  fullHeight?: boolean;
  className?: string;
}

interface StateProps {
  images: TranslatedModule<ImageModuleSettings>[];
  mediaLibraryLoaded: boolean;
  scheme: ColorScheme;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
  subModuleIsActive: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ImagesModule: FunctionComponent<Props & ReduxProps> = ({
  images,
  translatedModule,
  translatedModule: {
    settings: { imagesType, columnsCount, textAlign, width },
    translation: {
      settings: { title, subtitle },
    },
  },
  mediaLibraryLoaded,
  isPreview,
  isInsideHeader = false,
  scheme,
  pageId,
  overlayModule,
  activeModuleId,
  subModuleIsActive,
  fullHeight = false,
  isFirstOnPage,
  queries,
  className,
}) => {
  if (!mediaLibraryLoaded) return null;

  const currentWidthColumnsCount = getBreakpointImageColumnsCount(
    queries,
    columnsCount
  );

  // Check if images fill all columns using one or more rows and show a separator
  // in that case, otherwise show a slider to avoid empty spaces among rows.
  // If the images fit in one row or don’t fill all columns in a row, always show
  // the a separator, not a slider.
  // On very narrow viewport widths (with max 1 column), always show a slider
  // if there are multiple images.
  const imagesFillColumns = !(images.length % currentWidthColumnsCount);
  const imagesFitInOneRow = images.length <= currentWidthColumnsCount;
  const isOneColumnAndMultipleImages =
    !queries["Query--small"] && images.length > 1;

  const isSeparator =
    ((imagesFillColumns && imagesType === "separator") || imagesFitInOneRow) &&
    !isOneColumnAndMultipleImages;

  return (
    <ModuleWithHeadings
      className={ClassNames(
        "ImagesModule",
        { "ImagesModule--full-height": fullHeight },
        className
      )}
      id={translatedModule.id}
      colors={{ background: scheme.main.background }}
      title={!isInsideHeader ? title : undefined}
      subtitle={!isInsideHeader ? subtitle : undefined}
    >
      {!isInsideHeader && (title || subtitle) && (
        <div className="ImagesModule__HeadingsWrapper Module__Wrapper Module__Wrapper--small-padding">
          <ModuleHeadings
            textAlign={textAlign}
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            title={title}
            subtitle={subtitle}
          />
        </div>
      )}

      <div
        className={ClassNames(
          "ImagesModule__Inner",
          `ImagesModule__Inner--width-${width}`
        )}
      >
        {isSeparator ? (
          <ImagesSeparator
            pageId={pageId}
            images={images}
            settings={translatedModule.settings}
            isInsideHeader={isInsideHeader}
            scheme={scheme}
            isPreview={isPreview}
          />
        ) : (
          <ImageSlider
            pageId={pageId}
            isPreview={isPreview}
            images={images}
            translatedModule={translatedModule}
            isInsideHeader={isInsideHeader}
            scheme={scheme}
            overlayModule={overlayModule}
            activeModuleId={activeModuleId}
            subModuleIsActive={subModuleIsActive}
            slidesToShow={columnsCount}
            queries={queries}
          />
        )}
      </div>
    </ModuleWithHeadings>
  );
};

const getHasOverlaySubmodules = (
  modules: Modules,
  images: TranslatedModule<ImageModuleSettings>[],
  pageId: string
): boolean => {
  const hasOverlaySubmodules: boolean = !!images.reduce<number>(
    (count, { id }) => {
      const currentCount = getModulesByParentId<OverlayModuleSettings>(
        modules,
        id,
        pageId,
        "OverlayModule"
      );

      return count + currentCount.length;
    },
    0
  );

  return hasOverlaySubmodules;
};

/**
 * Check if a module or its submodules are active
 */
const checkModuleActive = (
  activeId: string | undefined,
  currentId: string,
  modules: Modules
): boolean => {
  if (activeId === currentId) return true;
  if (!activeId) {
    return false;
  }

  const module = modules.byId[activeId];
  if (!module) return false;

  return checkModuleActive(module.parentId || undefined, currentId, modules);
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { mediaLibrary, modules, loadStates, colorSchemes, sites },
  {
    translatedModule,
    translatedModule: {
      pageId,
      id: moduleId,
      translation: { languageId },
    },
    parentScheme,
    pageId: modulePageId,
    activeModuleId,
  }
): StateProps => {
  const site = getActiveSite(sites);

  const subModuleIsActive = checkModuleActive(
    activeModuleId,
    moduleId,
    modules
  );

  const images = !pageId
    ? []
    : getModulesByParentId<ImageModuleSettings>(
        modules,
        moduleId,
        pageId,
        "ImageModule"
      ).reduce<TranslatedModule<ImageModuleSettings>[]>((images, module) => {
        const image = getTranslatedModule(module, languageId);
        const picture = getPictureById(
          mediaLibrary.pictures,
          image.settings.pictureId
        );
        // // If the are multiple placeholder images (e.g. due to deleted EasyChannel images)
        // // filter them out
        return checkIsPlaceholder(picture) ? images : [...images, image];
      }, []);

  const hasOverlaySubmodules = getHasOverlaySubmodules(
    modules,
    images,
    modulePageId
  );

  const overlayModule = hasOverlaySubmodules
    ? undefined
    : getRecursiveOverlayModule({
        languageId,
        modules,
        pageId: modulePageId,
        parentId: moduleId,
      });

  return {
    images:
      images.length || !pageId
        ? images
        : [
            getPlaceholderImage({
              siteId: site.id,
              pageId,
              languageId,
              parentId: moduleId,
              index: 0,
            }),
          ],
    mediaLibraryLoaded: loadStates.mediaLibrary.pictures === "loaded",
    scheme:
      parentScheme ||
      getActiveColorScheme(colorSchemes, site, translatedModule),
    overlayModule,
    subModuleIsActive,
  };
};

export const getBreakpointImageColumnsCount = (
  queries: ContainerQueries,
  columnsCountSetting: number
): number => {
  const maxSlidesPerBreakpoint: { [key in keyof ContainerQueries]: number } = {
    "Query--small": 2,
    "Query--medium": 3,
    "Query--large": 4,
    "Query--xlarge": 5,
    "Query--xxlarge": 6,
  };

  const currentBreakpoint = keys(minWidthBreakpoints)
    .reverse()
    .find((key) => queries[key]);

  return currentBreakpoint
    ? Math.min(columnsCountSetting, maxSlidesPerBreakpoint[currentBreakpoint])
    : 1;
};

const connector = connect(mapStateToProps);

export default connector(ImagesModule);
