import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import { checkIsPlaceholder } from "../selectors/pictures.js";
import {
  AspectRatio,
  ColorScheme,
  HighlightsItem,
  Language,
} from "../types/index.js";
import ConditionalLink from "./ConditionalLink.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ReadMoreCollapse from "./ReadMoreCollapse.js";

interface Props {
  item: HighlightsItem;
  scheme: ColorScheme;
  mediaAspectRatio: AspectRatio;
  isPreview: boolean;
  isPlaceholderItem: boolean;
  languageId: Language;
  collapsedLinesCount: number | undefined;
  isEditing: boolean;
  index: number;
  hasHighlightsTitle: boolean;
  isFirstOnPage: boolean;
}

const MultiBoxItem: FunctionComponent<Props> = ({
  item: {
    title,
    textAlign,
    subtitle,
    picture,
    link = {},
    text,
    imageDetail,
    buttons,
  },
  scheme,
  mediaAspectRatio,
  isPreview,
  isPlaceholderItem,
  languageId,
  collapsedLinesCount,
  isEditing,
  index,
  hasHighlightsTitle,
  isFirstOnPage,
}) => {
  const showPicture = !checkIsPlaceholder(picture) || isPlaceholderItem;

  return (
    <section
      className={ClassNames(
        "MultiBox__Item",
        style({
          background: scheme.main.background,
          color: scheme.main.text,
        })
      )}
    >
      <div className="MultiBox__Item__Inner">
        {showPicture && (
          <div className="MultiBox__Item__Picture">
            <ConditionalLink anchorAttrs={link} isPreview={isPreview}>
              <CroppedImage
                aspectRatio={mediaAspectRatio}
                picture={picture}
                width={1280}
                detail={imageDetail}
                sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
              />
            </ConditionalLink>
          </div>
        )}

        <div className="MultiBox__Item__Content">
          <div>
            <HighlightHeading
              className="MultiBox__Item__Header"
              title={{
                value: title,
                className: ClassNames(
                  "MultiBox__Item__Title",
                  `TextAlign--${textAlign.title}`,
                  style({
                    color: scheme.main.title,
                  })
                ),
                children: (
                  <ConditionalLink
                    anchorAttrs={link}
                    className="MultiBox__Item__HeaderLink"
                    isPreview={isPreview}
                  >
                    {title}
                  </ConditionalLink>
                ),
              }}
              subtitle={{
                value: subtitle,
                className: `MultiBox__Item__Subtitle TextAlign--${textAlign.subtitle}`,
              }}
              highlightIndex={index}
              hasHighlightsTitle={hasHighlightsTitle}
              isFirstOnPage={isFirstOnPage}
            />

            {text && (
              <div className="MultiBox__Item__Description">
                {collapsedLinesCount !== undefined ? (
                  <ReadMoreCollapse
                    backgroundColor={scheme.main.background}
                    languageId={languageId}
                    collapsedLinesCount={collapsedLinesCount}
                    isEditing={isEditing}
                  >
                    {text}
                  </ReadMoreCollapse>
                ) : (
                  text
                )}
              </div>
            )}
          </div>
          <div className="MultiBox__Item__Bar">
            {buttons && (
              <div
                className={`ButtonGroup ButtonGroup--full-width ButtonGroup--align-${textAlign.buttons}`}
              >
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultiBoxItem;
