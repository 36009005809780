import { FunctionComponent } from "react";
import { Accommodation, ActionLinks, Language } from "../types/index.js";
import { getActionAndContactLinks } from "../utils/utils.js";
import Icon from "./Icon.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  actionLinks: ActionLinks;
  accommodation: Accommodation | undefined;
  languageId: Language;
}

const HeaderActionLinks: FunctionComponent<Props> = ({
  isPreview,
  actionLinks,
  accommodation,
  languageId,
}) => {
  const items = getActionAndContactLinks(
    accommodation,
    actionLinks,
    languageId
  );

  return !items.length ? null : (
    <div className="HeaderActionLinks">
      <ul className="HeaderActionLinks__List">
        {items.map(({ children: content, icon, href, type, title }) => (
          <li className="HeaderActionLinks__ListItem" key={type}>
            <SmartLink
              isPreview={isPreview}
              anchorAttrs={{
                href,
                className: "HeaderActionLinks__LinkItem",
              }}
            >
              <Icon glyph={icon} className="HeaderActionLinks__Icon" />
              <span className="HeaderActionLinks__Text">
                {type === "email" ? title : content}
              </span>
            </SmartLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderActionLinks;
