import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { ActionLinks } from "../../types/index.js";
import { getEnquiryBookingLinks } from "../../utils/utils.js";
import SmartLink from "../SmartLink.js";

interface Props {
  actionLinks: ActionLinks;
  className: string;
  isPreview: boolean;
  isVisible: boolean;
  onClick: () => void;
}

const QuickButtons: FunctionComponent<Props> = ({
  actionLinks,
  className,
  isPreview,
  isVisible,
  onClick,
}) => {
  const links = getEnquiryBookingLinks(actionLinks);

  return !links.length ? null : (
    <div
      className={ClassNames(
        "QuickButtons",
        {
          "QuickButtons--hidden": !isVisible,
        },
        className
      )}
    >
      {links.map(({ label, href, type }) => (
        <div key={type} className="QuickButtons__Item">
          <SmartLink
            isPreview={isPreview}
            anchorAttrs={{
              className: "QuickButtons__Button",
              children: label,
              href,
              onClick,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default QuickButtons;
