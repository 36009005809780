import { FunctionComponent, useEffect, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import isInt from "validator/lib/isInt.js";
import {
  AspectRatio,
  FormModuleSettings,
  HighlightsDisplayType,
  HighlightsLayout,
  IconName,
  SelectOption,
  type HighlightsModuleSettings,
} from "../../types/index.js";
import { getMapDispatchToPropsForModuleSetting } from "../../utils/utils.js";
import AspectRatioSelection from "../AspectRatioSelection.js";
import Checkbox from "../Checkbox.js";
import FormFieldRadios from "../FormFieldRadios.js";
import FormInfo from "../FormInfo.js";
import MediaAlignSetting from "../MediaAlignSetting.js";
import ModuleColorSchemeSelection from "../ModuleColorSchemeSelection.js";
import NumberFormField from "../NumberFormField.js";
import SelectFormField from "../SelectFormField.js";
import SubModulesList from "../SubModulesList.js";
import TitleAndSubtitleSettings from "../TitleAndSubtitleSettings.js";

type ReduxProps = ConnectedProps<typeof connector>;

type Props = FormModuleSettings<HighlightsModuleSettings>;

interface DisplayType {
  value: HighlightsDisplayType;
  title: string;
  iconGlyphs: IconName;
}

const displayTypes: DisplayType[] = [
  {
    value: "list",
    title: "Liste",
    iconGlyphs: "separator",
  },
  {
    value: "slider",
    title: "Slider",
    iconGlyphs: "slider",
  },
];

const layouts: SelectOption<HighlightsLayout>[] = [
  { value: "layout-1", label: "Layout 1" },
  { value: "layout-2", label: "Layout 2" },
  { value: "layout-3", label: "Layout 3" },
  { value: "layout-4", label: "Layout 4" },
  { value: "layout-5", label: "Layout 5" },
  { value: "layout-6", label: "Layout 6" },
  { value: "layout-7", label: "Layout 7" },
  { value: "layout-8", label: "Layout 8" },
  { value: "layout-9", label: "Layout 9" },
];

const layoutDescriptions: { [layout in HighlightsLayout]?: string } = {
  "layout-4": "Dieses Layout ist für 3 Highlights geeignet.",
  "layout-5":
    "Dieses Layout ist für 2–4 Highlights geeignet. " +
    "Es werden nur maximal die ersten 4 Highlights angezeigt. " +
    "Wählen Sie ein anderes Layout, um über 4 Highlights anzuzeigen.",
  "layout-9": "Dieses Layout ist für mindestens 3 Highlights geeignet.",
};

export const getDynamicSettings = (layout: HighlightsLayout) => {
  const checkIncludesLayout = (
    layout: HighlightsLayout,
    layouts: HighlightsLayout[]
  ) => layouts.includes(layout);

  const reducedAspectRatios: AspectRatio[] = [1.3333, 1, 0.75];

  return {
    displayType: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-6",
      "layout-7",
      "layout-8",
    ]),
    mediaAlign: checkIncludesLayout(layout, ["layout-6", "layout-7"]),
    mediaAspectRatio: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-7",
    ]),
    maxColumnsCount: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-6",
      "layout-8",
    ]),
    highlightTextAlign: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-7",
      "layout-8",
    ]),
    description: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-6",
      "layout-7",
      "layout-8",
    ]),
    aspectRatios: checkIncludesLayout(layout, ["layout-7"])
      ? reducedAspectRatios
      : undefined,
    transparentSliderArrowBackground: checkIncludesLayout(layout, [
      "layout-4",
      "layout-6",
      "layout-7",
      "layout-8",
      "layout-9",
    ]),
    onlyOneButton: checkIncludesLayout(layout, [
      "layout-3",
      "layout-4",
      "layout-5",
    ]),
  };
};

const HighlightsModuleSettings: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  translatedModule: {
    id: parentModuleId,
    siteId,
    settings: {
      displayType,
      mediaAspectRatio,
      layout,
      maxColumnsCount,
      textAlign,
      collapsedLinesCount,
      transparentSliderArrowBackground,
      mediaAlign,
    },
    translation: {
      settings: { title, subtitle },
    },
  },
  pageId,
  languageId,
  setModuleSetting,
}) => {
  const dynamicSettings = getDynamicSettings(layout);
  const useMaxColumnsCountFromPropsRef = useRef(false);
  const prevSelectedLayoutRef = useRef(layout);

  useEffect(() => {
    // only proceed if the layout changed, otherwise it
    // sends a request on each mount and conflicts
    // with the HighlightModules
    if (prevSelectedLayoutRef.current === layout) return;

    const maxAllowedColumns = getMaxColumns(layout);
    (maxColumnsCount > maxAllowedColumns || maxColumnsCount < 1) &&
      setModuleSetting({
        global: { maxColumnsCount: maxAllowedColumns },
      });

    prevSelectedLayoutRef.current = layout;
  }, [layout, maxColumnsCount]);

  const maxColumns = getMaxColumns(layout);
  const isMaxColumnsCountValid = checkIsMaxColumnsCountValid({
    value: maxColumns,
    maxColumnsCount,
  });

  return (
    <>
      <TitleAndSubtitleSettings
        onTextAlignChange={(newTextAlign) => {
          setModuleSetting({
            global: {
              textAlign: {
                ...textAlign,
                ...newTextAlign,
              },
            },
          });
        }}
        onTitleAndSubtitleChange={({ title, subtitle }) => {
          setModuleSetting({
            language: { title, subtitle },
          });
        }}
        subtitle={subtitle}
        textAlign={textAlign}
        title={title}
      />

      <SelectFormField<HighlightsLayout>
        label="Layout"
        onChange={(value) => {
          const maxColumns = getMaxColumns(value);
          const isMaxColumnsCountValid = checkIsMaxColumnsCountValid({
            value: maxColumns,
            maxColumnsCount,
          });
          useMaxColumnsCountFromPropsRef.current = !isMaxColumnsCountValid;
          const { aspectRatios } = getDynamicSettings(value);
          setModuleSetting({
            global: {
              layout: value,
              mediaAspectRatio: aspectRatios?.[0] ?? mediaAspectRatio,
              maxColumnsCount:
                value === "layout-6" || value === "layout-8"
                  ? 1
                  : isMaxColumnsCountValid
                  ? maxColumnsCount
                  : maxColumns,
            },
          });
        }}
        value={layout}
        options={layouts}
      >
        {layoutDescriptions[layout] && (
          <FormInfo>{layoutDescriptions[layout]}</FormInfo>
        )}
      </SelectFormField>

      {dynamicSettings.maxColumnsCount && (
        <NumberFormField
          allowEmpty={false}
          errorMessage={
            "Die eingestellte Anzahl der Spalten liegt außerhalb des zulässigen Bereichs."
          }
          label="Boxen pro Zeile maximal"
          min={1}
          max={maxColumns}
          onChange={(value) =>
            setModuleSetting({
              global: { maxColumnsCount: value },
            })
          }
          value={maxColumnsCount}
          forceUpdateValue={() => {
            if (!useMaxColumnsCountFromPropsRef.current) return undefined;
            useMaxColumnsCountFromPropsRef.current = false;
            return maxColumnsCount;
          }}
          isValid={isMaxColumnsCountValid}
        />
      )}

      {dynamicSettings.displayType && (
        <FormFieldRadios<HighlightsDisplayType>
          radios={displayTypes}
          fieldLabel="Variante"
          denseLayout={true}
          onChange={(value) => {
            setModuleSetting({
              global: { displayType: value },
            });
          }}
          currentFormValue={displayType}
        >
          <FormInfo>
            Wenn die Highlights nicht gleichmäßig auf eine oder mehrere Zeilen
            aufgeteilt werden können, wird automatisch auf die Slider-Variante
            umgeschaltet, auch wenn hier die Listen-Variante ausgewählt ist.
          </FormInfo>
        </FormFieldRadios>
      )}

      {dynamicSettings.transparentSliderArrowBackground &&
        displayType === "slider" && (
          <Checkbox
            checkedStatus={transparentSliderArrowBackground}
            htmlId="button-toggle-slider-arrows-background"
            text="Transparenter Hintergrund bei den Slider-Pfeilen"
            onChange={(value) => {
              setModuleSetting({
                global: {
                  transparentSliderArrowBackground: value,
                },
              });
            }}
          />
        )}

      <div className="Form__Field">
        <SubModulesList
          languageId={languageId}
          pageId={pageId}
          parentModuleId={parentModuleId}
          siteId={siteId}
          moduleType="HighlightModule"
        />
        <div className="Form__Label">
          <label>Highlights</label>
        </div>
      </div>

      {dynamicSettings.description && (
        <NumberFormField
          allowEmpty={true}
          errorMessage={
            "Die eingestellte Anzahl der Zeilen liegt außerhalb des zulässigen Bereichs."
          }
          label="Anzahl geöffnete Zeilen"
          min={3}
          max={100}
          onChange={(value) => {
            setModuleSetting({
              global: { collapsedLinesCount: value || undefined },
            });
          }}
          value={collapsedLinesCount}
        />
      )}

      {dynamicSettings.mediaAspectRatio && (
        <AspectRatioSelection
          onChange={(value) => {
            setModuleSetting({
              global: { mediaAspectRatio: value },
            });
          }}
          currentFormValue={mediaAspectRatio}
          allowedAspectRatios={dynamicSettings.aspectRatios}
        />
      )}

      {dynamicSettings.mediaAlign && (
        <MediaAlignSetting
          mediaAlign={mediaAlign}
          onChange={(value) => {
            setModuleSetting({
              global: {
                mediaAlign: value,
              },
            });
          }}
        />
      )}

      <ModuleColorSchemeSelection
        pageId={pageId}
        translatedModule={translatedModule}
      />
    </>
  );
};

const checkIsMaxColumnsCountValid = ({
  value,
  maxColumnsCount,
}: {
  value: number;
  maxColumnsCount: number;
}) =>
  isInt(maxColumnsCount.toString(), {
    min: 1,
    max: value,
  });

const getMaxColumns = (layout: HighlightsLayout): number => {
  switch (layout) {
    case "layout-1":
      return 4;
    case "layout-2":
      return 2;
    case "layout-3":
      return 4;
    case "layout-6":
      return 2;
    case "layout-8":
      return 2;
  }

  return 0;
};

const mapDispatchToProps =
  getMapDispatchToPropsForModuleSetting<HighlightsModuleSettings>();

const connector = connect(undefined, mapDispatchToProps);

export default connector(HighlightsModuleSettings);
