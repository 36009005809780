import { AxiosResponse } from "axios";
import { ColorSchemesAPI } from "../../server/types/index.js";
import {
  ColorScheme,
  ColorSchemeCategory,
  ColorSchemePutData,
  ThunkAction,
} from "../types/index.js";
import { ColorSchemeNotFoundError, fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";
import { deleteModulesColorScheme } from "./Modules.js";

export type Action =
  | {
      type: "CHANGE_COLOR";
      schemeId: string;
      categoryId: ColorSchemeCategory;
      colorType: string;
      color: string;
    }
  | { type: "DUPLICATE_COLOR_SCHEME_START"; schemeId: string; newId: string }
  | { type: "GET_COLOR_SCHEMES_SUCCESS"; serverSchemes: ColorSchemesAPI }
  | { type: "GET_COLOR_SCHEMES_START" }
  | { type: "GET_COLOR_SCHEMES_ERROR" }
  | {
      type: "PUT_COLOR_SCHEME_START";
      colorSchemeId: string;
      scheme: ColorScheme;
    }
  | { type: "DELETE_COLOR_SCHEME_START"; colorSchemeId: string }
  | { type: "DELETE_COLOR_SCHEME_ERROR"; prevState: ColorSchemesAPI }
  | {
      type: "UPDATE_COLOR_SCHEME";
      colorSchemeId: string;
      scheme: ColorScheme;
    };

export const changeColor = (
  schemeId: string,
  categoryId: ColorSchemeCategory,
  colorType: string,
  color: string
) => ({
  type: "CHANGE_COLOR",
  schemeId,
  categoryId,
  colorType,
  color,
});

export const getColorSchemes = (siteId: string): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    if (getState().loadStates.colorSchemes !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getColorSchemesStart());

    return fetch({ dispatch })
      .get(`sites/${siteId}/color-schemes`)
      .then((res) => {
        dispatch(getColorSchemesSuccess(res.data));
      })
      .catch((error) => {
        dispatch(showAlert("Die Farbschemen konnten nicht geladen werden!"));
        dispatch(getColorSchemesError());
        throw error;
      });
  };
};

export const getColorSchemesSuccess = (
  serverSchemes: ColorSchemesAPI
): Action => ({
  type: "GET_COLOR_SCHEMES_SUCCESS",
  serverSchemes,
});

const getColorSchemesStart = (): Action => ({
  type: "GET_COLOR_SCHEMES_START",
});

const getColorSchemesError = (): Action => ({
  type: "GET_COLOR_SCHEMES_ERROR",
});

export const putColorScheme = (
  siteId: string,
  colorSchemeId: string
): ThunkAction<Promise<AxiosResponse>> => {
  return (dispatch, getState) => {
    const scheme = getState().colorSchemes.schemes[colorSchemeId];
    if (!scheme) throw new ColorSchemeNotFoundError(colorSchemeId);
    dispatch(putColorSchemeStart(colorSchemeId, scheme));

    const colorSchePutData: ColorSchemePutData = {
      scheme,
    };

    return fetch({ dispatch })
      .put(`sites/${siteId}/color-schemes/${colorSchemeId}`, colorSchePutData)
      .catch((error) => {
        dispatch(
          showAlert("Das Aktualisieren des Farbschemen ist fehlgeschlagen!")
        );
        throw error;
      });
  };
};

export const updateColorScheme = (
  colorSchemeId: string,
  scheme: ColorScheme
): Action => ({
  type: "UPDATE_COLOR_SCHEME",
  colorSchemeId,
  scheme,
});

export const putColorSchemeStart = (
  colorSchemeId: string,
  scheme: ColorScheme
): Action => ({
  type: "PUT_COLOR_SCHEME_START",
  colorSchemeId,
  scheme,
});

export const deleteColorScheme = (
  siteId: string,
  colorSchemeId: string
): ThunkAction<Promise<AxiosResponse>> => {
  return (dispatch, getState) => {
    const prevState = getState().colorSchemes;

    dispatch(deleteColorSchemeStart(colorSchemeId));
    dispatch(deleteModulesColorScheme(colorSchemeId));

    return fetch({ dispatch })
      .delete(`sites/${siteId}/color-schemes/${colorSchemeId}`)
      .catch((error) => {
        dispatch(deleteColorSchemeError(prevState));
        dispatch(
          showAlert("Beim Löschen des Farbschemen ist ein Fehler aufgetreten!")
        );
        throw error;
      });
  };
};

export const deleteColorSchemeStart = (colorSchemeId: string): Action => ({
  type: "DELETE_COLOR_SCHEME_START",
  colorSchemeId,
});

const deleteColorSchemeError = (prevState: ColorSchemesAPI): Action => ({
  type: "DELETE_COLOR_SCHEME_ERROR",
  prevState,
});

export const duplicateColorScheme = (
  siteId: string,
  colorSchemeId: string
): ThunkAction<void> => {
  const newId = crypto.randomUUID();

  return (dispatch) => {
    dispatch(duplicateColorSchemeStart(colorSchemeId, newId));
    dispatch(putColorScheme(siteId, newId));
  };
};

const duplicateColorSchemeStart = (
  schemeId: string,
  newId: string
): Action => ({
  type: "DUPLICATE_COLOR_SCHEME_START",
  schemeId,
  newId,
});
