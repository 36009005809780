import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  Google360VirtualTourModuleSettings,
  StoreState,
} from "../../types/index.js";
import { getActiveColorScheme } from "../../utils/utils.js";
import EmbeddedGoogleMaps from "../EmbeddedGoogleMaps.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<Google360VirtualTourModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Google360VirtualTourModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, url },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    className="Google360VirtualTourModule"
    colors={{
      background: scheme.main.background,
      color: scheme.main.text,
    }}
  >
    <div className="Module__Wrapper">
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitle}
      />
    </div>

    <div className="Module__Wrapper">
      <EmbeddedGoogleMaps
        isActive={isActive}
        isPreview={isPreview}
        placeholder="Google 360°"
        url={url}
        className="Google360VirtualTourModule__Container"
      />
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps);

export default connector(Google360VirtualTourModule);
