import { FunctionComponent } from "react";
import { ActionLinks } from "../../types/index.js";
import { getEnquiryBookingLinks } from "../../utils/utils.js";
import SmartLink from "../SmartLink.js";

interface Props {
  actionLinks: ActionLinks;
  isPreview: boolean;
  onClick: () => void;
}

const BookEnquireLinks: FunctionComponent<Props> = ({
  actionLinks,
  isPreview,
  onClick,
}) => {
  const links = getEnquiryBookingLinks(actionLinks);

  return !links.length ? null : (
    <div className="BookEnquireLinks">
      {getEnquiryBookingLinks(actionLinks).map(({ type, href, label }) => (
        <SmartLink
          key={type}
          isPreview={isPreview}
          anchorAttrs={{
            href,
            className: "BookEnquireLinks__Item",
            onClick,
          }}
        >
          {label}
        </SmartLink>
      ))}
    </div>
  );
};

export default BookEnquireLinks;
