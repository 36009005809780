import { ReactElement, ReactNode, ReactText } from "react";
import { SelectOption } from "../types/index.js";
import { getHtmlFieldId } from "../utils/utils.js";

interface Props<OptionValues> {
  value: OptionValues;
  label: string;
  options: SelectOption<OptionValues>[];
  onChange: (value: OptionValues) => void;
  children?: ReactNode;
}

const SelectFormField: <OptionValue extends ReactText | undefined>(
  props: Props<OptionValue>
) => ReactElement<Props<OptionValue>> = ({
  label,
  value,
  onChange,
  options,
  children,
}) => {
  const htmlId = getHtmlFieldId(label);

  return (
    <div className="Form__Field">
      <div className="Form__Content">
        <select
          id={htmlId}
          // The value attribute needs to be non-undefined,
          // otherwise the label is mistakenly used as value.
          value={value ?? ""}
          onChange={(event) => {
            const newValue = event.target.value;
            onChange(
              (newValue === ""
                ? undefined
                : isNaN(+newValue)
                ? newValue
                : +newValue) as typeof value
            );
          }}
        >
          {options.map(({ value, label }) => (
            <option key={value + label} value={value ?? ""}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {children}
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
    </div>
  );
};

export default SelectFormField;
