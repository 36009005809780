import {
  ImageCropParams,
  Picture,
  PictureCategory,
  PictureQueryParams,
  Pictures,
} from "../types/index.js";
import { appendQueryToUrl as appendQueryToURL } from "../utils/utils.js";

interface SizeParams {
  width?: number;
  height?: number;
  mode?: 1 | 4;
  crop?: ImageCropParams;
}

export const getPictureWithSizedURL =
  (sizeParams: SizeParams) => (picture: Picture) => {
    const query: PictureQueryParams = {
      w: sizeParams.width ? Math.round(sizeParams.width) : undefined,
      h: sizeParams.height ? Math.round(sizeParams.height) : undefined,
      m: sizeParams.mode,
      cropX: sizeParams.crop?.x,
      cropY: sizeParams.crop?.y,
      cropW: sizeParams.crop?.width,
      cropH: sizeParams.crop?.height,
    };

    return checkIsPlaceholder(picture)
      ? picture
      : {
          ...picture,
          url: appendQueryToURL(picture.url, query),
        };
  };

export const getPictureById = (
  pictures: Pictures,
  id: string | undefined,
  sizeParams: SizeParams = {}
) => {
  const defaultPicture = getDefaultPicture();
  const picture = (id ? pictures[id] : undefined) ?? defaultPicture;
  return getPictureWithSizedURL(sizeParams)(picture);
};

export const getPicturesById = (
  pictures: Pictures,
  ids: string[],
  sizeParams: SizeParams = {}
) =>
  ids.length
    ? // If there are images defined in the module
      ids.map((pictureId) => getPictureById(pictures, pictureId, sizeParams))
    : // If there are no images, get the placeholder picture
      [getDefaultPicture()];

const getDefaultPicture = (): Picture => ({
  // Math.random() is used here instead of crypto.randomUUID() due to better
  // browser support.
  id: String(Math.random()),
  url: "/image.svg",
  title: "",
  category: "placeholder",
  copyright: "",
  width: 100,
  height: 100,
});

export const checkIsPlaceholder = ({
  category,
}: {
  category: PictureCategory;
}) => category === "placeholder";
