import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import { ActionLinks, ColorScheme } from "../types/index.js";
import {
  getEnquiryBookingLinks,
  getVouchersSpecialsLinks,
} from "../utils/utils.js";
import Button from "./Button.js";
import Icon from "./Icon.js";
import SmartLink from "./SmartLink.js";

interface Props {
  scheme: ColorScheme | undefined;
  isPreview: boolean;
  actionLinks: ActionLinks;
  className?: string;
  onItemClick?: () => void;
  showQuickLinks: boolean;
}

const ActionModuleNavigation: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  actionLinks,
  className,
  onItemClick,
  showQuickLinks,
}) => {
  const bookingEnquiry = getEnquiryBookingLinks(actionLinks);
  const vouchersSpecials = getVouchersSpecialsLinks(actionLinks);

  return (
    <div className={ClassNames("ActionModuleNavigation", className)}>
      {showQuickLinks && (
        <div className="ActionModuleNavigation__Quick">
          {vouchersSpecials.map(({ label, href, type }) => (
            <SmartLink
              key={type}
              anchorAttrs={{
                className: "ActionModuleNavigation__QuickItem",
                href,
                title: label,
              }}
              isPreview={isPreview}
            >
              <Icon
                className={ClassNames(
                  "ActionModuleNavigation__Icon",
                  style({ fill: scheme?.primary.background })
                )}
                glyph={type}
              />
            </SmartLink>
          ))}
        </div>
      )}

      {bookingEnquiry.map(({ label, href, type }, index) => (
        <Button
          className="ActionModuleNavigation__Item"
          key={index}
          type={type === "enquiry" ? "secondary" : "primary"}
          anchorAttrs={{
            href,
            onClick: onItemClick,
          }}
          scheme={scheme}
          isPreview={isPreview}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

export default ActionModuleNavigation;
