import { I18n } from "../types/index.js";

const de: I18n = {
  boards: {
    0: "Undefiniert",
    1: "Nur Übernachtung",
    2: "Frühstück",
    3: "Halbpension",
    4: "Vollpension",
    5: "All inclusive",
  },
  email: "E-Mail",
  phone: "Telefon",
  more: "mehr",
  moreMenu: "mehr",
  languageNames: {
    de: "Deutsch",
    it: "Italienisch",
    en: "Englisch",
    es: "Spanisch",
    fr: "Französisch",
    ru: "Russisch",
    da: "Dänisch",
    pl: "Polnisch",
    nl: "Niederländisch",
  },
  menu: "Menü",
  imprint: {
    author: "Autor",
    icons: "Icons",
    pictures: "Bildquellen",
  },
  errorOccurred:
    "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  countries: {
    main: "Schnellauswahl",
    all: "Alle Länder",
    error: "Beim Laden der Länderliste ist ein Fehler aufgetreten.",
  },
  genders: {
    m: "Herr",
    f: "Frau",
  },
  register: "Anmelden",
  formFields: {
    gender: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    email: "E-Mail",
    country: "Land",
  },
  newsletterMessages: {
    success: "Vielen Dank für Ihre Anmeldung zu unserem Newsletter-Service!",
    error:
      "Leider konnte die Newsletter-Anmeldung nicht abgeschlossen werden. " +
      "Bitte versuchen Sie es später erneut oder schreiben Sie uns gerne eine E-Mail.",
    privacy: (privacyLink) => (
      <>
        Durch die Anmeldung zum Newsletter stimmen Sie den{" "}
        {privacyLink("Datenschutzbestimmungen")} zu und sind damit
        einverstanden, dass Ihre E-Mail-Adresse für Newsletterzwecke verwendet
        wird.
      </>
    ),
  },
  on: "auf",
};

export default de;
