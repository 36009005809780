import ClassNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript, loadScript } from "../../actions/LoadStates.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  GastroPoolInsuranceModuleSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<GastroPoolInsuranceModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const GastroPoolInsuranceModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    translation: {
      languageId,
      settings: { title, subtitle, key },
    },
    settings: { textAlign },
  },
  scheme,
  isFirstOnPage,
  injectScript,
  loadScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  const scriptLanguage = getSupportedLanguage(languageId, ["de", "it", "en"]);
  const scriptUrl = `https://portal.gastropool.it/rxtravel/widget/${scriptLanguage}?${key}`;

  useEffect(() => {
    isLazyloaded && key && injectScript(scriptUrl);
  }, [scriptLanguage, key, isLazyloaded]);

  // The script needs to be loaded again and the widget script removed,
  // otherwise the widget won’t load again when navigating pages in the CMS
  useEffect(
    () => () => {
      loadScript(scriptUrl, "unloaded");
    },
    []
  );

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="GastroPoolInsuranceModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div
          className={ClassNames(
            "Module__Wrapper GastroPoolInsuranceModule__Content",
            {
              "GastroPoolInsuranceModule__Content--empty": !key,
            }
          )}
        >
          {key && (
            <div id="rxtravel-container">
              <div id="ti-form">
                <div className="block GastroPoolInsuranceModule__Center">
                  <img src="https://portal.gastropool.it/app_themes/travel/loader.gif" />
                </div>
              </div>
            </div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapDispatchToProps = {
  injectScript,
  loadScript,
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GastroPoolInsuranceModule);
