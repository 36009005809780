import ClassNames from "classnames";
import { FunctionComponent, ReactNode, useRef, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { CSSTransition } from "react-transition-group";
import tinycolors from "tinycolor2";
import { style } from "typestyle";
import {
  Accommodation,
  ActionLinks,
  ColorScheme,
  CommonHeaderLayoutProps,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  ThreeSizes,
} from "../../types/index.js";
import {
  useIntersection,
  useKeyDown,
  useScrollDirection,
} from "../../utils/hooks.js";
import {
  getContactLinks,
  getPhoneLink,
  getQuickLinksWithIcon,
  onTransitionEnd,
  overrideImagesAspectRatio,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import MainMenuListCollapsible, {
  verticalMenuClassNames,
} from "../MainMenuListCollapsible.js";
import ImagesModule from "../Modules/ImagesModule.js";
import SmartLink from "../SmartLink.js";
import BookEnquireLinks from "./BookEnquireLinks.js";
import HamburgerButton from "./HamburgerButton.js";
import MobileLanguageMenu from "./MobileLanguageMenu.js";
import MobileMainLogo from "./MobileMainLogo.js";
import QuickButtons from "./QuickButtons.js";

interface Props extends CommonHeaderLayoutProps {
  isAlwaysHamburgerNav: boolean;
}

const Header1Mobile: FunctionComponent<Props> = ({
  accommodation,
  actionLinks,
  activeModuleId,
  activePagePath,
  fallbackLanguageId,
  imagesModule: imagesModuleProp,
  isPreview,
  languageId,
  logo,
  logoSize,
  mainPageURL,
  menuItems,
  pageId,
  pages,
  queries,
  scheme,
  sticky,
  isAlwaysHamburgerNav,
  whatsAppNumber,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref);
  const isIntersecting = intersection?.isIntersecting ?? true;
  const imagesModule = imagesModuleProp
    ? overrideImagesAspectRatio(imagesModuleProp)
    : undefined;
  const closeMenu = () => setIsMenuOpen(false);

  useKeyDown("Escape", closeMenu);

  const isFixed =
    !isPreview &&
    ((scrollDirection === "up" && !isIntersecting) || sticky || isMenuOpen);

  const showQuickButtons =
    isPreview ||
    isIntersecting ||
    sticky ||
    isMenuOpen ||
    scrollDirection === "up";

  return (
    <div
      className={ClassNames("HeaderModule__Inner", {
        "HeaderModule__Inner--narrow": !isAlwaysHamburgerNav,
      })}
    >
      <header
        className={ClassNames(
          "Header1Mobile",
          `Header1Mobile--height-${logoSize}`,
          {
            "Header1Mobile--scrolled-down": !isIntersecting,
            "Header1Mobile--no-image": !imagesModule?.pageId,
          }
        )}
      >
        <div
          ref={ref}
          className={ClassNames("Header1Mobile__IntersectionHelper", {
            "Header1Mobile__IntersectionHelper--scrolled-down": !isIntersecting,
          })}
        />

        <CSSTransition
          timeout={300}
          in={isFixed}
          addEndListener={onTransitionEnd}
          classNames={{
            enter: "Header1Mobile__Bar--enter",
            enterActive: "Header1Mobile__Bar--enter-active",
            exit: "Header1Mobile__Bar--fixed Header1Mobile__Bar--exit",
            exitActive:
              "Header1Mobile__Bar--fixed Header1Mobile__Bar--exit-active",
          }}
        >
          <div
            className={ClassNames(
              "Header1Mobile__Bar",
              {
                "Header1Mobile__Bar--fixed": isFixed,
                "Header1Mobile__Bar--scrolled-down": !isIntersecting,
              },
              style({
                background: scheme.main.background,
                color: scheme.main.text,
              })
            )}
          >
            <BarInner
              accommodation={accommodation}
              isPreview={isPreview}
              logo={logo}
              mainPageURL={mainPageURL}
              isIntersecting={isIntersecting}
              logoSize={logoSize}
              actionLinks={actionLinks}
              languageId={languageId}
              closeMenu={closeMenu}
            >
              <Header1LogoContainer
                isMenuOpen={isMenuOpen}
                toggleMenuOpen={() => setIsMenuOpen((v) => !v)}
              />
            </BarInner>
          </div>
        </CSSTransition>

        <Menu
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          menuItems={menuItems}
          pages={pages}
          scheme={scheme}
          accommodation={accommodation}
          languageId={languageId}
          whatsAppNumber={whatsAppNumber}
          actionLinks={actionLinks}
          isPreview={isPreview}
          pageId={pageId}
          isMenuOpen={isMenuOpen}
          closeMenu={closeMenu}
        />

        {imagesModule?.pageId && (
          <ImagesModule
            translatedModule={imagesModule}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={activeModuleId}
            fullHeight={true}
          />
        )}
      </header>

      <QuickButtons
        actionLinks={actionLinks}
        className={style({
          background: scheme.primary.background,
          color: scheme.primary.text,
        })}
        isPreview={isPreview}
        isVisible={showQuickButtons}
        onClick={closeMenu}
      />
    </div>
  );
};

interface LogoProps {
  isMenuOpen: boolean;
  toggleMenuOpen: () => void;
}

export const Header1LogoContainer: FunctionComponent<LogoProps> = ({
  isMenuOpen,
  toggleMenuOpen,
}) => (
  <div className="Header1Mobile__MenuButtonContainer">
    <HamburgerButton
      className="Header1Mobile__MenuButton"
      isMenuOpen={isMenuOpen}
      onClick={toggleMenuOpen}
    />
  </div>
);

interface MenuProps {
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  menuItems: MainMenuItem[];
  pages: PageState;
  scheme: ColorScheme;
  accommodation: Accommodation;
  languageId: Language;
  whatsAppNumber: string | undefined;
  actionLinks: ActionLinks;
  isPreview: boolean;
  pageId: string;
  isMenuOpen: boolean;
  closeMenu: () => void;
}

export const Menu: FunctionComponent<MenuProps> = ({
  activePagePath,
  fallbackLanguageId,
  menuItems,
  pages,
  scheme,
  accommodation,
  languageId,
  whatsAppNumber,
  actionLinks,
  isPreview,
  pageId,
  isMenuOpen,
  closeMenu,
}) => {
  const quickNav = [
    ...getContactLinks(accommodation, languageId, whatsAppNumber),
    ...getQuickLinksWithIcon(actionLinks),
  ];

  return (
    <CSSTransition
      in={isMenuOpen}
      timeout={2000}
      mountOnEnter={true}
      unmountOnExit={true}
      addEndListener={onTransitionEnd}
      classNames={{
        enterActive: "Header1Mobile__Menu--open",
        enterDone: "Header1Mobile__Menu--open",
      }}
    >
      <div
        className={ClassNames(
          "Header1Mobile__Menu",
          style({
            background: tinycolors(scheme.main.background)
              .darken(5)
              .toRgbString(),
            color: scheme.main.text,
          })
        )}
      >
        <RemoveScroll enabled={!isPreview} className="Header1Mobile__MenuInner">
          <ul className="Header1Mobile__QuickNav">
            {quickNav.map(({ href, icon, type }) => (
              <li key={type} className="Header1Mobile__QuickNavItem">
                <SmartLink
                  isPreview={isPreview}
                  anchorAttrs={{
                    href,
                    className: "Header1Mobile__QuickNavLink",
                    onClick: () =>
                      type !== "email" && type !== "phone" && closeMenu(),
                  }}
                >
                  <Icon glyph={icon} className="Header1Mobile__QuickNavIcon" />
                </SmartLink>
              </li>
            ))}
          </ul>
          <MainMenuListCollapsible
            isPreview={isPreview}
            languageId={languageId}
            onMenuItemClick={closeMenu}
            menuItems={menuItems}
            pages={pages}
            activePagePath={activePagePath}
            fallbackLanguageId={fallbackLanguageId}
            menuLevel={0}
            collapseLevel={0}
            classNames={verticalMenuClassNames}
          />
          <MobileLanguageMenu
            className="Header1Mobile__LanguageMenu"
            isPreview={isPreview}
            languageId={languageId}
            pageId={pageId}
            onClick={closeMenu}
          />
        </RemoveScroll>
      </div>
    </CSSTransition>
  );
};

interface BarInnerProps {
  accommodation: Accommodation;
  isPreview: boolean;
  logo: Picture;
  mainPageURL: string | undefined;
  isIntersecting?: boolean;
  logoSize: ThreeSizes;
  actionLinks: ActionLinks;
  languageId: Language;
  closeMenu: () => void;
  children: ReactNode;
}

export const BarInner: FunctionComponent<BarInnerProps> = ({
  accommodation,
  isPreview,
  logo,
  mainPageURL,
  isIntersecting,
  logoSize,
  actionLinks,
  languageId,
  closeMenu,
  children,
}) => {
  const phoneLink = getPhoneLink(accommodation, languageId);

  return (
    <div className="Header1Mobile__BarInner Module__Wrapper">
      {children}

      <div className="Header1Mobile__LogoContainer">
        <MobileMainLogo
          accommodationName={accommodation.name}
          isPreview={isPreview}
          logo={logo}
          mainPageURL={mainPageURL}
          isSmall={isIntersecting ? !isIntersecting : true}
          size={logoSize}
        />
      </div>

      <div className="Header1Mobile__Container3">
        <BookEnquireLinks
          actionLinks={actionLinks}
          isPreview={isPreview}
          onClick={closeMenu}
        />

        <a
          href={phoneLink.href}
          className="BareBtn Header1Mobile__Phone"
          title={phoneLink.title}
        >
          <Icon glyph={phoneLink.icon} />
        </a>
      </div>
    </div>
  );
};

export default Header1Mobile;
