import { isAxiosError } from "axios";
import { debounce } from "throttle-debounce";
import {
  APIModule,
  CopyModulePost,
  ModulePatch,
} from "../../server/types/index.js";
import { arePageModulesLoaded } from "../selectors/modules.js";
import {
  ChangeModuleSet,
  CreateModulePostData,
  Language,
  ModuleSettingsTypes,
  ModuleTranslations,
  ModuleType,
  Modules,
  MoveModulePostData,
  PartialModuleSettings,
  PostModuleParams,
  PostMoveModuleParams,
  StoreState,
  ThunkAction,
  ThunkDispatch,
} from "../types/index.js";
import {
  ModuleNotFoundError,
  actionModuleTypes,
  copiedModuleClipboard,
  fetch,
  getNextModuleId,
  isDeletableModule,
  isItemUntranslated,
} from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

interface PostModuleStartAction extends PostModuleParams {
  type: "POST_MODULE_START";
}

export interface DeleteModuleTranslationStartAction {
  type: "DELETE_MODULE_TRANSLATION_START";
  pageId: string | null;
  languageId: Language;
  moduleId: string;
  moduleType: ModuleType;
  parentId: string | null;
  deleteAllTranslations: boolean;
}

export interface TranslateModuleStartAction {
  type: "TRANSLATE_MODULE_START";
  moduleId: string;
  pageId: string | null;
  languageId: Language;
  sourceLanguageId: Language;
}

export type Action =
  | {
      type: "GET_MODULES_START";
      pageId: string;
    }
  | {
      type: "GET_MODULES_SUCCESS";
      pageId: string;
      modules: APIModule[];
    }
  | {
      type: "GET_SITE_MODULES_START";
    }
  | {
      type: "GET_SITE_MODULES_SUCCESS";
      modules: APIModule[];
    }
  | {
      type: "GET_MODULES_ERROR";
      pageId: string;
    }
  | PostModuleStartAction
  | {
      type: "CHANGE_MODULE";
      moduleId: string;
      changeset: ChangeModuleSet;
    }
  | TranslateModuleStartAction
  | {
      type: "DRAG_MODULE";
      pageId: string;
      dragIndex: number;
      hoverIndex: number;
    }
  | {
      type: "DRAG_SUBMODULE";
      parentId: string;
      moduleType: ModuleType;
      pageId: string | null;
      dragIndex: number;
      hoverIndex: number;
    }
  | {
      type: "MOVE_MODULE";
      pageId: string | null;
      moduleId: string;
      moveBy: number;
    }
  | DeleteModuleTranslationStartAction
  | { type: "DELETE_MODULE_TRANSLATION_ERROR"; prevState: Modules }
  | {
      type: "SET_MODULE_SETTINGS";
      moduleId: string;
      languageId: Language;
      settings: PartialModuleSettings<ModuleSettingsTypes>;
    }
  | {
      type: "GET_ACTION_MODULES_START";
    }
  | {
      type: "GET_ACTION_MODULES_SUCCESS";
      modules: APIModule[];
    }
  | {
      type: "GET_ACTION_MODULES_ERROR";
    }
  | {
      type: "DELETE_MODULES_COLOR_SCHEME";
      colorSchemeId: string;
    };

export const getSiteModules =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getState().loadStates.siteModules !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getSiteModulesStart());

    try {
      const res = await fetch({ dispatch }).get<APIModule[]>(
        `sites/${siteId}/modules`,
        {
          params: {
            global: true,
          },
        }
      );
      dispatch(getSiteModulesSuccess(res.data));
    } catch (error) {
      dispatch(
        showAlert("Beim Laden der globalen Module ist ein Fehler aufgetreten!")
      );
      throw error;
    }
  };

const getSiteModulesStart = (): Action => ({
  type: "GET_SITE_MODULES_START",
});

export const getSiteModulesSuccess = (modules: APIModule[]): Action => ({
  type: "GET_SITE_MODULES_SUCCESS",
  modules,
});

export const getModules = ({
  siteId,
  pageId,
  forceLoad,
}: {
  siteId: string;
  pageId: string;
  forceLoad: boolean;
}): ThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    if (
      arePageModulesLoaded(getState().modules.byPageId, pageId) &&
      !forceLoad
    ) {
      return Promise.resolve();
    }

    dispatch(getModulesStart(pageId));

    try {
      const res = await fetch({ dispatch }).get<APIModule[]>(
        `sites/${siteId}/pages/${pageId}/modules`
      );
      dispatch(getModulesSuccess(pageId, res.data));
    } catch (error) {
      dispatch(getModulesError(pageId));
      dispatch(showAlert("Beim Laden der Module ist ein Fehler aufgetreten!"));
      throw error;
    }
  };
};

const getModulesStart = (pageId: string): Action => ({
  type: "GET_MODULES_START",
  pageId,
});

const getModulesError = (pageId: string): Action => ({
  type: "GET_MODULES_ERROR",
  pageId,
});

export const getModulesSuccess = (
  pageId: string,
  modules: APIModule[]
): Action => ({
  type: "GET_MODULES_SUCCESS",
  pageId,
  modules,
});

export const postModule =
  <Settings extends ModuleSettingsTypes>(
    params: PostModuleParams<Settings>
  ): ThunkAction<Promise<APIModule | undefined>> =>
  async (dispatch, getState) => {
    dispatch(postModuleStart(params));
    const module = getState().modules.byId[params.moduleId];
    if (!module) throw new ModuleNotFoundError(params.moduleId);

    const {
      siteId,
      parentId,
      type: moduleType,
      id: moduleId,
      ...rest
    } = module;

    const postData: CreateModulePostData = {
      ...rest,
      moduleType,
      next: params.next || null,
      parentId,
    };

    try {
      const { data } = await fetch({ dispatch }).post<APIModule | undefined>(
        `sites/${siteId}/modules/${moduleId}`,
        postData
      );
      return data;
    } catch (error) {
      dispatch(
        showAlert("Beim Speichern des Moduls ist ein Fehler aufgetreten!")
      );
      throw error;
    }
  };

export const postModuleStart = (params: PostModuleParams): Action => {
  return {
    type: "POST_MODULE_START",
    ...params,
  };
};

export const changeModule =
  (
    siteId: string,
    moduleId: string,
    changeset: ChangeModuleSet
  ): ThunkAction<void> =>
  (dispatch, getState) => {
    dispatch<Action>({
      type: "CHANGE_MODULE",
      moduleId,
      changeset,
    });

    debouncedPatchModuleRequest({
      dispatch,
      getState,
      moduleId,
      patch: changeset,
      siteId,
    });
  };

export const translateModule =
  ({
    siteId,
    moduleId,
    pageId,
    languageId,
    sourceLanguageId,
  }: {
    siteId: string;
    moduleId: string;
    pageId: string | null;
    languageId: Language;
    sourceLanguageId: Language;
  }): ThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(
      translateModuleStart(moduleId, languageId, sourceLanguageId, pageId)
    );
    const translations: ModuleTranslations = {};
    translations[languageId] =
      getState().modules.byId[moduleId]?.translations[languageId];

    patchModuleRequest({
      dispatch,
      getState,
      moduleId,
      patch: {
        translations,
      },
      siteId,
    });
  };

export const translateModuleStart = (
  moduleId: string,
  languageId: Language,
  sourceLanguageId: Language,
  pageId: string | null
): TranslateModuleStartAction => ({
  type: "TRANSLATE_MODULE_START",
  moduleId,
  languageId,
  sourceLanguageId,
  pageId,
});

export const dragModule = (
  pageId: string,
  dragIndex: number,
  hoverIndex: number
): Action => ({
  type: "DRAG_MODULE",
  pageId,
  dragIndex,
  hoverIndex,
});

export const dragSubmodule = ({
  parentId,
  moduleType,
  pageId,
  dragIndex,
  hoverIndex,
}: {
  parentId: string;
  moduleType: ModuleType;
  pageId: string | null;
  dragIndex: number;
  hoverIndex: number;
}): Action => ({
  type: "DRAG_SUBMODULE",
  parentId,
  moduleType,
  pageId,
  dragIndex,
  hoverIndex,
});

export const moveModule = (
  siteId: string,
  pageId: string,
  moduleId: string,
  moveBy: number
): ThunkAction<void> => {
  return (dispatch, getState) => {
    const module = getState().modules.byId[moduleId];
    if (!module?.pageId) return;

    dispatch(moveModuleStart(pageId, moduleId, moveBy));

    const params: PostMoveModuleParams = {
      siteId,
      pageId,
      moduleId,
      hasMovedBy: moveBy,
    };

    debouncedMoveModule(dispatch, params);
  };
};

const moveModuleStart = (
  pageId: string | null,
  moduleId: string,
  moveBy: number
): Action => ({
  type: "MOVE_MODULE",
  pageId,
  moduleId,
  moveBy,
});

export const postMoveModule =
  ({
    siteId,
    pageId,
    moduleId,
    hasMovedBy,
  }: PostMoveModuleParams): ThunkAction<void> =>
  async (dispatch, getState) => {
    const { modules } = getState();
    const module = getState().modules.byId[moduleId];
    if (!module) throw new ModuleNotFoundError(moduleId);
    const { parentId } = module;

    const nextModuleId = getNextModuleId(moduleId, modules);

    const postData: MoveModulePostData = {
      next: nextModuleId || null,
      parentId,
    };

    try {
      return fetch({ dispatch }).post(
        `sites/${siteId}/modules/${moduleId}/move`,
        postData
      );
    } catch (error) {
      // If an error occurred, reset the position to before the move
      dispatch(moveModuleStart(pageId, moduleId, -hasMovedBy));

      dispatch(
        showAlert("Beim Verschieben des Moduls ist ein Fehler aufgetreten!")
      );
      throw error;
    }
  };

export const copyModule =
  ({
    siteId,
    targetPageId,
    moduleId,
    targetModuleId,
    newModuleShortId,
  }: {
    siteId: string;
    moduleId: string;
    targetPageId: string | null;
    targetModuleId: string | null;
    newModuleShortId: string;
  }): ThunkAction<Promise<APIModule[]>> =>
  async (dispatch) => {
    try {
      const postData: CopyModulePost = {
        targetModuleId,
        targetPageId,
        newModuleShortId,
      };

      const { data } = await fetch({ dispatch }).post<APIModule[]>(
        `sites/${siteId}/modules/${moduleId}/copy`,
        postData
      );

      dispatch(
        targetPageId !== null
          ? getModulesSuccess(targetPageId, data)
          : getSiteModulesSuccess(data)
      );

      return data;
    } catch (error) {
      const responseStatus = isAxiosError(error)
        ? error.response?.status
        : undefined;

      const errorMessage =
        responseStatus === 404
          ? "Das kopierte Modul wurde gelöscht und kann daher nicht hier eingefügt werden!"
          : "Beim Einfügen Moduls ist ein Fehler aufgetreten!";

      dispatch(showAlert(errorMessage));

      // Clear copied module if the module was removed
      [404, 422, 400].indexOf(responseStatus ?? 0) !== -1 &&
        copiedModuleClipboard.remove(siteId);

      throw error;
    }
  };

export const setModuleSetting = <Settings extends ModuleSettingsTypes>(
  {
    id,
    siteId,
    translation: { languageId },
  }: {
    id: string;
    siteId: string;
    translation: {
      languageId: Language;
    };
  },
  settings: PartialModuleSettings<Settings>
) => {
  return setModuleSettings<Settings>(siteId, languageId, id, settings);
};

/**
 * Set the module settings for a specific module translation. The passed settings
 * will extend (but not deep extend) the existing settings, so it’s not necessary to pass
 * all top level properties of the settings.
 */
export const setModuleSettings =
  <Settings extends ModuleSettingsTypes = ModuleSettingsTypes>(
    siteId: string,
    languageId: Language,
    moduleId: string,
    settings: PartialModuleSettings<Settings>
  ): ThunkAction<void> =>
  (dispatch, getState) => {
    const action: Action = {
      type: "SET_MODULE_SETTINGS",
      moduleId,
      languageId,
      settings,
    };
    dispatch(action);

    const currentModule = getState().modules.byId[moduleId];
    const translation = currentModule?.translations[languageId];

    if (!translation) {
      throw new Error(
        `Translation for in ${languageId} for module ${moduleId} not found`
      );
    }

    const translations: ModuleTranslations = {};
    translations[languageId] = {
      settings: translation.settings,
    };

    debouncedPatchModuleRequest({
      dispatch,
      getState,
      moduleId,
      patch: {
        translations,
        settings: currentModule.settings,
      },
      siteId,
    });
  };

export const deleteModuleTranslation =
  ({
    siteId,
    pageId,
    languageId,
    moduleId,
    deleteAllTranslations = false,
  }: {
    siteId: string;
    pageId: string | null;
    languageId: Language;
    moduleId: string;
    deleteAllTranslations: boolean;
  }): ThunkAction<void> =>
  async (dispatch, getState) => {
    const { modules } = getState();
    const previousModules = modules;
    const currentModule = modules.byId[moduleId];
    if (!currentModule) throw new ModuleNotFoundError(moduleId);
    const { parentId, type } = currentModule;
    const _deleteAllTranslations =
      deleteAllTranslations || isItemUntranslated(currentModule);
    if (!isDeletableModule(type)) return undefined;
    dispatch(
      deleteModuleTranslationStart({
        pageId,
        languageId,
        moduleId,
        moduleType: type,
        parentId,
        deleteAllTranslations: _deleteAllTranslations,
      })
    );
    const url = `sites/${siteId}/modules/${moduleId}`;
    const patchBody: ModulePatch = {
      translations: {
        [languageId]: null,
      },
    };

    try {
      return await (_deleteAllTranslations
        ? fetch({ dispatch }).delete(url)
        : fetch({ dispatch }).patch(url, patchBody));
    } catch (error) {
      dispatch(deleteModuleTranslationError(previousModules));
      dispatch(
        showAlert("Beim Löschen des Moduls ist ein Fehler aufgetreten!")
      );
      throw error;
    }
  };

export const deleteModuleTranslationStart = ({
  pageId,
  languageId,
  moduleId,
  moduleType,
  parentId,
  deleteAllTranslations,
}: {
  pageId: string | null;
  languageId: Language;
  moduleId: string;
  moduleType: ModuleType;
  parentId: string | null;
  deleteAllTranslations: boolean;
}): DeleteModuleTranslationStartAction => {
  return {
    type: "DELETE_MODULE_TRANSLATION_START",
    pageId,
    languageId,
    moduleId,
    moduleType,
    parentId,
    deleteAllTranslations,
  };
};

const deleteModuleTranslationError = (prevState: Modules): Action => ({
  type: "DELETE_MODULE_TRANSLATION_ERROR",
  prevState,
});

/**
 * Get the debounce function on a per-module basis
 */
const debouncedMoveModuleCreator = () => {
  const memoizedFunctions: {
    [moduleId: string]: (
      dispatch: ThunkDispatch,
      params: PostMoveModuleParams
    ) => void;
  } = {};

  return (dispatch: ThunkDispatch, params: PostMoveModuleParams) => {
    const { moduleId } = params;

    memoizedFunctions[moduleId] =
      memoizedFunctions[moduleId] ||
      // Only 500 ms, a higher value could lead to mismatches
      // between server and client if an user moves modules fast
      debounce(500, (dispatch: ThunkDispatch, params: PostMoveModuleParams) =>
        dispatch(postMoveModule(params))
      );

    memoizedFunctions[moduleId]?.(dispatch, params);
  };
};

const debouncedMoveModule = debouncedMoveModuleCreator();

const patchModuleRequest = async ({
  dispatch,
  getState,
  siteId,
  moduleId,
  patch,
}: {
  dispatch: ThunkDispatch;
  getState: () => StoreState;
  siteId: string;
  moduleId: string;
  patch: ModulePatch;
}) => {
  const currentModule = getState().modules.byId[moduleId];

  // If the module no longer exists (e.g. it was deleted), abort
  if (!currentModule) return;

  try {
    const { data } = await fetch({ dispatch }).patch<APIModule>(
      `sites/${siteId}/modules/${moduleId}`,
      patch
    );

    return data;
  } catch (error) {
    dispatch(
      showAlert("Beim Aktualisieren des Moduls ist ein Fehler aufgetreten!")
    );
    throw error;
  }
};

export const debouncedPatchModuleRequest = debounce(2000, patchModuleRequest);

export const deleteModulesColorScheme = (colorSchemeId: string): Action => ({
  type: "DELETE_MODULES_COLOR_SCHEME",
  colorSchemeId,
});

export const getActionModules =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getState().loadStates.actionModules !== "unloaded") {
      return Promise.resolve();
    }
    dispatch(getActionModulesStart());
    try {
      const { data } = await fetch({ dispatch }).get<APIModule[]>(
        `sites/${siteId}/modules`,
        {
          params: {
            type: actionModuleTypes,
          },
        }
      );
      dispatch(getActionModulesSuccess(data));
    } catch (e) {
      dispatch(getActionModulesError());
    }
  };

const getActionModulesStart = (): Action => ({
  type: "GET_ACTION_MODULES_START",
});

export const getActionModulesSuccess = (modules: APIModule[]): Action => ({
  type: "GET_ACTION_MODULES_SUCCESS",
  modules,
});

const getActionModulesError = (): Action => ({
  type: "GET_ACTION_MODULES_ERROR",
});
