import ClassNames from "classnames";
import { FunctionComponent, RefCallback, useRef } from "react";
import {
  CommonHeaderLayoutProps,
  ThreeSizes,
  TopHeaderVariant,
} from "../types/index.js";
import ActionModuleNavigation from "./ActionModuleNavigation.js";
import HeaderBar from "./HeaderBar.js";
import Header1Mobile from "./HeaderMobile/Header1Mobile.js";
import ImagesModule from "./Modules/ImagesModule.js";
import PriorityNavigation from "./PriorityNavigation.js";

interface Props {
  intersectionTriggerRef: RefCallback<HTMLElement>;
  topHeaderVariant: TopHeaderVariant;
  common: CommonHeaderLayoutProps;
  logoSize: ThreeSizes;
}

const HeaderLayout1: FunctionComponent<Props> = ({
  topHeaderVariant,
  intersectionTriggerRef,
  common,
  common: {
    isPreview,
    queries,
    scheme,
    accommodation,
    fallbackLanguageId,
    languageId,
    pages,
    logo,
    imagesModule,
    mainPageURL,
    activePagePath,
    pageId,
    menuItems,
    actionLinks,
    activeModuleId,
  },
  logoSize,
}) => {
  const headerBarRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {topHeaderVariant !== "hamburger-nav" && (
        <div
          className={ClassNames(
            "HeaderModule__Inner",
            "HeaderModule__Inner--wide",
            `HeaderModule__Inner--top-${topHeaderVariant}`
          )}
        >
          <HeaderBar
            onRef={(el) => (headerBarRef.current = el)}
            onIntersectionTriggerRef={intersectionTriggerRef}
            accommodation={accommodation}
            actionLinks={actionLinks}
            activePagePath={activePagePath}
            hasHeaderImage={!!imagesModule}
            isPreview={isPreview}
            languageId={languageId}
            topHeaderVariant={topHeaderVariant}
            logo={logo}
            mainPageURL={mainPageURL}
            pages={pages}
            topLevelMainMenuItems={menuItems}
            scheme={scheme}
            isStaticHeader={true}
            fallbackLanguageId={fallbackLanguageId}
            pageId={pageId}
            logoSize={logoSize}
          />
          <div className="HeaderModule__BottomBlock">
            {topHeaderVariant === "below-header-nav" && (
              <PriorityNavigation
                className="MainMenu__Wrapper--below-header-nav MainMenu__Wrapper--horizontal"
                isPreview={isPreview}
                languageId={languageId}
                menuItems={menuItems}
                pages={pages}
                scheme={scheme}
                topHeaderVariant={topHeaderVariant}
                activePagePath={activePagePath}
                hasHeaderImage={!!imagesModule}
                absolutePositioning={!!imagesModule}
                fallbackLanguageId={fallbackLanguageId}
              />
            )}
            {imagesModule?.pageId && (
              <ImagesModule
                translatedModule={imagesModule}
                isPreview={isPreview}
                pageId={pageId}
                queries={queries}
                isActive={false}
                isInsideHeader={true}
                isFirstOnPage={false}
                parentScheme={scheme}
                activeModuleId={activeModuleId}
              />
            )}
            <ActionModuleNavigation
              className="ActionModuleNavigation--on-narrow"
              isPreview={isPreview}
              actionLinks={actionLinks}
              scheme={scheme}
              showQuickLinks={true}
            />
          </div>
        </div>
      )}

      <Header1Mobile
        {...common}
        isAlwaysHamburgerNav={topHeaderVariant === "hamburger-nav"}
      />
    </>
  );
};

export default HeaderLayout1;
