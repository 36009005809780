import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  AspectRatio,
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  Language,
  MediaAlign,
  RichEditorState,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ModuleHeadings from "./ModuleHeadings.js";
import ReadMoreCollapse from "./ReadMoreCollapse.js";
import Slider from "./Slider.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  collapsedLinesCount: number | undefined;
  languageId: Language;
  richEditor: RichEditorState;
  isPreview: boolean;
  displayType: HighlightsDisplayType;
  activeModuleId: string | undefined;
  transparentSliderArrowBackground: boolean;
  mediaAlign: MediaAlign;
  maxColumnsCount: number;
  queries: ContainerQueries;
}

const HighlightsLayout6: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  collapsedLinesCount,
  languageId,
  richEditor,
  isPreview,
  displayType,
  activeModuleId,
  transparentSliderArrowBackground,
  mediaAlign,
  maxColumnsCount,
  queries,
}) => {
  const titleBackground = scheme.main.background;
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const fixedAspectRatio: AspectRatio = 1.5;
  const hasHighlightsTitle = !!title;

  const highlights = items.map(
    ({ id, title, subtitle, picture, imageDetail, buttons, text }, index) => (
      <article
        key={id}
        className={ClassNames("HighlightsLayout6__Item", {
          "HighlightsLayout6__Item--list": displayType === "list",
        })}
      >
        <div className="HighlightsLayout6__ItemPicture">
          <CroppedImage
            aspectRatio={queries["Query--large"] ? undefined : fixedAspectRatio}
            picture={picture}
            width={1280}
            detail={imageDetail}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
        </div>

        <div className="HighlightsLayout6__ItemContent">
          <div className="HighlightsLayout6__ItemContentText">
            <HighlightHeading
              title={{
                value: title,
                className: ClassNames(
                  "HighlightsLayout6__ItemTitle",
                  style({ color: scheme.main.title })
                ),
                children: (
                  <span
                    className={ClassNames(
                      "HighlightsLayout6__ItemTitleInner",
                      style({
                        backgroundColor: titleBackground,
                        boxShadow:
                          `7px 3px 0 ${titleBackground}, -7px 3px 0 ${titleBackground}, ` +
                          `7px 0 0 ${titleBackground}, -7px 0 0 ${titleBackground}`,
                      })
                    )}
                  >
                    {title}
                  </span>
                ),
              }}
              subtitle={{
                value: subtitle,
                className: "HighlightsLayout6__ItemSubtitle",
              }}
              highlightIndex={index}
              hasHighlightsTitle={hasHighlightsTitle}
              isFirstOnPage={isFirstOnPage}
            />

            {text && (
              <div className="HighlightsLayout6__Description">
                {collapsedLinesCount !== undefined ? (
                  <ReadMoreCollapse
                    backgroundColor={scheme.main.separator}
                    languageId={languageId}
                    collapsedLinesCount={collapsedLinesCount}
                    isEditing={!!richEditor[id]}
                  >
                    {text}
                  </ReadMoreCollapse>
                ) : (
                  text
                )}
              </div>
            )}
          </div>
          {!!buttons.length && (
            <div className="HighlightsLayout6__Buttons">{buttons}</div>
          )}
        </div>
      </article>
    )
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout6",
        `HighlightsLayout6--media-${mediaAlign}`,
        style({ background: scheme.main.separator, color: scheme.main.text }),
        {
          "TextAlign--right": mediaAlign === "right",
          "HighlightsLayout6--columns-2": maxColumnsCount === 2,
        }
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <div className="HighlightsLayout6__Items">
          {displayType === "slider" && (
            <Slider
              className="HighlightsLayout6__Slider"
              containerClassName="HighlightsLayout6__SliderContainer"
              slideClassName="HighlightsLayout6__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              onSliderLoaded={setSlider}
              slidesToShow={queries["Query--xlarge"] ? maxColumnsCount : 1}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {displayType === "list" && (
            <div className="HighlightsLayout6__List">{highlights}</div>
          )}
          {displayType === "slider" && items.length > 1 && (
            <div className="HighlightsLayout6__SliderArrows">
              <SliderArrow
                className="HighlightsLayout6__SliderArrow HighlightsLayout6__SliderArrow--left"
                iconClassName="HighlightsLayout6__SliderArrowIcon"
                direction="left"
                onClick={() => slider?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout6__SliderArrow HighlightsLayout6__SliderArrow--right"
                iconClassName="HighlightsLayout6__SliderArrowIcon"
                direction="right"
                onClick={() => slider?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HighlightsLayout6;
