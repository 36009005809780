import { Reducer } from "redux";
import { AllActions } from "../actions/index.js";
import { MediaLibrary, Picture } from "../types/index.js";
import { removeFromArray } from "../utils/utils.js";

const initialState: MediaLibrary = {
  pictures: {},
  logoIds: [],
  groups: [],
  picturesByGroupId: {},
};

const getAllMediaSuccess = (
  state: MediaLibrary,
  pictures: Picture[]
): MediaLibrary => {
  const logoIds = pictures
    .filter(({ category }) => category === "logo")
    .map(({ id }) => id);

  const byId = Object.fromEntries(pictures.map((p) => [p.id, p]));
  return { ...state, pictures: byId, logoIds };
};

const getMediaGroupSuccess = ({
  state,
  groupId,
  pictures,
}: {
  state: MediaLibrary;
  groupId: number;
  pictures: Picture[];
}): MediaLibrary => ({
  ...state,
  picturesByGroupId: {
    ...state.picturesByGroupId,
    [groupId]: pictures.map(({ id }) => id),
  },
});

const uploadMediaSuccess = (
  state: MediaLibrary,
  picture: Picture
): MediaLibrary => ({
  ...state,
  logoIds: [...state.logoIds, picture.id],
  pictures: {
    ...state.pictures,
    [picture.id]: picture,
  },
});

const deleteMediaSuccess = (state: MediaLibrary, id: string): MediaLibrary => {
  const newPictures = { ...state.pictures };
  delete newPictures[id];

  return {
    ...state,
    logoIds: removeFromArray(state.logoIds, id),
    pictures: newPictures,
  };
};

const reducer: Reducer<MediaLibrary, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_ALL_MEDIA_SUCCESS":
      return getAllMediaSuccess(state, action.pictures);

    case "GET_ALL_MEDIA_GROUPS_SUCCESS":
      return { ...state, groups: action.groups };

    case "GET_MEDIA_GROUP_SUCCESS":
      return getMediaGroupSuccess({
        state,
        groupId: action.groupId,
        pictures: action.pictures,
      });

    case "UPLOAD_MEDIA_SUCCESS":
      return uploadMediaSuccess(state, action.picture);

    case "DELETE_MEDIA_SUCCESS":
      return deleteMediaSuccess(state, action.id);

    default:
      return state;
  }
};

export default reducer;
