import { FunctionComponent, useRef } from "react";
import {
  OfferingsType,
  OfferingsWidgetSettings,
  WidgetInstance,
  WindowState,
} from "../types/index.js";
import HgvWidgetContainer from "./HgvWidgetContainer.js";

interface Props {
  type: OfferingsType;
  settings: OfferingsWidgetSettings;
  // If lazyload === false, load the specials widget immediately.
  // This is necessary to open the specials detail lightboxes on page load.
  lazyload: boolean;
  // pinnedItems and maxItems need to be props too because
  // the widget needs to re-initalize if they change
  pinnedItems: number[];
  maxItems: number | undefined;
}

const OfferingsWidget: FunctionComponent<Props> = ({
  type,
  settings,
  lazyload,
  maxItems,
  pinnedItems,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    const { BookingSüdtirol } = window as unknown as WindowState;
    if (!ref.current) return;

    widgetInstanceRef.current = BookingSüdtirol.Widgets[type](
      ref.current,
      settings
    );
  };

  const { onDetailsClose, onDetailsOpen, onLoad, ...settingsToCompare } =
    settings;

  return (
    <HgvWidgetContainer
      widgetInstance={widgetInstanceRef.current}
      initWidget={initWidget}
      settings={{ ...settingsToCompare, maxItems, pinnedItems }}
      enableLazyload={lazyload}
    >
      <div className={`${type}Module__Container`} ref={ref} />
    </HgvWidgetContainer>
  );
};

export default OfferingsWidget;
