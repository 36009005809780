import { AllActions } from "../actions/index.js";
import { LoginState, ServerError } from "../types/index.js";

const initialState: LoginState = {
  isSubmitting: false,
  error: undefined,
};

const postLoginStart = (state: LoginState) => ({
  ...state,
  isSubmitting: true,
});

const postLoginSuccess = (state: LoginState) => ({
  ...state,
  isSubmitting: false,
});

const postLoginError = (state: LoginState, error: ServerError) => ({
  ...state,
  isSubmitting: false,
  error,
});

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "POST_LOGIN_START":
      return postLoginStart(state);

    case "POST_LOGIN_SUCCESS":
      return postLoginSuccess(state);

    case "POST_LOGIN_ERROR":
      return postLoginError(state, action.error);

    default:
      return state;
  }
};

export default reducer;
