import {
  UsercentricsEvent,
  UsercentricsServiceName,
  UsercentricsUI,
  WindowState,
} from "../types/index.js";
import { isBrowser } from "./utils.js";

export const usercentricsDataProcessingServices = <const>[
  "Promotion",
  "Google Maps",
  "YouTube Video",
];

const getUsercentricsUI = (): Promise<UsercentricsUI> =>
  new Promise((resolve) => {
    const initializedEventName = "UC_UI_INITIALIZED";

    const { UC_UI } = window as unknown as WindowState;
    if (UC_UI?.isInitialized()) {
      resolve(UC_UI);
      return;
    }

    const onInitializedEvent = () => {
      const { UC_UI } = window as unknown as WindowState;
      UC_UI && resolve(UC_UI);
      window.removeEventListener(initializedEventName, onInitializedEvent);
    };

    window.addEventListener(initializedEventName, onInitializedEvent);
  });

export const getUsercentricsServiceId = (
  serviceName: string
): Promise<string | undefined> =>
  new Promise(async (resolve) => {
    const UC_UI = await getUsercentricsUI();
    resolve(
      UC_UI.getServicesBaseInfo().find(({ name }) => name === serviceName)?.id
    );
  });

export const getUsercentricsConsent = (
  serviceName: UsercentricsServiceName
): Promise<boolean> =>
  new Promise(async (resolve) => {
    if (!isBrowser) {
      resolve(false);
      return;
    }

    const UC_UI = await getUsercentricsUI();

    const consentStatus =
      UC_UI.getServicesBaseInfo().find(({ name }) => name === serviceName)
        ?.consent.status ?? false;

    resolve(consentStatus);
  });

const checkIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

export const onUsercentricsConsent = (
  serviceName: UsercentricsServiceName
): Promise<void> =>
  new Promise((resolve) => {
    const eventName = "UsercentricsCustomEvent";
    const onEvent = (event: Event) => {
      const consentIsGiven =
        checkIsUsercentricsEvent(event) && event.detail[serviceName];
      consentIsGiven && resolve();
      consentIsGiven && window.removeEventListener(eventName, onEvent);
    };

    window.addEventListener(eventName, onEvent);
  });
