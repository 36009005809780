import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  Language,
  RichEditorState,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ModuleHeadings from "./ModuleHeadings.js";
import ReadMoreCollapse from "./ReadMoreCollapse.js";
import Slider from "./Slider.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  collapsedLinesCount: number | undefined;
  languageId: Language;
  richEditor: RichEditorState;
  isPreview: boolean;
  queries: ContainerQueries;
  activeModuleId: string | undefined;
  maxColumnsCount: number;
  displayType: HighlightsDisplayType;
  transparentSliderArrowBackground: boolean;
}

const HighlightsLayout8: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  collapsedLinesCount,
  languageId,
  richEditor,
  isPreview,
  queries,
  activeModuleId,
  maxColumnsCount,
  displayType,
  transparentSliderArrowBackground,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();

  const columns = getColumnsCount(queries, maxColumnsCount);
  const hasHighlightsTitle = !!title;

  const highlights = items.map(
    (
      { id, title, subtitle, picture, imageDetail, buttons, text, textAlign },
      index
    ) => (
      <article key={id} className="HighlightsLayout8__Item">
        <div className="HighlightsLayout8__ItemPicture">
          <CroppedImage
            aspectRatio={undefined}
            picture={picture}
            width={1280}
            detail={imageDetail}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
        </div>

        <div
          className={ClassNames(
            "HighlightsLayout8__ItemContent",
            style({
              background: scheme.main.background,
              $nest: {
                "&::before": {
                  borderColor: scheme.main.background,
                },
              },
            })
          )}
        >
          <HighlightHeading
            title={{
              value: title,
              className: ClassNames(
                "HighlightsLayout8__ItemTitle",
                `TextAlign--${textAlign.title}`,
                style({
                  color: scheme.main.title,
                })
              ),
              children: title,
            }}
            subtitle={{
              value: subtitle,
              className: ClassNames(
                "HighlightsLayout8__ItemSubtitle",
                `TextAlign--${textAlign.subtitle}`
              ),
            }}
            highlightIndex={index}
            hasHighlightsTitle={hasHighlightsTitle}
            isFirstOnPage={isFirstOnPage}
          />

          {text && (
            <div className="HighlightsLayout8__Description">
              {collapsedLinesCount !== undefined ? (
                <ReadMoreCollapse
                  backgroundColor={scheme.main.background}
                  languageId={languageId}
                  collapsedLinesCount={collapsedLinesCount}
                  isEditing={!!richEditor[id]}
                >
                  {text}
                </ReadMoreCollapse>
              ) : (
                text
              )}
            </div>
          )}
          {!!buttons.length && (
            <div className="HighlightsLayout8__Buttons">{buttons}</div>
          )}
        </div>
      </article>
    )
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout8",
        style({ background: scheme.main.separator, color: scheme.main.text })
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <div className="HighlightsLayout8__Items">
          {displayType === "slider" && (
            <Slider
              className="HighlightsLayout8__Slider"
              containerClassName="HighlightsLayout8__SliderContainer"
              slideClassName="HighlightsLayout8__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              onSliderLoaded={setSlider}
              slidesToShow={columns}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {displayType === "list" && (
            <div
              className={ClassNames("HighlightsLayout8__List", {
                "HighlightsLayout8__List--columns-2": columns === 2,
              })}
            >
              {highlights}
            </div>
          )}
          {displayType === "slider" && items.length > 1 && (
            <div className="HighlightsLayout8__SliderArrows">
              <SliderArrow
                className="HighlightsLayout8__SliderArrow HighlightsLayout8__SliderArrow--left"
                iconClassName="HighlightsLayout8__SliderArrowIcon"
                direction="left"
                onClick={() => slider?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout8__SliderArrow HighlightsLayout8__SliderArrow--right"
                iconClassName="HighlightsLayout8__SliderArrowIcon"
                direction="right"
                onClick={() => slider?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getColumnsCount = (queries: ContainerQueries, maxColumnsCount: number) =>
  queries["Query--xlarge"] ? maxColumnsCount : 1;

export default HighlightsLayout8;
