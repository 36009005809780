import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import { Language, LanguageMenuItem, StoreState } from "../../types/index.js";
import { getLanguageMenuItems } from "../../utils/utils.js";
import SmartLink from "../SmartLink.js";

interface Props {
  className: string;
  isPreview: boolean;
  languageId: Language;
  pageId: string;
  onClick: () => void;
}

interface StateProps {
  items: LanguageMenuItem[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const MobileLanguageMenu: FunctionComponent<Props & ReduxProps> = ({
  isPreview,
  languageId,
  items,
  onClick,
  className,
}) =>
  items.length > 1 ? (
    <ul className={ClassNames("MobileLanguageMenu", className)}>
      {items.map(({ children, href, hrefLang, rel }) => (
        <li
          key={hrefLang}
          className={ClassNames("MobileLanguageMenu__ListItem", {
            "MobileLanguageMenu__ListItem--active": hrefLang === languageId,
          })}
        >
          <SmartLink
            isPreview={isPreview}
            anchorAttrs={{
              rel,
              hrefLang,
              href,
              className: "MobileLanguageMenu__Item",
              onClick,
            }}
          >
            {children}
          </SmartLink>
        </li>
      ))}
    </ul>
  ) : null;

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  { isPreview, pageId }
): StateProps => ({
  items: getLanguageMenuItems({
    isPreview,
    pageId,
    pages,
    siteLanguages: getActiveSite(sites).languages,
    useFullLanguageNames: false,
  }),
});

const connector = connect(mapStateToProps);

export default connector(MobileLanguageMenu);
