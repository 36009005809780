import {
  DndContext,
  DragOverEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { ReactNode, useRef } from "react";

interface Props<Item extends GenericItem> {
  items: Item[];
  onDragOver?: OnDrag<Item>;
  onDragEnd: OnDrag<Item>;
  children: ReactNode;
}

export type OnDrag<Item extends GenericItem> = (
  params: OnDragParams<Item>
) => void;

interface OnDragParams<Item extends GenericItem> {
  id: Item["id"];
  oldIndex: number;
  newIndex: number;
}

export interface GenericItem {
  id: string | number;
}

const SortableList = <Item extends GenericItem>({
  items,
  onDragOver,
  onDragEnd,
  children,
}: Props<Item>) => {
  const dndRef = useRef<OnDragParams<Item>>();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Set a distance threshold to allow clicking on the edit/delete buttons.
        distance: 8,
      },
    })
  );

  const handleDrag = ({ active, over }: DragOverEvent) => {
    if (over?.id === undefined || active.id === over.id) {
      return;
    }
    const oldIndex = items.findIndex(({ id }) => id === active.id);
    const newIndex = items.findIndex(({ id }) => id === over.id);
    const data = { id: active.id, oldIndex, newIndex };
    dndRef.current = data;
    onDragOver?.(data);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={() => {
        dndRef.current && onDragEnd(dndRef.current);
        dndRef.current = undefined;
      }}
      onDragCancel={() => {
        dndRef.current = undefined;
      }}
      onDragOver={handleDrag}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={items}>{children}</SortableContext>
    </DndContext>
  );
};

export default SortableList;
