import { ResizeObserver, ResizeObserverEntry } from "@juggle/resize-observer";
import ClassNames from "classnames";
import {
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ContainerQueries as ContainerQueriesType,
  type ContainerQueries,
} from "../types/index.js";
import { getInlineSize, keys } from "../utils/utils.js";

export const minWidthBreakpoints = {
  "Query--small": 420,
  "Query--medium": 640,
  "Query--large": 1024,
  "Query--xlarge": 1200,
  "Query--xxlarge": 1440,
};

const startQueries: ContainerQueries = {
  "Query--small": false,
  "Query--medium": false,
  "Query--large": false,
  "Query--xlarge": false,
  "Query--xxlarge": false,
};

const queryKeys = keys(startQueries);

const getQueryValues = (queries: ContainerQueries): boolean[] =>
  queryKeys.map((key) => queries[key]);

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, "ref" | "children"> {
  children: (queries: ContainerQueriesType) => ReactNode;
  useViewportWidth: boolean;
}

const ContainerQueries: FunctionComponent<Props> = ({
  useViewportWidth,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [queriesState, setQueries] = useState(startQueries);
  const rafId = useRef<number>();
  const queriesRef = useRef<ContainerQueries>(startQueries);
  queriesRef.current = queriesState;

  useEffect(() => {
    const onResize = (entries: ResizeObserverEntry[]) => {
      const inlineSize = useViewportWidth
        ? window.innerWidth
        : getInlineSize(entries);
      if (inlineSize === undefined) return;

      const newQueries = queryKeys.reduce<ContainerQueries>(
        (acc, key) => {
          acc[key] = inlineSize >= minWidthBreakpoints[key];
          return acc;
        },
        { ...startQueries }
      );

      const valuesHaveChanged =
        getQueryValues(newQueries).toString() !==
        getQueryValues(queriesRef.current).toString();

      valuesHaveChanged && setQueries(newQueries);
    };

    const observer = new ResizeObserver((entries) => {
      rafId.current = window.requestAnimationFrame(() => onResize(entries));
    });

    ref.current && observer.observe(ref.current);

    return () => {
      rafId.current !== undefined && window.cancelAnimationFrame(rafId.current);
      observer.disconnect();
    };
  }, []);

  return (
    <div
      {...props}
      ref={ref}
      className={ClassNames(props.className, queriesRef.current)}
    >
      {props.children(queriesRef.current)}
    </div>
  );
};

export default ContainerQueries;
