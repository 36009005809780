import { I18n } from "../types/index.js";

const it: I18n = {
  boards: {
    0: "Non definito",
    1: "Solo pernottamento",
    2: "Con colazione",
    3: "Mezza pensione",
    4: "Pensione completa",
    5: "All inclusive",
  },
  email: "e-mail",
  phone: "telefono",
  more: "di più",
  moreMenu: "altro",
  languageNames: {
    de: "tedesco",
    it: "italiano",
    en: "inglese",
    es: "spagnolo",
    fr: "francese",
    ru: "russo",
    da: "danese",
    pl: "polacco",
    nl: "olandese",
  },
  menu: "menu",
  imprint: {
    author: "Autore",
    icons: "Icone",
    pictures: "Foto",
  },
  errorOccurred: "Si è verificato un errore. Si prega di riprovare più tardi.",
  countries: {
    main: "Selezione rapida",
    all: "Tutti paesi",
    error: "Errore durante il caricamento della lista dei paesi.",
  },
  genders: {
    m: "Signor",
    f: "Signora",
  },
  register: "Iscrivere",
  formFields: {
    gender: "Titolo",
    firstname: "Nome",
    lastname: "Cognome",
    email: "Indirizzo email",
    country: "Paese",
  },
  newsletterMessages: {
    success:
      "Grazie per aver scelto di iscriverti al nostro servizio newsletter!",
    error:
      "Purtroppo, non è stato possibile completare la registrazione alla newsletter. " +
      "La preghiamo di riprovare più tardi o di inviarci un'e-mail.",
    privacy: (privacyLink) => (
      <>
        Registrandomi alla newsletter approvo le{" "}
        {privacyLink("norme sulla protezione dei dati personali")} (regolamento
        generale sulla protezione dati) e accetto che l´indirizzo e-mail fornito
        possa essere utilizzato al solo fine di fornire il servizio.
      </>
    ),
  },
  on: "su",
};

export default it;
