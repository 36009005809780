import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Language, type ControlItem } from "../types/index.js";
import Icon from "./Icon.js";
import ItemsListButton from "./ItemsListButton.js";

interface Props<Item extends ControlItem> {
  item: ControlItem;
  index: number;
  hasMultiple: boolean;
  onChange: ((itemId: Item["id"], index: number) => void) | undefined;
  onTranslate:
    | ((itemId: Item["id"], sourceLanguageId: Language) => void)
    | undefined;
  onRemove: ((itemId: Item["id"], index: number) => void) | undefined;
}

const ControlItem = <Item extends ControlItem>({
  item,
  index,
  hasMultiple,
  onChange,
  onTranslate,
  onRemove,
}: Props<Item>) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const { translateFrom } = item;

  return (
    <li
      className={ClassNames("ControlItem", {
        "ControlItem--draggable": hasMultiple,
        "ControlItem--semi-transparent": !!translateFrom,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="ControlItem__Inner">
        <div className="ControlItem__Preview">
          <div className="ControlItem__PreviewInner ControlItem__PreviewInner--draggable">
            {item.preview ?? <ItemTitle title={item.title} />}
          </div>
          {hasMultiple && (
            <Icon className="ControlItem__SortIcon" glyph="unfold-more" />
          )}
        </div>
        {onChange && !translateFrom && (
          <ItemsListButton
            title="Bearbeiten"
            icon="edit"
            onClick={() => onChange(item.id, index)}
          />
        )}
        {onTranslate && translateFrom && (
          <ItemsListButton
            title="Übersetzen"
            icon="translate"
            onClick={() => onTranslate(item.id, translateFrom)}
          />
        )}
        {onRemove && !translateFrom && (
          <ItemsListButton
            title="Löschen"
            icon="delete"
            onClick={() => onRemove(item.id, index)}
          />
        )}
      </div>
    </li>
  );
};

const ItemTitle: FunctionComponent<{
  title: string;
}> = ({ title }) => (
  <div className="ControlItem__Title">
    <span className="ControlItem__Caption" title={title}>
      {title}
    </span>
  </div>
);

export default ControlItem;
