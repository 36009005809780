import { ThunkAction } from "../types/index.js";
import { copiedModuleClipboard } from "../utils/utils.js";
import { PostLoginSuccessAction } from "./Login.js";

export type Action = PostLoginSuccessAction | { type: "LOGOUT_USER" };

export const logoutUser = (): ThunkAction<void> => (dispatch) => {
  copiedModuleClipboard.clear();
  dispatch({
    type: "LOGOUT_USER",
  });
};
