import ClassNames from "classnames";
import { FunctionComponent, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";
import { style } from "typestyle";
import { getActiveSite } from "../selectors/sites.js";
import {
  ActionLinks,
  ColorScheme,
  Language,
  MainMenuItem,
  NavLinkWithIcon,
  PageState,
  StoreState,
} from "../types/index.js";
import {
  getActionAndContactLinks,
  getActivePagePath,
  getFallbackLanguage,
  getTopLevelMainMenu,
} from "../utils/utils.js";
import HamburgerMenuList from "./HamburgerMenuList.js";
import HeaderSidebarIconNav from "./HeaderSidebarIconNav.js";
import Icon from "./Icon.js";
import LanguageMenu from "./LanguageMenu.js";

interface Props {
  scheme: ColorScheme;
  languageId: Language;
  isPreview: boolean;
  pageId: string;
  actionLinks: ActionLinks;
  whatsAppNumber: string | undefined;
}

interface StateProps {
  navLinks: NavLinkWithIcon[];
  pages: PageState;
  fallbackLanguageId: Language | undefined;
  activePagePath: string[];
  menuItems: MainMenuItem[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const HeaderSidebar: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  languageId,
  isPreview,
  navLinks,
  pageId,
  pages,
  fallbackLanguageId,
  menuItems,
  activePagePath,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={ClassNames(
        "HeaderSidebar",
        style({ backgroundColor: scheme.main.background })
      )}
    >
      <div className="HeaderSidebar__MenuButtonWrapper" ref={ref}>
        <button
          type="button"
          className="HeaderSidebar__MenuButton Btn--bare"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Icon
            className={ClassNames(
              "HeaderSidebar__MenuIcon",
              style({ fill: scheme.main.text })
            )}
            glyph="view-headline"
          />
        </button>
        {isMenuOpen && (
          <div
            className="HeaderSidebar__MenuWrapper"
            onClick={(event) => {
              // Close menu when clicking on the wrapper but not in the menu
              event.currentTarget === event.target && setIsMenuOpen(false);
            }}
          >
            <RemoveScroll>
              <HamburgerMenuList
                className="HeaderSidebar__Menu"
                scheme={scheme}
                isPreview={isPreview}
                pages={pages}
                languageId={languageId}
                topLevelMainMenuItems={menuItems}
                activePagePath={activePagePath}
                fallbackLanguageId={fallbackLanguageId}
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                outerRef={ref}
                showMenuCaption={true}
              />
            </RemoveScroll>
          </div>
        )}
      </div>

      <HeaderSidebarIconNav
        isPreview={isPreview}
        navLinks={navLinks}
        scheme={scheme}
        hoverTextDirection="right"
      />

      <LanguageMenu
        isPreview={isPreview}
        languageId={languageId}
        scheme={scheme}
        pageId={pageId}
        variant="horizontal"
        switchToSelectLimit={1}
      />
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites, accommodation },
  { languageId, actionLinks, isPreview, pageId, whatsAppNumber }
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);

  return {
    pages,
    navLinks: getActionAndContactLinks(
      accommodation[languageId],
      actionLinks,
      languageId,
      whatsAppNumber
    ),
    fallbackLanguageId,
    menuItems: getTopLevelMainMenu({
      pages,
      languageId,
      isPreview,
      fallbackLanguageId,
    }),
    activePagePath: getActivePagePath(pageId, pages.byParentId),
  };
};

const connector = connect(mapStateToProps);

export default connector(HeaderSidebar);
