import deepEqual from "fast-deep-equal";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript } from "../actions/LoadStates.js";
import { LoadStatus, StoreState, WidgetInstance } from "../types/index.js";
import LazyloadWrapper from "./LazyloadWrapper.js";

interface Props {
  widgetInstance: WidgetInstance | undefined;
  settings: {};
  initWidget: () => void;
  enableLazyload: boolean;
  children: ReactNode;
}

interface StateProps {
  loadStatus: LoadStatus;
}

type ReduxProps = ConnectedProps<typeof connector>;

const bsWidgetScriptUrl = "https://widget.bookingsuedtirol.com/v2/bundle.js";

const HgvWidgetContainer: FunctionComponent<Props & ReduxProps> = ({
  widgetInstance,
  children,
  settings,
  initWidget,
  loadStatus,
  injectScript,
  enableLazyload,
}) => {
  const prevSettingsRef = useRef(settings);
  const [isLazyLoaded, setIsLazyloaded] = useState(false);
  const isReadyToLoad = isLazyLoaded || !enableLazyload;

  useEffect(() => {
    isReadyToLoad && injectScript(bsWidgetScriptUrl);
  }, [isReadyToLoad]);

  useEffect(() => {
    loadStatus === "loaded" && isReadyToLoad && initWidget();
  }, [loadStatus, isReadyToLoad]);

  // Remount if settings change
  useEffect(() => {
    if (loadStatus !== "loaded" || !isReadyToLoad) return;

    const settingsAreEqual = deepEqual(settings, prevSettingsRef.current);
    prevSettingsRef.current = settings;
    if (settingsAreEqual) return;
    widgetInstance?.unmount();
    initWidget();
  }, [settings, widgetInstance, loadStatus, isReadyToLoad]);

  return <LazyloadWrapper onLoad={setIsLazyloaded}>{children}</LazyloadWrapper>;
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  loadStates,
}): StateProps => ({
  loadStatus: loadStates.scripts[bsWidgetScriptUrl] ?? "unloaded",
});

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HgvWidgetContainer);
