import ClassNames from "classnames";
import { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { style } from "typestyle";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import { Captions } from "yet-another-react-lightbox/plugins";
import globalTranslations from "../../i18n/index.js";
import {
  getPictureWithSizedURL,
  getPicturesById,
} from "../../selectors/pictures.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  I18n,
  ImageGalleryModuleSettings,
  ImageModuleSettings,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getGalleryMediaAspectRatio,
  getModulesByParentId,
  getPlaceholderImage,
  getTranslatedModule,
  getTranslations,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import ImageModule from "./ImageModule.js";

interface Props extends BaseModuleProps<ImageGalleryModuleSettings> {}

interface StateProps {
  slides: SlideImage[];
  mediaLibraryLoaded: boolean;
  scheme: ColorScheme;
  i18n: I18n;
  images: TranslatedModule<ImageModuleSettings>[];
}

const ImageGalleryModule: FunctionComponent<Props & StateProps> = ({
  translatedModule,
  translatedModule: {
    settings,
    translation: {
      settings: { title, subtitle },
    },
  },
  mediaLibraryLoaded,
  slides,
  scheme,
  isFirstOnPage,
  isPreview,
  i18n,
  images,
  pageId,
  isActive,
}) => {
  const [maxImagesToShow, setMaxImagesToShow] = useState(5);
  const [openIndex, setOpenIndex] = useState(-1);

  if (!mediaLibraryLoaded) return null;
  const showMoreButton = maxImagesToShow < images.length;
  const shownImages = images.slice(0, maxImagesToShow);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={translatedModule.id}
      className="ImageGalleryModule"
      colors={{ background: scheme.main.background }}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          textAlign={settings.textAlign}
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          title={title}
          subtitle={subtitle}
        />

        <div className="ImageGalleryModule__Wrapper">
          {shownImages.map((imageModule, index) => {
            const item = slides[index];
            return (
              <a
                href={isPreview || !item ? undefined : item.src}
                className="ImageGalleryModule__Item"
                key={imageModule.id}
                onClick={(e) => {
                  e.preventDefault();
                  if (isPreview && !isActive) return;
                  setOpenIndex(index);
                }}
              >
                <ImageModule
                  aspectRatio={getGalleryMediaAspectRatio(index)}
                  sizes="50vw"
                  lazyLoad={true}
                  width={1920}
                  translatedModule={imageModule}
                  isPreview={isPreview}
                  isSlider={false}
                  pageId={pageId}
                  scheme={scheme}
                  showOverlay={false}
                />
              </a>
            );
          })}
        </div>
        {showMoreButton && (
          <div className="ImageGalleryModule__ButtonWrap">
            <button
              className={ClassNames(
                "Btn--bare",
                "ImageGalleryModule__Button",
                style({
                  color: scheme.primary.background,
                })
              )}
              onClick={() => setMaxImagesToShow(maxImagesToShow + 8)}
              title={i18n.more}
            >
              <Icon className="ImageGalleryModule__Icon" glyph="arrow-down" />
            </button>
          </div>
        )}
      </div>

      <Lightbox
        open={openIndex >= 0}
        index={openIndex}
        close={() => setOpenIndex(-1)}
        slides={slides}
        plugins={[Captions]}
        animation={{ navigation: 0 }}
      />
    </ModuleWithHeadings>
  );
};

const mapStateToProps = (
  { modules, mediaLibrary, loadStates, colorSchemes, sites }: StoreState,
  { translatedModule, pageId }: Props
): StateProps => {
  const site = getActiveSite(sites);
  const {
    translation: { languageId },
  } = translatedModule;

  const images = getModulesByParentId<ImageModuleSettings>(
    modules,
    translatedModule.id,
    pageId,
    "ImageModule"
  ).map((module) => getTranslatedModule(module, languageId));

  const pictureIds = images.map(
    (imageModule) => imageModule.settings.pictureId
  );

  const slides = !images.length
    ? []
    : getPicturesById(mediaLibrary.pictures, pictureIds)
        .map(
          getPictureWithSizedURL({
            width: 1920,
            height: 1080,
            mode: 4,
          })
        )
        .map<SlideImage>(({ url, title }) => ({
          src: url,
          width: 1920,
          height: 1080,
          alt: title ?? "",
          description: title,
        }));

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);
  const i18n = getTranslations(languageId, globalTranslations);

  return {
    images: images.length
      ? images
      : new Array(5).fill(true).map((_, index) =>
          getPlaceholderImage({
            siteId: site.id,
            pageId,
            languageId,
            parentId: translatedModule.id,
            index,
          })
        ),
    slides,
    mediaLibraryLoaded: loadStates.mediaLibrary.pictures === "loaded",
    scheme,
    i18n,
  };
};

export default connect(mapStateToProps)(ImageGalleryModule);
