import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  AspectRatio,
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  HighlightsLayout,
  Language,
  RichEditorState,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import ModuleHeadings from "./ModuleHeadings.js";
import MultiBoxItem from "./MultiBoxItem.js";
import MultiBoxSliderArrow from "./MultiBoxSliderArrow.js";
import MultiBoxTile from "./MultiBoxTile.js";
import Slider from "./Slider.js";

const calcItemsPerView = (
  queries: ContainerQueries | undefined,
  layout: HighlightsLayout,
  maxColumnsCount: number
): number => {
  if (layout === "layout-2") {
    return queries && queries["Query--large"] ? maxColumnsCount : 1;
  }

  if (!queries) return maxColumnsCount;
  if (queries["Query--xxlarge"]) return Math.min(4, maxColumnsCount);
  if (queries["Query--large"]) return Math.min(3, maxColumnsCount);
  if (queries["Query--medium"]) return Math.min(2, maxColumnsCount);

  return 1;
};

const isTileLayout = (layout: HighlightsLayout) => layout === "layout-3";

interface Props {
  layout: HighlightsLayout;
  scheme: ColorScheme;
  displayType: HighlightsDisplayType;
  mediaAspectRatio: AspectRatio;
  queries?: ContainerQueries;
  items: HighlightsItem[];
  isPreview: boolean;
  isFirstOnPage: boolean;
  contentOpenOnIndex?: number;
  activeModuleId: string | undefined;
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  maxColumnsCount: number;
  placeholdersShown: boolean;
  languageId: Language;
  collapsedLinesCount: number | undefined;
  richEditor: RichEditorState;
}

const MultiBox: FunctionComponent<Props> = ({
  items,
  layout,
  scheme,
  displayType: settingsDisplayType,
  queries,
  isPreview,
  mediaAspectRatio,
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  maxColumnsCount,
  placeholdersShown,
  languageId,
  collapsedLinesCount,
  richEditor,
  activeModuleId,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const goToPrevSlide = () => slider?.scrollPrev();
  const goToNextSlide = () => slider?.scrollNext();

  const itemsPerView = calcItemsPerView(queries, layout, maxColumnsCount);
  const itemsFillColumns = !(items.length % itemsPerView);
  const itemsFitInOneRow = items.length <= itemsPerView;
  const displayType: HighlightsDisplayType =
    (itemsFillColumns && settingsDisplayType === "list") || itemsFitInOneRow
      ? "list"
      : "slider";
  const hasSlider = displayType === "slider";

  const hasImages = items.some(
    ({ imageDetail: imageSettings }) => imageSettings
  );
  const hasHighlightsTitle = !!title;

  const multiBoxItems = items.map((item, index) => {
    const { id, title, subtitle, picture, link, imageDetail } = item;

    return isTileLayout(layout) ? (
      <MultiBoxTile
        key={id}
        title={title}
        subtitle={subtitle}
        picture={picture}
        link={link}
        mediaAspectRatio={mediaAspectRatio}
        imageDetail={imageDetail}
        isPreview={isPreview}
        tilesCount={items.length}
        hasHighlightsTitle={hasHighlightsTitle}
        index={index}
        isFirstOnPage={isFirstOnPage}
      />
    ) : (
      <MultiBoxItem
        key={id}
        item={item}
        scheme={scheme}
        mediaAspectRatio={mediaAspectRatio}
        isPreview={isPreview}
        isPlaceholderItem={placeholdersShown}
        languageId={languageId}
        collapsedLinesCount={collapsedLinesCount}
        isEditing={!!richEditor[id]}
        hasHighlightsTitle={hasHighlightsTitle}
        index={index}
        isFirstOnPage={isFirstOnPage}
      />
    );
  });

  return (
    <div
      className={ClassNames(
        "MultiBox",
        `MultiBox--${layout}`,
        `MultiBox--${displayType}`,
        `MultiBox--count-${items.length}`,
        {
          "MultiBox--center": items.length < 3,
          [`MultiBox--max-columns-count-${maxColumnsCount}`]:
            displayType === "list",
        },
        style({
          background: scheme.main.separator,
        })
      )}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div
        className={ClassNames("MultiBox__WrapperOuter", {
          "MultiBox__WrapperOuter--narrow":
            layout === "layout-1" && maxColumnsCount === 2,
        })}
      >
        <div className="MultiBox__Main Module__Wrapper">
          {hasSlider && (
            <>
              <MultiBoxSliderArrow
                scheme={scheme}
                type="prev"
                mediaAspectRatio={mediaAspectRatio}
                slidesPerView={itemsPerView}
                onClick={goToPrevSlide}
                hasImages={hasImages}
              />
              <MultiBoxSliderArrow
                scheme={scheme}
                type="next"
                mediaAspectRatio={mediaAspectRatio}
                slidesPerView={itemsPerView}
                onClick={goToNextSlide}
                hasImages={hasImages}
              />
              <div className="MultiBox__WrapperInner">
                <Slider
                  isPreview={isPreview}
                  onSliderLoaded={setSlider}
                  className="MultiBox__Slider"
                  containerClassName="MultiBox__SliderContainer"
                  slideClassName="MultiBox__SliderSlide"
                  slidesToShow={itemsPerView}
                  sliderEffect="slide"
                  activePreviewSlideId={activeModuleId}
                  slideIds={items.map(({ id }) => id)}
                >
                  {multiBoxItems}
                </Slider>
              </div>
            </>
          )}

          {!hasSlider && (
            <div className="MultiBox__WrapperInner">{multiBoxItems}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiBox;
