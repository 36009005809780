import ClassNames from "classnames";
import { FunctionComponent, MouseEvent } from "react";
import { AspectRatio, ColorScheme } from "../types/index.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  scheme: ColorScheme;
  slidesPerView: number;
  mediaAspectRatio: AspectRatio;
  type: "prev" | "next";
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  hasImages: boolean;
}

const MultiBoxSliderArrow: FunctionComponent<Props> = ({
  slidesPerView,
  scheme,
  type,
  onClick,
  mediaAspectRatio,
  hasImages,
}) => (
  <div
    className={ClassNames(
      "MultiBox__NavigationWrapper",
      "AspectRatio",
      `MultiBox__NavigationWrapper--${slidesPerView}`,
      `MultiBox__NavigationWrapper--${type}`,

      {
        "MultiBox__NavigationWrapper--has-images": hasImages,
      }
    )}
    style={{ "--aspect-ratio": mediaAspectRatio } as React.CSSProperties}
  >
    <SliderArrow
      className={`MultiBox__Navigation MultiBox__Navigation--${type}`}
      iconClassName="MultiBox__NavigationIcon"
      scheme={scheme}
      onClick={onClick}
      direction={type === "prev" ? "left" : "right"}
      transparentBackground={false}
    />
  </div>
);

export default MultiBoxSliderArrow;
