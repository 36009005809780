import ClassNames from "classnames";
import { FunctionComponent, useState } from "react";
import { Language, MainMenuItem, PageState } from "../types/index.js";
import { getMenuItems } from "../utils/utils.js";
import Icon from "./Icon.js";
import MainMenuListCollapsible, {
  ClassNamesProp,
} from "./MainMenuListCollapsible.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  languageId: Language;
  parentId: string;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  isRepeatedMenuItem?: boolean;
  onMenuItemClick: () => void;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  collapseLevel: number;
  classNames: ClassNamesProp;
}

const MainMenuLevelCollapsible: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  parentId,
  pages,
  currentMenuItem,
  isRepeatedMenuItem,
  onMenuItemClick,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
  collapseLevel,
  classNames,
}) => {
  const menuItems = getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
  const hasSubpages = !!menuItems.length;
  const { title, href, id, isFolder, target } = currentMenuItem;
  const isActive = activePagePath[0] === id;

  const containsActiveSubmenuItem = menuItems.some(({ id }) =>
    activePagePath.includes(id)
  );
  const [isOpen, setIsOpen] = useState(containsActiveSubmenuItem);
  const isCollapseLevel = menuLevel === collapseLevel;

  const listItemClassName = ClassNames(classNames.listItem, {
    [classNames.listItemLevel0]: menuLevel === 0,
    [classNames.listItemLevel1]: menuLevel === 1,
    [classNames.listItemLevel2up]: menuLevel >= 2,
  });

  const linkItemClassName = ClassNames(classNames.item, {
    [classNames.itemLevel0]: menuLevel === 0,
    [classNames.itemLevel1]: menuLevel === 1,
    [classNames.itemLevel2up]: menuLevel >= 2,
    [classNames.itemActive]: isActive,
  });

  const nextMenuLevel = menuLevel + 1;
  const linkContent = <span className={classNames.text}>{title}</span>;
  const isCollapsibleItem = isCollapseLevel && hasSubpages;
  const isLink = isPreview || !isFolder;

  return (
    <li className={ClassNames(listItemClassName)}>
      <div className={classNames.itemWrap}>
        {isCollapsibleItem && (
          <button
            type="button"
            className={ClassNames(linkItemClassName, "BareBtn")}
            onClick={() => setIsOpen(!isOpen)}
          >
            {linkContent}
          </button>
        )}

        {!isLink && !isCollapsibleItem && (
          <span className={linkItemClassName} onClick={onMenuItemClick}>
            {linkContent}
          </span>
        )}

        {isLink && !isCollapsibleItem && (
          <SmartLink
            isPreview={isPreview}
            anchorAttrs={{
              href,
              target,
              className: linkItemClassName,
              onClick: onMenuItemClick,
            }}
          >
            {linkContent}
          </SmartLink>
        )}

        {isCollapseLevel && hasSubpages && (
          <button
            type="button"
            className={classNames.expandButton}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon
              className={ClassNames(classNames.expandIcon, {
                [classNames.expandIconOpen]: isOpen,
              })}
              glyph={"arrow-down"}
            />
          </button>
        )}
      </div>

      {!isRepeatedMenuItem && (
        <MainMenuListCollapsible
          isPreview={isPreview}
          languageId={languageId}
          menuItems={menuItems}
          pages={pages}
          onMenuItemClick={onMenuItemClick}
          menuLevel={nextMenuLevel}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          collapseLevel={collapseLevel}
          parentMenuItem={currentMenuItem}
          isOpen={isOpen}
          classNames={classNames}
        />
      )}
    </li>
  );
};

export default MainMenuLevelCollapsible;
