import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  ColorScheme,
  ContainerQueries,
  HighlightsAspectRatio,
  HighlightsItem,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ModuleHeadings from "./ModuleHeadings.js";
import Slider from "./Slider.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  isPreview: boolean;
  queries: ContainerQueries;
  activeModuleId: string | undefined;
  transparentSliderArrowBackground: boolean;
}

const HighlightsLayout9: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  isPreview,
  queries,
  activeModuleId,
  transparentSliderArrowBackground,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const hasHighlightsTitle = !!title;

  return (
    <div
      className={ClassNames(
        "HighlightsLayout9",
        style({ background: scheme.main.background, color: scheme.main.text })
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div
        className={ClassNames(
          "HighlightsLayout9__Items",
          style({
            $nest: { "&::before": { background: scheme.main.separator } },
          })
        )}
      >
        <div className="HighlightsLayout9__ItemsInner Module__Wrapper">
          <Slider
            className="HighlightsLayout9__Slider"
            containerClassName="HighlightsLayout9__SliderContainer"
            slideClassName="HighlightsLayout9__Slide"
            isPreview={isPreview}
            sliderEffect="slide"
            onSliderLoaded={setSlider}
            slidesToShow={1}
            activePreviewSlideId={activeModuleId}
            slideIds={items.map(({ id }) => id)}
          >
            {items.map(
              (
                { id, title, subtitle, picture, imageDetail, buttons },
                index
              ) => {
                const prevItem =
                  items[(index === 0 ? items.length : index) - 1];
                const prevTitle = prevItem?.title || prevItem?.subtitle;
                const nextItem =
                  items[index === items.length - 1 ? 0 : index + 1];
                const nextTitle = nextItem?.title || nextItem?.subtitle;
                const backgroundAndTitleStyle = style({
                  background: scheme.main.background,
                  color: scheme.main.title,
                });

                return (
                  <article key={id} className="HighlightsLayout9__Item">
                    <div
                      className={ClassNames(
                        "HighlightsLayout9__NavTitle",
                        "HighlightsLayout9__NavTitle--left",
                        backgroundAndTitleStyle
                      )}
                    >
                      {prevTitle}
                    </div>

                    <div className="HighlightsLayout9__ItemMain">
                      <div className="HighlightsLayout9__ItemPicture">
                        <CroppedImage
                          aspectRatio={getAspectRatio(queries)}
                          picture={picture}
                          width={1280}
                          detail={imageDetail}
                          sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
                        />
                      </div>

                      <HighlightHeading
                        className={ClassNames(
                          "HighlightsLayout9__ItemContent",
                          style({ background: scheme.main.background })
                        )}
                        title={{
                          value: title,
                          className: ClassNames(
                            "HighlightsLayout9__ItemTitle",
                            style({
                              color: scheme.main.title,
                            })
                          ),
                          children: title,
                        }}
                        subtitle={{
                          value: subtitle,
                          className: "HighlightsLayout9__ItemSubtitle",
                        }}
                        highlightIndex={index}
                        hasHighlightsTitle={hasHighlightsTitle}
                        isFirstOnPage={isFirstOnPage}
                      />

                      {!!buttons.length && (
                        <div className="HighlightsLayout9__Buttons">
                          {buttons}
                        </div>
                      )}
                    </div>

                    <div
                      className={ClassNames(
                        "HighlightsLayout9__NavTitle",
                        "HighlightsLayout9__NavTitle--right",
                        backgroundAndTitleStyle
                      )}
                    >
                      {nextTitle}
                    </div>
                  </article>
                );
              }
            )}
          </Slider>
          {items.length > 1 && (
            <div className="HighlightsLayout9__SliderArrows">
              <SliderArrow
                className="HighlightsLayout9__SliderArrow HighlightsLayout9__SliderArrow--left"
                iconClassName="HighlightsLayout9__SliderArrowIcon"
                direction="left"
                onClick={() => slider?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout9__SliderArrow HighlightsLayout9__SliderArrow--right"
                iconClassName="HighlightsLayout9__SliderArrowIcon"
                direction="right"
                onClick={() => slider?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getAspectRatio = (queries: ContainerQueries): HighlightsAspectRatio =>
  queries["Query--medium"] ? 1.5 : 0.8333;

export default HighlightsLayout9;
