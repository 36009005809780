import ClassNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { getIsHeaderWithFixedSidebar } from "../selectors/modules.js";
import { getActiveSite } from "../selectors/sites.js";
import { StoreState } from "../types/index.js";
import { getDynamicTitleFontsCSS } from "../utils/dynamicFonts.js";

interface Props {
  isPreview: boolean;
  children: ReactNode;
}

interface StateProps {
  isHeaderWithSidebar: boolean;
  dynamicFontRules: NestedCSSProperties;
  isLightFont: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const SiteInner: FunctionComponent<Props & ReduxProps> = ({
  children,
  dynamicFontRules,
  isHeaderWithSidebar,
  isLightFont,
}) => (
  <div
    className={ClassNames(
      "Site__Inner",
      {
        "Site__Inner--with-sidebar": isHeaderWithSidebar,
        "Site__Inner--light-font": isLightFont,
      },
      style(dynamicFontRules)
    )}
  >
    {children}
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, fonts, sites },
  { isPreview }
): StateProps => {
  const site = getActiveSite(sites);
  return {
    isHeaderWithSidebar: getIsHeaderWithFixedSidebar(modules),
    dynamicFontRules: getDynamicTitleFontsCSS({ site, isPreview, fonts }),
    isLightFont:
      fonts.byFamily[site.fontFamily]?.variants.some(
        ({ weight }) => weight === "300"
      ) ?? false,
  };
};

const connector = connect(mapStateToProps);

export default connector(SiteInner);
