import de from "./de.js";
import en from "./en.js";
import fr from "./fr.js";
import it from "./it.js";

export default {
  de,
  it,
  en,
  fr,
};
