import { Reducer } from "redux";
import { AllActions } from "../actions/index.js";
import {
  Language,
  ModuleType,
  Page,
  PageRequest,
  PagesByAlias,
  PagesById,
  PagesByParentId,
  PageState,
  PageTranslation,
  PageTranslationRequest,
} from "../types/index.js";
import {
  customSlugify,
  isItemUntranslated,
  keys,
  PageNotFoundError,
} from "../utils/utils.js";

export const initialState: PageState = {
  byId: {},
  byAlias: {},
  byParentId: {
    null: [],
  },
  systemPages: [],
};

export const getPagesSuccess = (state: PageState, pages: Page[]): PageState => {
  const [byId, byAlias, byParentId, systemPages] = pages.reduce<
    [PagesById, PagesByAlias, PagesByParentId, string[]]
  >(
    ([byId, byAlias, byParentId, systemPages], page) => {
      byId[page.id] = page;

      if (page.isSystemPage) {
        systemPages = [...systemPages, page.id];
      } else {
        const parentId = String(page.parentId);
        const byCurrentParentId: string[] = byParentId[parentId] || [];
        byParentId[parentId] = [...byCurrentParentId, page.id];
      }

      if (page.alias) {
        byAlias[page.alias] = page.id;
      }

      return [byId, byAlias, byParentId, systemPages];
    },
    [{}, {}, { null: [] }, []]
  );

  return { ...state, byId, byAlias, byParentId, systemPages };
};

const postPageSuccess = (state: PageState, page: Page): PageState => {
  state = replacePage(state, page);
  state = addPageToParent(state, page, page, false, false);
  return state;
};

const replacePage = (state: PageState, page: Page): PageState => {
  return {
    ...state,
    byId: { ...state.byId, [page.id]: page },
  };
};

const getRecursiveSubpages = (
  byParentId: PagesByParentId,
  pageId: string
): string[] => {
  const subpages = byParentId[pageId] ?? [];
  if (!subpages.length) return [];

  const allSubpages = subpages.reduce<string[]>(
    (accumulator, subpageId) => [
      ...accumulator,
      ...getRecursiveSubpages(byParentId, subpageId),
    ],
    subpages
  );

  return allSubpages;
};

const deletePageTranslationStart = (
  state: PageState,
  pageId: string,
  languageId: Language
): PageState => {
  const { byId, byParentId } = state;
  const page = byId[pageId];

  if (!page) throw new PageNotFoundError(pageId);

  // Delete all subpages in all languages if there’s
  // only translation left on the current page
  if (isItemUntranslated(page)) {
    const pageIdsToDelete = [
      pageId,
      ...getRecursiveSubpages(byParentId, pageId),
    ];

    state = pageIdsToDelete.reduce<PageState>((accumulator, currentPageId) => {
      const pageToDelete = byId[currentPageId];
      if (!pageToDelete) throw new PageNotFoundError(currentPageId);
      return removePageFromParent(accumulator, pageToDelete);
    }, state);

    const newById = pageIdsToDelete.reduce<PagesById>(
      (accumulator, currentPageId) => {
        const { [currentPageId]: _toDiscard, ...rest } = accumulator;
        return rest;
      },
      byId
    );

    return { ...state, byId: newById };
  }

  const newTranslations = { ...page.translations };
  delete newTranslations[languageId];

  const newState: PageState = {
    ...state,
    byId: {
      ...byId,
      [pageId]: {
        ...page,
        translations: newTranslations,
      },
    },
  };

  return newState;
};

const dragPage = (
  state: PageState,
  sourcePageId: string,
  targetPageId: string,
  insertBefore: boolean,
  isNewSubtree: boolean
): PageState => {
  const source = state.byId[sourcePageId];
  const target = state.byId[targetPageId];

  if (!source) throw new PageNotFoundError(sourcePageId);
  if (!target) throw new PageNotFoundError(targetPageId);

  state = removePageFromParent(state, source);

  state = addPageToParent(state, source, target, insertBefore, isNewSubtree);

  // Update source item
  const newParentId = isNewSubtree ? target.id : target.parentId;
  return patchPageStart(state, source.id, { parentId: newParentId });
};

const patchPageStart = (
  state: PageState,
  pageId: string,
  { translations, ...pageRequestRest }: PageRequest
): PageState => {
  const currentPage = state.byId[pageId];
  if (!currentPage) throw new PageNotFoundError(pageId);
  const newPage = { ...currentPage, ...pageRequestRest };
  const newState = replacePage(state, newPage);

  if (!translations) {
    return newState;
  }

  // Update the translations too
  const newStateWithTranslations = keys(translations).reduce<PageState>(
    (carryState, languageId) => {
      const translation = translations[languageId];
      if (!translation) return carryState;

      return patchPageTranslationStart(
        carryState,
        pageId,
        languageId,
        translation,
        true
      );
    },
    newState
  );

  return newStateWithTranslations;
};

const patchPageSuccess = (state: PageState, page: Page): PageState => {
  const { translations, ...pageWithoutTranslations } = page;
  const existingPage = state.byId[page.id];
  if (!existingPage) throw new PageNotFoundError(page.id);

  const newState: PageState = {
    ...state,
    byId: {
      ...state.byId,
      [page.id]: { ...existingPage, ...pageWithoutTranslations },
    },
  };

  const stateWithUpdatedTranslations = keys(translations).reduce<PageState>(
    (acc, languageId) => {
      const translation = translations[languageId];
      if (!translation) return acc;
      return patchPageTranslationSuccess(state, page.id, translation);
    },
    newState
  );

  return stateWithUpdatedTranslations;
};

const patchPageTranslationStart = (
  state: PageState,
  pageId: string,
  languageId: Language,
  pageTranslationRequest: PageTranslationRequest,
  setUpdatedAt: boolean
): PageState => {
  const page = state.byId[pageId];
  const translation = page?.translations[languageId];
  if (!translation) return state;

  const newTranslation: PageTranslation = {
    ...translation,
    ...pageTranslationRequest,
    updatedAt: setUpdatedAt ? new Date().toJSON() : translation.updatedAt,
  };
  const slug = newTranslation.slug && customSlugify(newTranslation.slug);

  return replacePage(state, {
    ...page,
    translations: {
      ...page.translations,
      [languageId]: { ...newTranslation, slug },
    },
  });
};

const patchPageTranslationSuccess = (
  state: PageState,
  pageId: string,
  pageTranslation: PageTranslation
): PageState => {
  const existingPageTranslation: PageTranslation | undefined =
    state.byId[pageId]?.translations[pageTranslation.languageId];

  // Only update the fields that are processed by the server,
  // otherwise text fields could be overwritten
  const updatedPageTranslation: PageTranslation = !existingPageTranslation
    ? pageTranslation
    : {
        ...existingPageTranslation,
        createdAt: pageTranslation.createdAt,
        updatedAt: pageTranslation.updatedAt,
        slug: pageTranslation.slug,
      };

  return patchPageTranslationStart(
    state,
    pageId,
    pageTranslation.languageId,
    updatedPageTranslation,
    false
  );
};

const postPageTranslationSuccess = (
  state: PageState,
  pageId: string,
  pageTranslation: PageTranslation
): PageState => {
  const page = state.byId[pageId];
  if (!page) throw new PageNotFoundError(pageId);

  const { languageId } = pageTranslation;
  const newPage: Page = {
    ...page,
  };
  newPage.translations[languageId] = pageTranslation;
  return replacePage(state, newPage);
};

const removePageFromParent = (state: PageState, source: Page): PageState => {
  const sourceParentIds =
    state.byParentId[String(source.parentId)]?.filter(
      (id) => id !== source.id
    ) ?? [];

  return {
    ...state,
    byParentId: {
      ...state.byParentId,
      [String(source.parentId)]: sourceParentIds,
    },
  };
};

const addPageToParent = (
  state: PageState,
  source: Page,
  target: Page,
  insertBefore: boolean,
  isNewSubtree: boolean
): PageState => {
  const newParentId = String(isNewSubtree ? target.id : target.parentId);
  const targetParentIds = [...(state.byParentId[newParentId] || [])];

  let newIndex = targetParentIds.indexOf(target.id);
  newIndex = insertBefore ? newIndex : newIndex + 1;

  targetParentIds.splice(newIndex, 0, source.id);

  return {
    ...state,
    byParentId: {
      ...state.byParentId,
      [newParentId]: targetParentIds,
    },
  };
};

// Remove popUpModuleId references from pages in case a PopUpModule is deleted
const deleteModuleTranslationStart = (
  state: PageState,
  {
    moduleId,
    moduleType,
    deleteAllTranslations,
  }: {
    moduleId: string;
    moduleType: ModuleType;
    deleteAllTranslations: boolean;
  }
): PageState => {
  if (!deleteAllTranslations || moduleType !== "PopUpModule") {
    return state;
  }

  const newPagesById = Object.entries(state.byId).reduce<PagesById>(
    (acc, [pageId, page]) => {
      acc[pageId] = {
        ...page,
        popUpModuleId:
          page.popUpModuleId === moduleId ? null : page.popUpModuleId,
      };

      return acc;
    },
    {}
  );

  return { ...state, byId: newPagesById };
};

const reducer: Reducer<PageState, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_PAGES_SUCCESS":
      return getPagesSuccess(state, action.pages);

    case "POST_PAGE_SUCCESS":
      return postPageSuccess(state, action.page);

    case "PATCH_PAGE_START":
      return patchPageStart(state, action.pageId, action.pageRequest);

    case "PATCH_PAGE_SUCCESS":
      return patchPageSuccess(state, action.page);

    case "PATCH_PAGE_ERROR":
      return action.prevState;

    case "POST_PAGE_TRANSLATION_SUCCESS":
      return postPageTranslationSuccess(
        state,
        action.pageId,
        action.pageTranslation
      );

    case "DELETE_PAGE_TRANSLATION_START":
      return deletePageTranslationStart(
        state,
        action.pageId,
        action.languageId
      );

    case "DELETE_PAGE_TRANSLATION_ERROR":
      return action.prevState;

    case "DRAG_PAGE":
      return dragPage(
        state,
        action.sourcePageId,
        action.targetPageId,
        action.insertBefore,
        action.isNewSubtree
      );

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslationStart(state, {
        deleteAllTranslations: action.deleteAllTranslations,
        moduleId: action.moduleId,
        moduleType: action.moduleType,
      });

    default:
      return state;
  }
};

export default reducer;
