import { AllActions } from "../actions/index.js";
import { RichEditorState } from "../types/index.js";

const initialState: RichEditorState = {};

const setEditorState = (
  state: RichEditorState,
  moduleId: string,
  isActive: boolean
) => ({
  ...state,
  [moduleId]: isActive,
});

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "SET_EDITOR_ACTIVE_STATE":
      return setEditorState(state, action.moduleId, action.isActive);

    default:
      return state;
  }
};

export default reducer;
