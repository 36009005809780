import { FunctionComponent, useRef } from "react";
import {
  PricesWidgetSettings,
  WidgetInstance,
  WindowState,
} from "../types/index.js";
import HgvWidgetContainer from "./HgvWidgetContainer.js";

interface Props {
  settings: PricesWidgetSettings;
}

const PricesWidget: FunctionComponent<Props> = ({ settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    if (!ref.current) return;

    const { BookingSüdtirol } = window as unknown as WindowState;
    widgetInstanceRef.current = BookingSüdtirol.Widgets.Prices(
      ref.current,
      settings
    );
    widgetInstanceRef.current.render();
  };

  return (
    <HgvWidgetContainer
      widgetInstance={widgetInstanceRef.current}
      initWidget={initWidget}
      settings={settings}
      enableLazyload={true}
    >
      <div className="PricesWidget__Container" ref={ref} />
    </HgvWidgetContainer>
  );
};

export default PricesWidget;
