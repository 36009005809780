import { ErrorInfo } from "react";
import { isDevEnvironment } from "./utils.js";

const sendErrorToSentry = async (error: Error) => {
  const Sentry = await import("@sentry/browser");

  Sentry.init({
    dsn: "https://ea36ef58392c8724ae96f6a2f11035d2@o4508256406208512.ingest.de.sentry.io/4508357975736400",
    enabled: !isDevEnvironment,
    autoSessionTracking: false,
    ignoreErrors: [
      // TODO: find a better way to exclude this error
      "Request failed with status code 401",
    ],
    beforeSend: (event) => {
      const exception = event.exception?.values?.[0];
      if (!exception) return event;

      // Filter out an error that occurs with lazysizes in combination with
      // the Facebook browser on iOS. See https://github.com/aFarkas/lazysizes/issues/520
      const isLazySizesFBError =
        exception.value === "Illegal invocation" &&
        location.search.indexOf("fbclid=") !== -1;

      return isLazySizesFBError ? null : event;
    },
  });

  Sentry.captureException(error);
};

export const registerErrorReporting = () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const onFirstError = (event: ErrorEvent) => {
    sendErrorToSentry(event.error);
    window.removeEventListener("error", onFirstError);
  };

  window.addEventListener("error", onFirstError);
};

export const trackErrorWithService = (error: Error, errorInfo?: ErrorInfo) => {
  console.error(error, errorInfo);
  sendErrorToSentry(error);
};
