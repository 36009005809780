import ClassNames from "classnames";
import { FunctionComponent, useRef, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { CSSTransition } from "react-transition-group";
import { style } from "typestyle";
import { CommonHeaderLayoutProps } from "../../types/index.js";
import {
  useIntersection,
  useKeyDown,
  useScrollDirection,
} from "../../utils/hooks.js";
import {
  getActionAndContactLinks,
  getBookingEnquiryLinksWithIcon,
  getContactLinks,
  onTransitionEnd,
  overrideImagesAspectRatio,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import MainMenuListCollapsible, {
  verticalMenuClassNames,
} from "../MainMenuListCollapsible.js";
import ImagesModule from "../Modules/ImagesModule.js";
import SmartLink from "../SmartLink.js";
import HamburgerButton from "./HamburgerButton.js";
import MobileLanguageMenu from "./MobileLanguageMenu.js";
import MobileMainLogo from "./MobileMainLogo.js";

const Header2Mobile: FunctionComponent<CommonHeaderLayoutProps> = ({
  accommodation,
  actionLinks,
  activeModuleId,
  activePagePath,
  fallbackLanguageId,
  imagesModule: imagesModuleProp,
  sticky,
  isPreview,
  languageId,
  logo,
  logoSize,
  mainPageURL,
  menuItems,
  pageId,
  pages,
  queries,
  scheme,
  whatsAppNumber,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref);
  const imagesModule = imagesModuleProp
    ? overrideImagesAspectRatio(imagesModuleProp)
    : undefined;

  useKeyDown("Escape", () => setIsMenuOpen(false));

  const isBarVisible =
    !isPreview &&
    (scrollDirection === "up" || intersection?.isIntersecting || sticky);

  const quickNav = getActionAndContactLinks(
    accommodation,
    actionLinks,
    languageId,
    whatsAppNumber
  );

  const barNav = [
    ...getBookingEnquiryLinksWithIcon(actionLinks),
    ...getContactLinks(accommodation, languageId),
  ];

  return (
    <div className="HeaderModule__Inner--narrow">
      <header className="Header2Mobile">
        <div ref={ref} className="Header2Mobile__LogoContainer">
          <MobileMainLogo
            accommodationName={accommodation.name}
            isPreview={isPreview}
            logo={logo}
            mainPageURL={mainPageURL}
            isSmall={false}
            size={logoSize}
          />
        </div>

        {imagesModule?.pageId && (
          <ImagesModule
            translatedModule={imagesModule}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={activeModuleId}
            fullHeight={true}
          />
        )}

        <div
          className={ClassNames(
            "Header2Mobile__Bar",
            {
              "Header2Mobile__Bar--visible": isBarVisible,
            },
            style({
              background: scheme.main.background,
              color: scheme.main.text,
            })
          )}
        >
          <div className="Header2Mobile__MenuButtonContainer">
            <HamburgerButton
              className="Header2Mobile__MenuButton"
              isMenuOpen={isMenuOpen}
              onClick={() => setIsMenuOpen((v) => !v)}
            />
          </div>
          <ul className="Header2Mobile__BarNav">
            {barNav.map(({ href, icon, type }) => (
              <li
                key={type}
                className={ClassNames("Header2Mobile__BarNavItem", {
                  "Header2Mobile__BarNavItem--email": type === "email",
                })}
              >
                <SmartLink
                  isPreview={isPreview}
                  anchorAttrs={{
                    href,
                    className: "Header2Mobile__BarNavLink",
                  }}
                >
                  <Icon glyph={icon} className="Header2Mobile__BarNavIcon" />
                </SmartLink>
              </li>
            ))}
          </ul>
        </div>

        <CSSTransition
          in={isMenuOpen}
          timeout={2000}
          mountOnEnter={true}
          unmountOnExit={true}
          addEndListener={onTransitionEnd}
          classNames={{
            enterActive: "Header2Mobile__Menu--open",
            enterDone: "Header2Mobile__Menu--open",
          }}
        >
          <div
            className={ClassNames(
              "Header2Mobile__Menu",
              style({
                background: scheme.main.background,
                color: scheme.main.text,
              })
            )}
          >
            <RemoveScroll
              enabled={!isPreview}
              className="Header2Mobile__MenuInner"
            >
              <HamburgerButton
                className="Header2Mobile__Close"
                isMenuOpen={true}
                onClick={() => setIsMenuOpen((v) => !v)}
              />

              <MobileLanguageMenu
                className="Header2Mobile__LanguageMenu"
                isPreview={isPreview}
                languageId={languageId}
                pageId={pageId}
                onClick={() => setIsMenuOpen(false)}
              />

              <MainMenuListCollapsible
                isPreview={isPreview}
                languageId={languageId}
                onMenuItemClick={() => setIsMenuOpen(false)}
                menuItems={menuItems}
                pages={pages}
                activePagePath={activePagePath}
                fallbackLanguageId={fallbackLanguageId}
                menuLevel={0}
                collapseLevel={0}
                classNames={verticalMenuClassNames}
              />

              <ul className="Header2Mobile__QuickNav">
                {quickNav.map(({ href, icon, type }) => (
                  <li key={type} className="Header2Mobile__QuickNavItem">
                    <SmartLink
                      isPreview={isPreview}
                      anchorAttrs={{
                        href,
                        className: "Header2Mobile__QuickNavLink",
                        onClick: () =>
                          type !== "email" &&
                          type !== "phone" &&
                          setIsMenuOpen(false),
                      }}
                    >
                      <Icon
                        glyph={icon}
                        className="Header2Mobile__QuickNavIcon"
                      />
                    </SmartLink>
                  </li>
                ))}
              </ul>
            </RemoveScroll>
          </div>
        </CSSTransition>
      </header>
    </div>
  );
};

export default Header2Mobile;
