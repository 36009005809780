// Mock node’s Buffer for browsers (required for the deep-extend package)
if (typeof window !== "undefined") {
  class Buffer {}
  (<{ Buffer: typeof Buffer }>(<unknown>window)).Buffer = Buffer;
}

import { DataRef } from "@dnd-kit/core";
import { ResizeObserverEntry } from "@juggle/resize-observer";
import axios, { AxiosError } from "axios";
import { MutableRefObject } from "react";
import slugify from "slugify";
import { style } from "typestyle";
import isEmail from "validator/lib/isEmail.js";
import isEmpty from "validator/lib/isEmpty.js";
import isURL from "validator/lib/isURL.js";
import {
  APISite,
  ColorScheme,
  ColorSchemesAPI,
  SiteLanguage,
} from "../../server/types/index.js";
import { setModuleSetting } from "../actions/Modules.js";
import { logoutUser } from "../actions/User.js";
import i18n from "../i18n/index.js";
import { defaultSettings } from "../reducers/modules/byId.js";
import { getPageModuleIds } from "../selectors/modules.js";
import {
  Accommodation,
  ActionLink,
  ActionLinkType,
  ActionLinks,
  ActionModule,
  ActiveActionModuleIds,
  AnchorAttrs,
  AspectRatio,
  AuthenticatedUser,
  ButtonModuleSettings,
  DisabledModuleColors,
  DragDropTypes,
  EnquiryBookingLinkType,
  ExternalLinkParams,
  FormModuleSettings,
  GetPagePathParams,
  GetPageUrlParams,
  HeaderLayoutVariant,
  HeaderModuleSettings,
  HrefLangAttributes,
  IconName,
  ImageModuleIdsByPictureId,
  ImageModuleSettings,
  ImagesModuleSettings,
  ItemWithTranslations,
  Language,
  LanguageMenuItem,
  LegalNavId,
  LinkCaption,
  LinkData,
  LinkParams,
  MainMenuItem,
  Module,
  ModuleLinkParams,
  ModuleSettingsTypes,
  ModuleType,
  ModuleTypeMapping,
  ModuleWithRichTextSettingsCombined,
  ModuleWithRichTextSettingsDraftOnly,
  Modules,
  ModulesByParentId,
  NavLinkWithIcon,
  OfferingsLayout,
  OverlayModuleSettings,
  Page,
  PageLimits,
  PageLinkParams,
  PageState,
  PageTranslationPostRequest,
  PageTranslationType,
  PagesByChildId,
  PagesByParentId,
  PagesLimit,
  PartialModuleSettings,
  Process,
  QuickLink,
  QuickLinksSettings,
  SelectOption,
  TextAlignment,
  ThreeSizes,
  ThunkDispatch,
  TopHeaderVariant,
  TranslatedModule,
  TranslatedPage,
  User,
  VerticalAlignment,
  WeatherWebcamLayoutVariant,
  WeatherWebcamModuleSettings,
  WebcamModuleSettings,
} from "../types/index.js";

declare const process: Process;

export const getUserFromStorage = (): User => {
  try {
    const userPayload = window.localStorage.getItem("user");
    if (!userPayload) {
      throw Error();
    }
    return JSON.parse(userPayload);
  } catch {
    return {};
  }
};

export const apiBaseURL = "/api/1";

export const fetch = ({
  dispatch,
  languageId,
  authorize = true,
}: {
  dispatch: ThunkDispatch | undefined;
  languageId?: Language;
  authorize?: boolean;
}) => {
  const { token } = getUserFromStorage();

  const headers = {
    ...(token && authorize
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {}),
    ...(languageId ? { "Accept-Language": languageId } : {}),
  };

  const instance = axios.create({
    baseURL: `${apiBaseURL}/`,
    headers,
  });

  instance.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        dispatch?.(logoutUser());
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const injectLink = (
  callback: (link: HTMLLinkElement) => HTMLLinkElement
): HTMLLinkElement => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  document.head.appendChild(callback(link));
  return link;
};

export const getModulesByAction = ({
  modules,
  actionModuleType,
  languageId,
}: {
  modules: Modules;
  actionModuleType: EnquiryBookingLinkType;
  languageId: Language;
}): ActionModule[] => {
  const actionModules = modules.actionModules[languageId] || [];
  return actionModules.filter((actionModule) =>
    actionModuleType === "enquiry"
      ? actionModule.type !== "BookingModule" &&
        actionModule.type !== "PortalModule"
      : actionModule.type !== "EnquiryModule"
  );
};

export const getSubModulesIds = ({
  byParentId,
  parentId,
  pageId,
  moduleType,
}: {
  byParentId: ModulesByParentId;
  parentId: string;
  pageId: string | null;
  moduleType: ModuleType | undefined;
}): string[] => {
  const byPageId = byParentId[String(pageId)] || {};
  const categories = byPageId[parentId];
  if (!categories) return [];
  if (moduleType) return categories[moduleType] || [];
  return keys(categories).reduce<string[]>(
    (ids, key) => [...ids, ...(categories[key] || [])],
    []
  );
};

export const getModulesByParentId = <T extends ModuleSettingsTypes>(
  modules: Modules,
  parentId: string,
  pageId: string | null,
  moduleType?: ModuleType
): Module<T>[] => {
  const moduleIds = getSubModulesIds({
    byParentId: modules.byParentId,
    parentId,
    pageId,
    moduleType,
  });

  const childModules = moduleIds
    .map((moduleId) => modules.byId[moduleId] as Module<T>)
    .filter(Boolean);

  return childModules;
};

export const getTranslatedModulesByParentId = <T extends ModuleSettingsTypes>({
  modules,
  pageId,
  parentId,
  moduleType,
  languageId,
}: {
  modules: Modules;
  parentId: string;
  pageId: string | null;
  moduleType?: ModuleType;
  languageId: Language;
}) =>
  getModulesByParentId<T>(modules, parentId, pageId, moduleType)
    .map((module) => getTranslatedModule(module, languageId))
    .filter((module) => module.translation.exists);

export const getModuleTypeTranslation = (moduleType: ModuleType) => {
  const translationsMap: ModuleTypeMapping = {
    HeaderModule: "Header",
    FooterModule: "Footer",
    ButtonModule: "Button",
    BookingModule: "Buchungs\u00ADwidget",
    PortalModule: "Portal\u00ADwidget",
    EnquiryModule: "Anfrage\u00ADformular",
    QuickEnquiryModule: "Schnellanfrage\u00ADformular",
    HighlightModule: "Highlight",
    HighlightsModule: "Highlights",
    ImageModule: "Bild",
    ImagesModule: "Bilder",
    OverlayModule: "Text-im-Bild",
    ImageGalleryModule: "Bildergalerie",
    ImprintModule: "Impressum",
    MapModule: "Karte",
    PrivacyModule: "Privacy",
    RoomsModule: "Zimmer",
    SpecialsModule: "Specials",
    TermsModule: "Allgemeine Geschäftsbedingungen",
    TextModule: "Text",
    PriceModule: "Preise",
    VideoModule: "Video",
    EasiPayModule: "easiPay",
    EasiFastCheckInModule: "easiFastCheckIn",
    HTMLModule: "HTML",
    SeparatorModule: "Trennmodul",
    NotFoundTextModule: "Seite nicht gefunden",
    MTSToursModule: "MTS Touren Widget",
    WeatherWebcamModule: "Wetter & Webcam",
    WeatherModule: "Wetter",
    WebcamModule: "Webcam",
    GastroPoolInsuranceModule: "gastro-pool Reiserücktritts-versicherung",
    Google360VirtualTourModule: "Google 360°-Ansichten",
    EmbeddedGoogleMapsModule: "Google Maps",
    PopUpModule: "Pop-up",
    NewsletterModule: "Newsletter",
    PeerTvModule: "Peer.tv",
    ACSmartVoucherModule: "ac-smart.voucher",
    QuestionAndAnswerModule: "Frage und Antwort",
    QuestionsAndAnswersModule: "Fragen und Antworten",
    GuestnetWidgetModule: "Guestnet Widget",
    HogastGastropoolPayModule: "hogast/gastropool Zahlknopf",
    HogastGastropoolSmartPayModule: "hogast/gastropool Smart Pay",
    QuoteModule: "Zitat",
    AccommodationFeaturesModule: "Ausstattungs\u00ADmerkmale",
    ArriveInSouthTyrolModule: "Widget „Anreise Announcements“",
    GetavoModule: "Gutscheinsystem Getavo",
  };

  return translationsMap[moduleType];
};

export const getModulePreviewText = (module: Module, languageId: Language) => {
  const moduleType = getModuleTypeTranslation(module.type);
  const translatedModule = getTranslatedModule(module, languageId);

  const {
    translation: { settings },
  } = <
    TranslatedModule<{
      global: {};
      language: {
        title?: string;
        subtitle?: string;
      };
    }>
  >translatedModule;

  return settings.title || settings.subtitle || moduleType;
};

export const getModuleIcon = (moduleType: ModuleType) => {
  const moduleIconMap: { [moduleType in ModuleType]: IconName } = {
    HeaderModule: "text",
    FooterModule: "text",
    ButtonModule: "text",
    BookingModule: "booking",
    PortalModule: "portal",
    EnquiryModule: "enquiry",
    QuickEnquiryModule: "quick-enquiry",
    HighlightModule: "highlight",
    HighlightsModule: "highlight",
    ImagesModule: "image",
    ImageModule: "image",
    OverlayModule: "text",
    ImageGalleryModule: "image-gallery",
    ImprintModule: "text",
    MapModule: "map",
    PrivacyModule: "text",
    RoomsModule: "room",
    SpecialsModule: "specials",
    TermsModule: "text",
    TextModule: "text",
    PriceModule: "price",
    VideoModule: "video",
    EasiPayModule: "easipay",
    EasiFastCheckInModule: "easi-fast-check-in",
    HTMLModule: "code",
    SeparatorModule: "separator-lines",
    NotFoundTextModule: "text",
    MTSToursModule: "mts-logo",
    WeatherWebcamModule: "weather",
    WeatherModule: "weather",
    WebcamModule: "videocam",
    GastroPoolInsuranceModule: "erv-logo",
    Google360VirtualTourModule: "view-360",
    EmbeddedGoogleMapsModule: "map",
    PopUpModule: "pop-up",
    NewsletterModule: "email-outline",
    PeerTvModule: "peer-tv",
    ACSmartVoucherModule: "vouchers",
    QuestionAndAnswerModule: "question-answer",
    QuestionsAndAnswersModule: "question-answer",
    GuestnetWidgetModule: "guestnet",
    HogastGastropoolPayModule: "price",
    HogastGastropoolSmartPayModule: "hogast",
    QuoteModule: "quote",
    AccommodationFeaturesModule: "check",
    ArriveInSouthTyrolModule: "noi-techpark",
    GetavoModule: "getavo",
  };

  return moduleIconMap[moduleType];
};

export const getURL = (...slugs: (string | undefined)[]) =>
  (slugs[0]?.startsWith("/") ? "" : "/") + slugs.filter(Boolean).join("/");

export const moveArrayElement = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  const arrayCopy = [...array];
  const target = array[fromIndex];

  if (target === undefined) {
    throw RangeError(`Index ${toIndex} is out of range`);
  }

  arrayCopy.splice(fromIndex, 1);
  arrayCopy.splice(toIndex, 0, target);

  return arrayCopy;
};

const removeFromArrayByIndex = <T>(array: T[], index: number) => {
  // Remove the element from the array without mutating
  // the original array
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const removeFromArray = <T>(array: T[], el: T) => {
  const index = array.indexOf(el);
  if (index !== -1) {
    return removeFromArrayByIndex<T>(array, index);
  }

  return array;
};

export const getActiveColorScheme = (
  { schemes, defaultColorScheme }: ColorSchemesAPI,
  { colorSchemeId }: APISite,
  customScheme?: { colorSchemeId: string | null }
) => {
  if (
    customScheme &&
    customScheme.colorSchemeId &&
    schemes[customScheme.colorSchemeId]
  ) {
    return schemes[customScheme.colorSchemeId];
  }

  if (colorSchemeId && schemes[colorSchemeId]) {
    return schemes[colorSchemeId];
  }

  return defaultColorScheme;
};

export const isAuthenticated = (user: User): user is AuthenticatedUser =>
  user.token !== undefined;

export const customSlugify = (input: string) =>
  slugify(input, {
    // This regex is taken from the slugify library with these modifications:
    // Remove characters that are not allowed in Windows file/folder names
    // and not already handled by the slugify library
    remove: /[^\w\s$_+~.()'!\-@]/g,
    lower: true,
  });

export const normalizeLink = (link: string | null): string | null => {
  if (!link) return null;

  if (isEmail(link) || checkIsPhoneNumber(link)) {
    return link;
  }

  // If the link already has the protocol specified
  // or if it’s an anchor link, return it as is.
  if (/^https?:\/\//i.test(link) || link.startsWith("#")) {
    return link;
  }

  // Prepend with http:// (the standard protocol)
  return "http://" + link;
};

/**
 * Format a date to the German date format, e.g. “30.6.2020”
 */
export const formatNumericDate = (date: Date | number | string) =>
  new Date(date).toLocaleDateString("de-DE");

/**
 * Format a date to the German date format with time, e.g. “30.6.2020, 08:53:28”
 */
export const formatDateTime = (date: Date | number | string) =>
  new Date(date).toLocaleString("de-DE");

export const parseISO = (input: string) => new Date(Date.parse(input));

export const getPagePath = ({
  pageId,
  languageId,
  pages,
  fallbackLanguageId,
}: GetPagePathParams): string => {
  const page = getTranslatedPageWithFallback({
    pageId,
    languageId,
    fallbackLanguageId,
    fallbackToFirst: true,
    pages,
  });
  const {
    translation: { slug },
    parentId,
  } = page;
  const childPages = pages.byParentId[String(parentId)];
  if (!childPages) throw new PageNotFoundError(String(parentId));

  const index = childPages.indexOf(page.id);

  const finalSlug = parentId
    ? getURL(
        getPagePath({
          pageId: parentId,
          languageId,
          pages,
          fallbackLanguageId,
        }),
        slug
      )
    : // Main page has no slug
    index === 0
    ? getURL(languageId)
    : getURL(languageId, slug);

  return finalSlug;
};

const getPageUrl = ({
  pages,
  pageId,
  languageId,
  fallbackLanguageId,
  isPreview,
}: GetPageUrlParams): string | undefined => {
  const { isEnabled, translation, siteId } = getTranslatedPage(
    pages,
    pageId,
    languageId,
    true
  );

  if (!isEnabled && !isPreview) {
    return undefined;
  }

  return isPreview
    ? getURL(siteId, "pages", pageId, translation.languageId)
    : getPagePath({
        pageId,
        languageId,
        pages,
        fallbackLanguageId,
      });
};

/**
 * If the language of a translatable object exists, return it,
 * otherwise get the fallback language
 */
export const getTranslationLanguage = (
  { translations }: ItemWithTranslations,
  languageId: Language
): Language => {
  if (languageId in translations) return languageId;
  return getTranslationFallbackLanguage({ translations }, languageId);
};

/**
 * Get the first language of a translatable item (page/module)
 */
export const getTranslationFallbackLanguage = (
  { translations }: ItemWithTranslations,
  languageId: Language
): Language => {
  const languages = keys(translations).filter((lang) => lang !== languageId);
  if (!languages[0]) {
    throw Error("The site cannot have 0 languages");
  }
  return languages[0];
};

class GetTranslatedPageError extends Error {
  constructor(...params: ConstructorParameters<typeof Error>) {
    super(...params);
    this.name = "GetTranslatedPageError";
  }
}

export const isGetTranslatedPageError = (
  error: unknown
): error is GetTranslatedPageError =>
  (error as Error).name === "GetTranslatedPageError";

/**
 * Get a translated page
 * @throws GetTranslatedPageError
 */
export const getTranslatedPage = (
  pages: PageState,
  pageId: string,
  languageId: Language,
  languageFallback = false
): TranslatedPage => {
  const page = pages.byId[pageId];

  if (!page) {
    throw new GetTranslatedPageError(`Page "${pageId}" could not be found!`);
  }

  if (languageFallback) {
    languageId = getTranslationLanguage(page, languageId);
  }

  const translation = page.translations[languageId];

  if (!translation) {
    throw new GetTranslatedPageError(
      `Translation "${languageId}" for page "${pageId}" could not be found!`
    );
  }

  const { translations, ...rest } = page;
  return {
    ...rest,
    translation,
    languages: keys(page.translations),
  };
};

/**
 * Get a module with the translated settings in one language.
 * If the translation does not exist, fallback to default settings
 */
export const getTranslatedModule = <Settings extends ModuleSettingsTypes>(
  module: Module<Settings>,
  languageId: Language,
  fallbackLanguageId?: Language
): TranslatedModule<Settings> => {
  const { translations, type, settings, ...rest } = module;
  // If the translation doesn’t exist in the desired language,
  // check for the fallback language
  const fallbackApplied =
    !(languageId in translations) &&
    (fallbackLanguageId ? fallbackLanguageId in translations : false);

  const translation =
    translations[languageId] ||
    (fallbackLanguageId ? translations[fallbackLanguageId] : undefined);

  const translationSettings = translation
    ? translation.settings
    : defaultSettings[type].language;

  const translatedModule: TranslatedModule<Settings> = {
    ...rest,
    type,
    translation: {
      languageId:
        fallbackApplied && fallbackLanguageId ? fallbackLanguageId : languageId,
      exists: !!translation,
      settings: translationSettings,
    },
    settings: module.settings,
    languages: keys(translations),
  };

  return translatedModule;
};

/**
 * Get a translated page for a specific language.
 * If not found, attempt to get the page’s fallback language.
 * @throws Throw a `GetTranslatedPageError` if the page is
 * not translated in the requested language or in the fallback language.
 */
export const getTranslatedPageWithFallback = ({
  pages,
  pageId,
  languageId,
  fallbackLanguageId,
  fallbackToFirst = false,
}: {
  pages: PageState;
  pageId: string;
  languageId: Language;
  fallbackLanguageId: Language | undefined;
  fallbackToFirst?: boolean;
}): TranslatedPage => {
  try {
    return getTranslatedPage(pages, pageId, languageId);
  } catch (e) {
    if (
      (!fallbackLanguageId || !isGetTranslatedPageError(e)) &&
      !fallbackToFirst
    ) {
      throw e;
    }
    return getTranslatedPage(
      pages,
      pageId,
      fallbackLanguageId || languageId,
      fallbackToFirst
    );
  }
};

export const getModuleUrl = ({
  isPreview,
  languageId,
  moduleId,
  pageId,
  pages,
  fallbackLanguageId,
}: ModuleLinkParams): string | undefined => {
  const pageLink = getPageUrl({
    pages,
    pageId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
  if (!pageLink) return;

  return isPreview
    ? getURL(pageLink, "modules", moduleId)
    : `${pageLink}#${moduleId}`;
};

export const isPageLink = (
  link: LinkParams | LinkData
): link is PageLinkParams => {
  const { pageId, moduleId } = link;
  return pageId != null && moduleId == null;
};

export const isModuleLink = (
  link: LinkParams | LinkData
): link is ModuleLinkParams => {
  const { pageId, moduleId } = link;
  return pageId != null && moduleId != null;
};

export const isExternalLink = (
  link: LinkParams | LinkData
): link is ExternalLinkParams => link.url != null;

const isEmailLink = (link: LinkParams | LinkData): link is ExternalLinkParams =>
  !!link.url && isEmail(link.url);

const isFolder = (link: LinkParams | LinkData) => {
  const { url, pageId, moduleId } = link;
  return pageId == null && moduleId == null && url == null;
};

export const checkIsPhoneNumber = (input: string) => /^[0-9\s\+]+$/.test(input);
const removeWhitespace = (input: string) => input.replace(/\s/g, "");

export const resolveLink = (link: LinkParams): AnchorAttrs => {
  if (isPageLink(link)) {
    const { pages, pageId, isPreview, fallbackLanguageId, languageId } = link;

    try {
      const {
        translation: { title, link: pageLinkData },
      } = getTranslatedPageWithFallback({
        pages,
        pageId,
        languageId,
        fallbackLanguageId,
      });

      // If the target page is a link itself (but not a folder),
      // recursively resolve the link
      if (pageLinkData && !isFolder(pageLinkData)) {
        const targetLanguageId: Language =
          pageLinkData.languageId === fallbackLanguageId
            ? languageId
            : pageLinkData.languageId || languageId;

        return resolveLink({
          isPreview: link.isPreview,
          languageId: targetLanguageId,
          moduleId: pageLinkData.moduleId,
          pageId: pageLinkData.pageId,
          pages: link.pages,
          url: pageLinkData.url,
          title,
          fallbackLanguageId,
        });
      }

      const href = getPageUrl({
        pages,
        pageId,
        languageId,
        isPreview,
        fallbackLanguageId,
      });

      return {
        children: link.title || title,
        href,
      };
    } catch {
      return {
        href: "#",
      };
    }
  }

  if (isModuleLink(link)) {
    const {
      pages,
      pageId,
      moduleId,
      isPreview,
      fallbackLanguageId,
      languageId,
    } = link;

    try {
      const {
        translation: { title, languageId: targetLanguageId },
        // TODO: add recursive link. What happens if the link is circular
      } = getTranslatedPageWithFallback({
        pages,
        pageId,
        languageId,
        fallbackLanguageId,
      });

      return {
        children: title,
        href: getModuleUrl({
          isPreview,
          languageId: targetLanguageId,
          moduleId,
          pageId,
          pages,
          fallbackLanguageId,
        }),
      };
    } catch {
      return {
        href: "#",
      };
    }
  }

  if (link.url && checkIsPhoneNumber(link.url)) {
    return {
      children: link.url,
      href: `tel:${removeWhitespace(link.url)}`,
    };
  }

  if (isEmailLink(link)) {
    const { url } = link;
    return {
      children: url,
      href: `mailto:${url}`,
    };
  }

  if (isExternalLink(link)) {
    return link.url.startsWith("#")
      ? {
          children: link.url,
          href: link.url,
        }
      : {
          children: link.url,
          href: link.url,
          target: "_blank",
          rel: "noopener",
        };
  }

  return {};
};

/**
 * Scroll to the provided DOM element. This function
 * polls the elemen for 5 seconds in case the DOM
 * changes (e.g. AJAX content) and re-executes
 * the scrollIntoView function if necessary.
 */
export const scrollToEl = (
  el: HTMLElement,
  options: ScrollIntoViewOptions = {},
  {
    attemptNumber,
    lastHeight,
    lastOffsetTop,
  }: {
    lastOffsetTop: undefined | number;
    lastHeight: undefined | number;
    attemptNumber: number;
  } = {
    lastOffsetTop: undefined,
    lastHeight: undefined,
    attemptNumber: 1,
  }
) => {
  if (attemptNumber > 10) {
    return;
  }

  const { offsetParent } = el;
  if (!(offsetParent instanceof HTMLElement)) return;

  const offsetTop = offsetParent ? offsetParent.offsetTop : 0;
  const { offsetHeight } = el;

  const executeScroll =
    lastOffsetTop === undefined ||
    (lastOffsetTop !== undefined && offsetTop !== lastOffsetTop) ||
    (lastHeight !== undefined && lastHeight !== offsetHeight);

  executeScroll && scrollToElSingle(el, options);

  setTimeout(() => {
    scrollToEl(el, options, {
      lastOffsetTop: offsetTop,
      attemptNumber: attemptNumber + 1,
      lastHeight: offsetHeight,
    });
  }, 500);
};

const supportsSmoothScroll = (): boolean => {
  if (!document.documentElement) {
    return false;
  }

  return "scrollBehavior" in document.documentElement.style;
};

export const scrollToElSingle = (
  el: HTMLElement,
  options: ScrollIntoViewOptions = {}
) => {
  const defaultOptions: ScrollIntoViewOptions = {
    behavior: "smooth",
    block: "nearest",
    inline: "nearest",
  };
  const mergedOptions = { ...defaultOptions, ...options };

  el.scrollIntoView(supportsSmoothScroll() ? mergedOptions : true);
};

export const checkIsOnePager = (site: APISite) => site.pagesLimit === 1;

type TranslationsAll = {
  [K in Language]?: {
    [key: string]: any;
  };
};

export const getTranslations = <T extends TranslationsAll>(
  languageId: Language,
  translations: T
): T["en"] => {
  const current = translations[languageId];
  if (!current) return translations.en;
  return current;
};

export const linkSelectHasLink = ({
  moduleId,
  pageId,
  url,
}: LinkData): boolean => !!(pageId || moduleId || url);

/**
 * Check if a DOM Element is currently in the viewport, at least partially.
 */
export const isElementPartiallyInViewport = (
  el: Element,
  yOffset = 0
): boolean => {
  if (!document.documentElement) return false;

  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const vertInView =
    rect.top <= windowHeight && rect.top + rect.height >= yOffset;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
};

export const isItemUntranslated = ({ translations }: ItemWithTranslations) =>
  Object.keys(translations).length === 1;

export const getMenuItems = ({
  pages,
  pageId,
  languageId,
  isPreview,
  fallbackLanguageId,
}: {
  pages: PageState;
  pageId: string;
  languageId: Language;
  isPreview: boolean;
  fallbackLanguageId: Language | undefined;
}): MainMenuItem[] => {
  const subPageIds = pages.byParentId[pageId] || [];

  const subPages = subPageIds.reduce<MainMenuItem[]>(
    (accumulator, pageId: string) => {
      try {
        const {
          translation: {
            title,
            isVisible,
            link,
            languageId: translationLanguageId,
          },
          isEnabled,
        } = getTranslatedPageWithFallback({
          pages,
          pageId,
          languageId,
          fallbackLanguageId,
        });

        if (!isVisible || !isEnabled) {
          return accumulator;
        }

        const resolvedLink = resolveLink({
          pageId,
          pages,
          languageId,
          fallbackLanguageId: translationLanguageId,
          isPreview,
        });

        const item: MainMenuItem = {
          id: pageId,
          isFolder: link ? isFolder(link) : false,
          title,
          // TODO: is it OK to pass an empty string?
          href: resolvedLink.href || "",
          target: resolvedLink.target,
        };

        return [...accumulator, item];
      } catch (e) {
        if (isGetTranslatedPageError(e)) {
          return accumulator;
        }

        throw e;
      }
    },
    []
  );

  return subPages;
};

export const getTopLevelMainMenu = ({
  pages,
  languageId,
  isPreview,
  fallbackLanguageId,
}: {
  pages: PageState;
  languageId: Language;
  isPreview: boolean;
  fallbackLanguageId: Language | undefined;
}): MainMenuItem[] => {
  const topLevelPages = pages.byParentId["null"];

  const hasSingleTopLevelPage =
    topLevelPages.reduce<number>((accumulator, pageId: string) => {
      const page = getTranslatedPage(pages, pageId, languageId, true);

      if (page.translation.isVisible) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0) === 1;

  // If there’s only one top level page, leave it off the main menu
  const parentId =
    hasSingleTopLevelPage && topLevelPages[0] ? topLevelPages[0] : "null";
  return getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
};

export const getMainPageURL = (
  pages: PageState,
  languageId: Language,
  isPreview: boolean
): string | undefined => {
  const pageId = pages.byParentId["null"][0];
  if (!pageId) throw new HomePageNotFoundError(pageId);
  return resolveLink({
    isPreview,
    pages,
    languageId,
    pageId,
    fallbackLanguageId: undefined,
  }).href;
};

const getActivePages = (
  activePageId: string,
  byChildId: PagesByChildId,
  activePagesAccumulator = [activePageId]
): string[] => {
  const parentPage: string | undefined = byChildId[activePageId];

  if (!parentPage || parentPage === "null") {
    return activePagesAccumulator;
  }

  return getActivePages(parentPage, byChildId, [
    ...activePagesAccumulator,
    parentPage,
  ]);
};

export const getActivePagePath = (
  activePageId: string,
  byParentId: PagesByParentId
): string[] => {
  const byChildId = Object.keys(byParentId).reduce<PagesByChildId>(
    (accumulator, parentId) => {
      const subpages = byParentId[parentId]?.reduce<PagesByChildId>(
        (innerAccumulator, subpageId) => {
          return {
            ...innerAccumulator,
            [subpageId]: parentId,
          };
        },
        {}
      );

      return {
        ...accumulator,
        ...subpages,
      };
    },
    {}
  );

  return getActivePages(activePageId, byChildId);
};

export const getDisabledPagesLimitNumber = (pagesLimit: PagesLimit) => {
  if (pagesLimit === 1) return 1;
  if (pagesLimit <= 10) return 5;
  if (pagesLimit <= 20) return 10;
  return 20;
};

/**
 * Get the number of pages, but only for the type
 * "page", not "redirect" or "folder"
 */
const getPagesCount = (pages: PageState): { enabled: number; all: number } =>
  keys(pages.byId).reduce<{
    enabled: number;
    all: number;
  }>(
    (accumulator, pageId) => {
      const page = pages.byId[pageId];
      if (!page) return accumulator;

      const [firstLanguageId] = keys(page.translations);

      // If there are no translations (shouldn’t normally be the case)
      if (!firstLanguageId) return accumulator;

      const isLink = !!page.translations[firstLanguageId]?.link;
      const pageCounts = !page.isSystemPage && !isLink;
      return {
        all: pageCounts ? accumulator.all + 1 : accumulator.all,
        enabled:
          pageCounts && page.isEnabled
            ? accumulator.enabled + 1
            : accumulator.enabled,
      };
    },
    { enabled: 0, all: 0 }
  );

export const getPageLimits = (
  pages: PageState,
  pagesLimit: PagesLimit
): PageLimits => {
  const pagesCount = getPagesCount(pages);
  const disabledPagesLimit = getDisabledPagesLimitNumber(pagesLimit);

  return {
    enabled: {
      limitReached: pagesCount.enabled >= pagesLimit,
      count: pagesCount.enabled,
    },
    all: {
      limitReached: pagesCount.all >= pagesLimit + disabledPagesLimit,
      count: pagesCount.all,
    },
  };
};

export const getPageTranslationType = (
  link: LinkData | null | undefined
): PageTranslationType => {
  if (!link) {
    return "page";
  }
  const { moduleId, pageId, url } = link;
  if (!moduleId && !pageId && !url) {
    return "folder";
  }

  return "redirect";
};

export const getNextModuleId = (
  moduleId: string,
  modules: Modules
): string | undefined => {
  const module = modules.byId[moduleId];
  if (!module) throw new ModuleNotFoundError(moduleId);
  const { pageId, parentId, type } = module;

  // Get the sibling module ids either from by byParentId or from byPageId
  const siblingModuleIds = parentId
    ? getSubModulesIds({
        byParentId: modules.byParentId,
        parentId,
        pageId,
        moduleType: type,
      })
    : getPageModuleIds(modules.byPageId, pageId);

  if (!siblingModuleIds) return undefined;
  const index = siblingModuleIds.indexOf(moduleId);
  if (index === -1) return undefined;
  return siblingModuleIds[index + 1];
};

export const getHrefLangAttributes = ({
  siteLanguages,
  pages,
  pageId,
  fallbackToHome,
  isPreview,
}: {
  siteLanguages: SiteLanguage[];
  pages: PageState;
  pageId: string;
  fallbackToHome: boolean;
  isPreview: boolean;
}): HrefLangAttributes[] => {
  const homePageId = pages.byParentId["null"][0];
  if (!homePageId) throw new HomePageNotFoundError();
  const homePage = homePageId ? pages.byId[homePageId] : undefined;
  if (!homePage) throw new HomePageNotFoundError(homePageId);

  const page = pages.byId[pageId];
  if (!page) throw new PageNotFoundError(pageId);

  const siteLanguageIds = siteLanguages.map(({ id }) => id);

  const currentPageLanguages = siteLanguageIds.filter(
    (languageId) => languageId in page.translations
  );

  const homePageLanguages = siteLanguageIds.filter(
    (languageId) => languageId in homePage.translations
  );

  const availablePageLanguages = fallbackToHome
    ? siteLanguageIds.filter(
        (languageId) =>
          currentPageLanguages.indexOf(languageId) !== -1 ||
          homePageLanguages.indexOf(languageId) !== -1
      )
    : currentPageLanguages;

  // Don’t show if there’s only one language
  if (availablePageLanguages.length <= 1) return [];

  return availablePageLanguages.reduce<HrefLangAttributes[]>(
    (accumulator, languageId) => {
      const { href } = resolveLink({
        pageId:
          fallbackToHome && currentPageLanguages.indexOf(languageId) === -1
            ? homePageId
            : pageId,
        pages,
        languageId,
        isPreview,
        fallbackLanguageId: undefined,
      });

      if (!href) return accumulator;

      const attrs: HrefLangAttributes = {
        hrefLang: languageId,
        rel: "alternate",
        href,
      };

      return [...accumulator, attrs];
    },
    []
  );
};

export const buildBreadcrumb = (
  pages: PageState,
  site: APISite,
  pageId: string,
  languageId: Language,
  isPreview: boolean,
  breadcrumb: AnchorAttrs[] = []
): AnchorAttrs[] => {
  const page = pages.byId[pageId];
  if (!page) return [];

  const homePageId = pages.byParentId["null"][0];
  if (homePageId === undefined) throw new HomePageNotFoundError();

  const link = resolveLink({
    pages,
    pageId: page.id,
    languageId,
    isPreview,
    fallbackLanguageId: getFallbackLanguage(site, languageId),
  });

  breadcrumb.push(link);

  if (page.parentId !== null) {
    return buildBreadcrumb(
      pages,
      site,
      page.parentId,
      languageId,
      isPreview,
      breadcrumb
    );
  }

  if (page.id !== homePageId) {
    return buildBreadcrumb(
      pages,
      site,
      homePageId,
      languageId,
      isPreview,
      breadcrumb
    );
  }

  return breadcrumb.reverse();
};

export const isDevEnvironment = process.env.NODE_ENV === "development";

export const getPublishedSiteURL = (siteId: string) => {
  // CMS_ORIGIN is only defined during development.
  const url = process.env.CMS_ORIGIN
    ? new URL(process.env.CMS_ORIGIN)
    : new URL(`${location.protocol}//${location.host}`);

  url.hostname = `${siteId}.${url.hostname}`;
  url.pathname = "";
  return url;
};

export const identity = (param: unknown) => param;

export const isDefined = <T>(item: T | undefined): item is T =>
  item !== undefined;

export const isLegalNavId = (alias: string | null): alias is LegalNavId => {
  const legalNavIds: { [key in LegalNavId]: true } = {
    imprint: true,
    privacy: true,
    terms: true,
  };
  return alias ? alias in legalNavIds : false;
};

export const youTubeVideoRegex =
  /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:.+?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

/**
 * Get the YouTube video ID from a YouTube URL
 */
export const getYouTubeVideoId = (input: string) => {
  const match = input.match(youTubeVideoRegex);
  if (!match) return undefined;
  return match[1];
};

const timeRegex = /^(\d+)(?::(\d+))?(?::(\d+))?$/;

/**
 * Takes a time string in the format HH:MM:SS, MM:SS or SS
 * and returns the total number of seconds.
 */
export const convertTimeToSeconds = (input: string) => {
  const match = input.match(timeRegex);
  if (!match) return undefined;
  const stringValues = match.slice(1).filter(Boolean);
  const pad = Array.apply<null, number[], undefined[]>(
    null,
    Array(3 - stringValues.length)
  );
  const paddedValues = [...pad, ...stringValues];
  return paddedValues.reduce<number>((sum, value, index) => {
    if (!value) return sum;
    const currentValue = parseInt(value) * 60 ** Math.abs(index - 2);
    return sum + currentValue;
  }, 0);
};

/**
 * Checks if the input is in the format HH:MM:SS, MM:SS or SS
 */
export const isTime = (input: string) => timeRegex.test(input);

export const actionModuleTypes: ModuleType[] = [
  "BookingModule",
  "EnquiryModule",
  "RoomsModule",
  "SpecialsModule",
  "PortalModule",
];

export const isDeletableModule = (type: ModuleType) => {
  const undeletableModules: ModuleType[] = [
    "HeaderModule",
    "FooterModule",
    "ImprintModule",
    "NotFoundTextModule",
    "PrivacyModule",
    "TermsModule",
  ];

  return !undeletableModules.includes(type);
};

export const getNearestHeaderImagesModule = ({
  pages,
  modules,
  currentPageId,
  languageId,
}: {
  pages: PageState;
  modules: Modules;
  currentPageId: string;
  languageId: Language;
}): TranslatedModule<ImagesModuleSettings> | undefined => {
  const page = pages.byId[currentPageId];
  if (!page) throw new PageNotFoundError(currentPageId);

  const { parentId, inheritHeaderImages } = page;
  const headerModuleId = modules.bySiteModuleType.HeaderModule?.[0];
  if (!headerModuleId) return undefined;
  const byPage = modules.byParentId[currentPageId];
  const imagesModuleIds = ((byPage || {})[headerModuleId] || {}).ImagesModule;
  const couldBeInherited =
    (!imagesModuleIds || !imagesModuleIds.length) && inheritHeaderImages;

  if (couldBeInherited && parentId) {
    return getNearestHeaderImagesModule({
      pages,
      modules,
      currentPageId: parentId,
      languageId,
    });
  }

  if (!imagesModuleIds) return undefined;

  const imagesModule = imagesModuleIds
    .map((id) => modules.byId[id])
    .filter(isDefined)
    .find<Module<ImagesModuleSettings>>(
      (module): module is Module<ImagesModuleSettings> =>
        module.type === "ImagesModule"
    );

  if (imagesModule) {
    return getTranslatedModule(imagesModule, languageId);
  }

  return undefined;
};

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const getGalleryMediaAspectRatio = (index: number): number => {
  const aspectRatios = <const>[0.5732, 3.2776];
  const defaultAspectRatio: AspectRatio = 1.3333;

  return aspectRatios[index] ?? defaultAspectRatio;
};

export const getFallbackLanguage = (
  site: APISite,
  languageId: Language
): Language | undefined => {
  const siteLanguage = site.languages.find(({ id }) => id === languageId);
  return siteLanguage && (siteLanguage.fallbackId || undefined);
};

const translateSettingsField = (
  fieldContent: unknown,
  targetLanguageId: Language
) => {
  if (!fieldContent || typeof fieldContent !== "string") {
    return fieldContent;
  }
  return `[${targetLanguageId.toUpperCase()}] ${fieldContent}`;
};

export const translateModuleSettings = (
  settings: ModuleSettingsTypes["language"],
  targetLanguageId: Language
): ModuleSettingsTypes["language"] => {
  const translatableFields = ["title", "subtitle"];

  const translatedSettings = translatableFields.reduce<
    ModuleSettingsTypes["language"]
  >(
    (accumulator, fieldName) => {
      const fieldContent = (<{ [x: string]: unknown }>settings)[fieldName];
      if (!(fieldName in accumulator)) return accumulator;

      return {
        ...accumulator,
        [fieldName]: translateSettingsField(fieldContent, targetLanguageId),
      };
    },
    { ...settings }
  );

  return translatedSettings;
};

export const setCookie = (
  name: string,
  value: string,
  days = 7,
  path = "/"
) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  return (document.cookie = `${name}=${encodeURIComponent(
    value
  )};expires=${expires};path=${path};SameSite=Lax`);
};

export const getHtmlFieldId = (label: string) =>
  `form-field-${label.replace(/\s+/g, "-")}`;

export const isNotEmpty = (value: string) => !isEmpty(value);

export const gtmCheckRegex = /^GTM-[a-zA-Z0-9]{1,20}$/;
/**
 * A primitive regular expression to validate phone numbers in international format
 * (starting with a +, then only spaces and digits are allowed, e.g. +39 0123456789).
 * Proper validation is inherently difficult and requires the use of large libraries,
 * e.g. https://github.com/google/libphonenumber.
 */
export const phoneNumberRegex = /^\+[\d ]{6,25}$/;

export const calculatePagination = (
  keepExisting: boolean,
  currentItems: number,
  itemsPerPage = 10
) => {
  if (!keepExisting) return 1;

  return Math.ceil(currentItems / itemsPerPage + 1);
};

export const textAlignments: TextAlignment[] = [
  {
    value: "left",
    title: "Links",
    iconGlyphs: "format-align-left",
  },
  {
    value: "center",
    title: "Zentriert",
    iconGlyphs: "format-align-center",
  },
  {
    value: "right",
    title: "Rechts",
    iconGlyphs: "format-align-right",
  },
];

export const textAlignJustify: TextAlignment = {
  value: "justify",
  title: "Blocksatz",
  iconGlyphs: "format-align-justify",
};

export const verticalAlignments: VerticalAlignment[] = [
  {
    value: "top",
    title: "Oben",
    iconGlyphs: "vertical-align-top",
  },
  {
    value: "center",
    title: "Mitte",
    iconGlyphs: "vertical-align-center",
  },
  {
    value: "bottom",
    title: "Unten",
    iconGlyphs: "vertical-align-bottom",
  },
];

export const getDefaultPageTranslationSettings = (
  languageId: Language,
  pageType: PageTranslationType
): PageTranslationPostRequest => {
  const link =
    pageType === "page"
      ? null
      : {
          pageId: null,
          languageId: null,
          moduleId: null,
          moduleType: null,
          url: null,
        };

  const pageTranslationRequest: PageTranslationPostRequest = {
    title: "",
    languageId,
    slug: "",
    description: null,
    link,
    isVisible: true,
  };

  return pageTranslationRequest;
};

export const getPlaceholderImage = ({
  siteId,
  pageId,
  languageId,
  parentId,
  index,
}: {
  siteId: string;
  pageId: string;
  languageId: Language;
  parentId: string;
  index: number;
}): TranslatedModule<ImageModuleSettings> => ({
  // The id must be stable across re-renders. Therefore it is not randomly
  // generated here.
  id: parentId + index,
  parentId: null,
  type: "ImageModule",
  siteId,
  pageId,
  colorSchemeId: null,
  settings: defaultSettings.ImageModule.global,
  translation: {
    exists: false,
    languageId,
    settings: defaultSettings.ImageModule.language,
  },
  languages: [languageId],
});

export const getImageModuleIdsByPictureId = ({
  modules,
  moduleId,
  pageId,
}: {
  modules: Modules;
  moduleId: string;
  pageId: string | null;
}): ImageModuleIdsByPictureId =>
  getModulesByParentId<ImageModuleSettings>(
    modules,
    moduleId,
    pageId,
    "ImageModule"
  ).reduce<ImageModuleIdsByPictureId>(
    (carry, { settings: { pictureId }, id }) => ({
      ...carry,
      [pictureId]: id,
    }),
    {}
  );

const getOverlayModule = ({
  modules,
  parentId,
  pageId,
  languageId,
}: {
  modules: Modules;
  parentId: string;
  pageId: string;
  languageId: Language;
}): TranslatedModule<OverlayModuleSettings> | undefined => {
  const overlayModules = getModulesByParentId<OverlayModuleSettings>(
    modules,
    parentId,
    pageId,
    "OverlayModule"
  ).map((module) => getTranslatedModule(module, languageId));

  if (!overlayModules.length) return undefined;

  return overlayModules[0];
};

export const getRecursiveOverlayModule = ({
  modules,
  parentId,
  pageId,
  languageId,
}: {
  modules: Modules;
  parentId: string;
  pageId: string | null;
  languageId: Language;
}): TranslatedModule<OverlayModuleSettings> | undefined => {
  if (!pageId) return undefined;

  // The module could be a dummy image which doesn’t exist in the store
  const parentModule = modules.byId[parentId];
  if (!parentModule) return undefined;

  const overlayModule = getOverlayModule({
    languageId,
    modules,
    pageId,
    parentId,
  });

  if (overlayModule) return overlayModule;
  const grandParentId = parentModule.parentId;
  if (!grandParentId) return undefined;

  return getOverlayModule({
    modules,
    languageId,
    pageId,
    parentId: grandParentId,
  });
};

export const getModulesFromAllPages = (pages: Page[]) =>
  pages.reduce<Module[]>((carry, { modules }) => [...carry, ...modules], []);

export const resolveButtonLink = ({
  pages,
  site,
  translatedModule,
  isPreview,
}: {
  pages: PageState;
  site: APISite;
  translatedModule: TranslatedModule<ButtonModuleSettings>;
  isPreview: boolean;
}): AnchorAttrs => {
  const {
    languageId,
    settings: { linkData },
  } = translatedModule.translation;
  const { moduleId, pageId, url } = linkData;

  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const targetLanguageId: Language =
    linkData.languageId === fallbackLanguageId
      ? languageId
      : linkData.languageId || languageId;

  const anchorAttrs = resolveLink({
    isPreview,
    languageId: targetLanguageId,
    moduleId,
    pageId,
    pages: pages,
    url,
    fallbackLanguageId,
  });

  return anchorAttrs;
};

export const getTranslatedButtonModules = ({
  modules,
  moduleId,
  pageId,
  languageId,
}: {
  modules: Modules;
  moduleId: string;
  pageId: string | null;
  languageId: Language;
}): TranslatedModule<ButtonModuleSettings>[] =>
  getTranslatedModulesByParentId<ButtonModuleSettings>({
    modules,
    parentId: moduleId,
    pageId,
    moduleType: "ButtonModule",
    languageId,
  });

/**
 * Check if the provided link is external (e.g. tel:, mailto:, http:, https:).
 */
export const checkIsExternalLink = (href: string): boolean => {
  const isMailTo = href?.includes("mailto:");
  const isTel = href?.includes("tel:");
  const hasProtocol = isURL(href ?? "", {
    require_protocol: true,
    allow_protocol_relative_urls: true,
  });

  return (isMailTo || isTel || hasProtocol) ?? false;
};

export const filterItemWithTitle = (
  { title }: { title: string | null },
  filterText: string
) => {
  if (!filterText) return true;
  if (!title) return false;

  return title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};

/**
 * Decode a base64 string on both the browser and Node.js
 */
const fromBase64 = (input: string) =>
  typeof atob === "function"
    ? atob(input)
    : Buffer.from(input, "base64").toString();

/**
 * Encode a string to to base64 on both the browser and Node.js
 */
export const toBase64 = (input: string) =>
  typeof btoa === "function"
    ? btoa(input)
    : Buffer.from(input).toString("base64");

/**
 * Encode an url with encodeURIComponent and then to base64
 */
export const urlToBase64 = (input: string) =>
  toBase64(encodeURIComponent(input));

/**
 * Decode an url form base64 and with decodeURIComponent
 */
export const urlFromBase64 = (input: string) =>
  decodeURIComponent(fromBase64(input));

export const isImageModule = (
  module: Module
): module is Module<ImageModuleSettings> => module.type === "ImageModule";

export const disabledModuleColors: DisabledModuleColors = {
  main: {
    background: ["VideoModule", "GetavoModule"],
    text: [],
    title: ["HeaderModule", "FooterModule"],
    separator: [
      "HeaderModule",
      "ImagesModule",
      "ImageGalleryModule",
      "TextModule",
      "BookingModule",
      "PopUpModule",
      "EasiPayModule",
      "EasiFastCheckInModule",
      "HTMLModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "QuoteModule",
      "AccommodationFeaturesModule",
    ],
  },
  primary: {
    background: [
      "VideoModule",
      "EasiPayModule",
      "EasiFastCheckInModule",
      "HTMLModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "GuestnetWidgetModule",
      "QuoteModule",
      "GetavoModule",
    ],
    text: [
      "ImageGalleryModule",
      "VideoModule",
      "EasiPayModule",
      "EasiFastCheckInModule",
      "HTMLModule",
      "FooterModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "GuestnetWidgetModule",
      "QuoteModule",
      "AccommodationFeaturesModule",
      "GetavoModule",
    ],
  },
  secondary: {
    background: [
      "ImageGalleryModule",
      "VideoModule",
      "EasiPayModule",
      "EasiFastCheckInModule",
      "HTMLModule",
      "FooterModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "GuestnetWidgetModule",
      "QuoteModule",
      "AccommodationFeaturesModule",
      "GetavoModule",
    ],
    text: [
      "ImageGalleryModule",
      "VideoModule",
      "EasiPayModule",
      "EasiFastCheckInModule",
      "HTMLModule",
      "FooterModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "GuestnetWidgetModule",
      "QuoteModule",
      "AccommodationFeaturesModule",
      "GetavoModule",
    ],
  },
};

export const getLinkCaption = ({
  languageId,
  pages,
  linkData: { moduleId, pageId, url, moduleType },
}: {
  linkData: LinkData;
  pages: PageState;
  languageId: Language;
}): LinkCaption | undefined => {
  if (moduleId && moduleType) {
    return {
      title: getModuleTypeTranslation(moduleType),
      icon: getModuleIcon(moduleType),
    };
  }

  if (pageId) {
    try {
      const page = getTranslatedPage(pages, pageId, languageId);
      return {
        title: page.translation.title,
        icon: "page",
      };
    } catch {
      return undefined;
    }
  }

  if (url) {
    return { title: url, icon: "link" };
  }

  return undefined;
};

export const getMapDispatchToPropsForModuleSetting =
  <Settings extends ModuleSettingsTypes>() =>
  (
    dispatch: ThunkDispatch,
    { translatedModule }: FormModuleSettings<Settings>
  ) => ({
    setModuleSetting: (settings: PartialModuleSettings<Settings>) =>
      dispatch(setModuleSetting<Settings>(translatedModule, settings)),
  });

export const getTopmostParentModuleId = (
  moduleId: string,
  modules: Modules
): string => {
  const currentModule = <Module | undefined>modules.byId[moduleId];
  return !currentModule || !currentModule.parentId
    ? moduleId
    : getTopmostParentModuleId(currentModule.parentId, modules);
};

export const getPageIcon = ({
  isMainPage,
  isEnabled,
  type,
}: {
  isMainPage: boolean;
  isEnabled: boolean;
  type: PageTranslationType;
}): IconName => {
  if (!isEnabled) {
    const mapping: { [type in PageTranslationType]: IconName } = {
      folder: "folder-disabled",
      page: "page-disabled",
      redirect: "redirect-disabled",
    };

    return mapping[type];
  }

  return isMainPage ? "public" : type;
};

export const getPhoneLink = (
  accommodation: Accommodation,
  lang: Language
): NavLinkWithIcon => ({
  type: "phone",
  icon: "phone",
  children: accommodation.phone ?? "",
  // Remove all non-digit characters except the + sign,
  // see https://datatracker.ietf.org/doc/html/rfc3966#section-5.1.1
  href: `tel:${accommodation.phone?.replace(/[^+\d]/g, "")}`,
  title: getTranslations(lang, i18n).phone,
});

export const getWhatsAppLink = (whatsAppNumber: string): NavLinkWithIcon => ({
  type: "whatsapp",
  icon: "whatsapp",
  children: whatsAppNumber ?? "",
  // Only leave digits in the link.
  href: `https://wa.me/${whatsAppNumber?.replace(/\D/g, "")}`,
  title: "WhatsApp",
});

export const getEmailLink = (
  accommodation: Accommodation,
  lang: Language
): NavLinkWithIcon => ({
  type: "email",
  icon: "email-outline",
  children: accommodation.email,
  href: `mailto:${accommodation.email}`,
  title: getTranslations(lang, i18n).email,
});

export const getContactLinks = (
  accommodation: Accommodation,
  lang: Language,
  whatsAppNumber?: string | undefined
): NavLinkWithIcon[] =>
  [
    getPhoneLink(accommodation, lang),
    ...(whatsAppNumber ? [getWhatsAppLink(whatsAppNumber)] : []),
    getEmailLink(accommodation, lang),
  ].filter((item): item is NavLinkWithIcon => !!item);

export const enquiryBookingLinkTypes = <const>["booking", "enquiry"];

export const quickLinkTypes = <const>["vouchers", "specials"];

export const actionLinkTypes = <const>[
  ...enquiryBookingLinkTypes,
  ...quickLinkTypes,
];

const getActiveActionModule = ({
  modules,
  activeActionModuleIds,
  actionModuleType,
  languageId,
}: {
  modules: Modules;
  activeActionModuleIds: ActiveActionModuleIds;
  actionModuleType: EnquiryBookingLinkType;
  languageId: Language;
}) => {
  const actionModuleIds = (modules.actionModules[languageId] || []).map(
    ({ id }) => id
  );
  const moduleId = activeActionModuleIds[actionModuleType];
  if (!moduleId) return undefined;

  const index = actionModuleIds.indexOf(moduleId);

  if (index === -1) {
    return undefined;
  }

  return (modules.actionModules[languageId] || [])[index];
};

const getActionLinkFromActionModule = ({
  actionModule,
  i18n,
  pages,
  isPreview,
  site,
  languageId,
  actionModuleType,
}: {
  isPreview: boolean;
  site: APISite;
  actionModule: ActionModule;
  languageId: Language;
  actionModuleType: EnquiryBookingLinkType;
  i18n: { [type in EnquiryBookingLinkType]: string };
  pages: PageState;
}): ActionLink | undefined => {
  const { pageId, id } = actionModule;
  if (!(pageId && pages.byId[pageId])) return undefined;

  const href = getModuleUrl({
    pages,
    pageId,
    moduleId: id,
    languageId,
    isPreview,
    fallbackLanguageId: getFallbackLanguage(site, languageId),
  });

  if (!href) return undefined;

  return {
    href,
    label: i18n[actionModuleType],
  };
};

const getQuickActionLink = ({
  link,
  isPreview,
  pages,
  label,
  languageId,
}: {
  link: LinkData;
  isPreview: boolean;
  pages: PageState;
  label: string;
  languageId: Language;
}): ActionLink | undefined => {
  const href = resolveLink({
    fallbackLanguageId: undefined,
    languageId: link.languageId ?? languageId,
    isPreview,
    pages,
    pageId: link.pageId,
    moduleId: link.moduleId,
    url: link.url,
  }).href;

  if (!href) return;

  return { href, label };
};

const getQuickLinks = ({
  pages,
  languageId,
  isPreview,
  links,
}: {
  pages: PageState;
  languageId: Language;
  isPreview: boolean;
  links: QuickLinksSettings;
}) => {
  const i18n = getTranslations(languageId, {
    de: {
      specials: "Specials",
      vouchers: "Gutscheine",
    },
    it: {
      specials: "Specials",
      vouchers: "Buoni",
    },
    en: {
      specials: "Specials",
      vouchers: "Gift vouchers",
    },
    fr: {
      specials: "Specials",
      vouchers: "Bons d’achat",
    },
  });

  return {
    vouchers: getQuickActionLink({
      isPreview,
      label: i18n.vouchers,
      languageId,
      link: links.vouchers,
      pages,
    }),
    specials: getQuickActionLink({
      isPreview,
      label: i18n.specials,
      languageId,
      link: links.specials,
      pages,
    }),
  };
};

const getBookingEnquiryActionLinks = ({
  pages,
  site,
  modules,
  languageId,
  isPreview,
  activeActionModuleIds,
}: {
  pages: PageState;
  site: APISite;
  modules: Modules;
  languageId: Language;
  isPreview: boolean;
  activeActionModuleIds: ActiveActionModuleIds;
}) => {
  const i18n = getTranslations(languageId, {
    de: {
      booking: "Buchen",
      enquiry: "Anfragen",
    },
    it: {
      booking: "Prenota",
      enquiry: "Richiedi",
    },
    en: {
      booking: "Book",
      enquiry: "Enquire",
    },
    fr: {
      booking: "Réservation",
      enquiry: "Demande",
    },
  });

  const [booking, enquiry] = enquiryBookingLinkTypes.map<
    ActionLink | undefined
  >((actionModuleType) => {
    const actionModule =
      getActiveActionModule({
        modules,
        activeActionModuleIds,
        actionModuleType,
        languageId,
      }) ??
      getModulesByAction({
        modules,
        actionModuleType,
        languageId,
      })[0];

    return (
      actionModule &&
      getActionLinkFromActionModule({
        actionModule,
        actionModuleType,
        i18n,
        isPreview,
        languageId,
        pages,
        site,
      })
    );
  });

  return {
    booking,
    enquiry,
  };
};

const buildActionLinks = ({
  pages,
  site,
  modules,
  languageId,
  isPreview,
  activeActionModuleIds,
  quickLinksSettings,
}: {
  pages: PageState;
  site: APISite;
  modules: Modules;
  languageId: Language;
  isPreview: boolean;
  activeActionModuleIds: ActiveActionModuleIds;
  quickLinksSettings: QuickLinksSettings;
}): ActionLinks => {
  const { booking, enquiry } = getBookingEnquiryActionLinks({
    activeActionModuleIds,
    isPreview,
    site,
    pages,
    languageId,
    modules,
  });

  const { specials, vouchers } = getQuickLinks({
    isPreview,
    pages,
    languageId,
    links: quickLinksSettings,
  });

  return { booking, enquiry, specials, vouchers };
};

export const getNavLinkWithIconFromActionLink = (
  link: ActionLink,
  type: ActionLinkType
): NavLinkWithIcon => ({
  type,
  children: link.label,
  href: link.href,
  icon: type,
  title: link.label,
});

export const getActionAndContactLinks = (
  accommodation: Accommodation | undefined,
  actionLinks: ActionLinks,
  lang: Language,
  whatsAppNumber?: string | undefined
): NavLinkWithIcon[] => {
  const contact = accommodation
    ? getContactLinks(accommodation, lang, whatsAppNumber)
    : [];

  const actions = actionLinkTypes
    .map<NavLinkWithIcon | undefined>((type) => {
      const actionLink = actionLinks[type];
      return actionLink && getNavLinkWithIconFromActionLink(actionLink, type);
    })
    .filter(isDefined);

  return [...contact, ...actions];
};

export const getActionLinks = ({
  modules,
  pages,
  languageId,
  site,
  isPreview,
}: {
  modules: Modules;
  pages: PageState;
  languageId: Language;
  site: APISite;
  isPreview: boolean;
}): ActionLinks => {
  const emptyActionLinks: ActionLinks = {
    booking: undefined,
    enquiry: undefined,
    vouchers: undefined,
    specials: undefined,
  };

  const headerModuleId = modules.bySiteModuleType.HeaderModule?.[0];
  if (!headerModuleId) return emptyActionLinks;
  const headerModule = <Module<HeaderModuleSettings>>(
    modules.byId[headerModuleId]
  );
  if (!headerModule) return emptyActionLinks;
  const { activeActionModuleIds, links } =
    getTranslatedModule<HeaderModuleSettings>(headerModule, languageId)
      .translation.settings;

  return buildActionLinks({
    pages,
    site,
    modules,
    languageId,
    isPreview,
    activeActionModuleIds,
    quickLinksSettings: links,
  });
};

export const getImageModuleWithFixedAspectRatio = (
  translatedModule: TranslatedModule<ImagesModuleSettings>
): TranslatedModule<ImagesModuleSettings> => ({
  ...translatedModule,
  // Fixed aspect ratio of 16/9
  settings: { ...translatedModule.settings, mediaAspectRatio: 1.7778 },
});

export const checkIsHomePage = (pages: PageState, pageId: string): boolean =>
  pageId === pages.byParentId?.["null"]?.[0];

export let isTouchDevice = false;

const setIsTouchDevice = () => {
  isTouchDevice = true;
  window.removeEventListener("touchstart", setIsTouchDevice);
};

export const isBrowser = typeof window !== "undefined";

isBrowser && window.addEventListener("touchstart", setIsTouchDevice);

export const checkIsModuleWithDraftState = (
  module: TranslatedModule
): module is TranslatedModule<ModuleWithRichTextSettingsDraftOnly> => {
  const description = (<TranslatedModule<ModuleWithRichTextSettingsCombined>>(
    module
  )).translation.settings?.description;

  return description === undefined || typeof description === "object";
};

/**
 * Check if the language is contained in the provided
 * array.
 * @returns string The provided input languageId or en as fallback
 */
export const getSupportedLanguage = (
  languageId: Language,
  languages: Language[]
): Language =>
  languageId && languages.indexOf(languageId) === -1 ? "en" : languageId;

export const getLinkStyleClass = (scheme: ColorScheme) =>
  style({
    $nest: {
      a: {
        color: scheme.main.title,
      },
      "a:hover": {
        textDecoration: "underline",
      },
    },
  });

type CopiedModuleIds = { [siteId in string]?: string };

export const copiedModuleClipboard = {
  get: (siteId: string): string | undefined => {
    const ids: CopiedModuleIds = JSON.parse(
      localStorage.getItem("copiedModuleIds") ?? "{}"
    );

    return ids[siteId];
  },
  set: (siteId: string, moduleId: string) => {
    const ids: CopiedModuleIds = JSON.parse(
      localStorage.getItem("copiedModuleIds") ?? "{}"
    );

    localStorage.setItem(
      "copiedModuleIds",
      JSON.stringify({ ...ids, [siteId]: moduleId })
    );
  },
  remove: (siteId: string) => {
    const ids: CopiedModuleIds = JSON.parse(
      localStorage.getItem("copiedModuleIds") ?? "{}"
    );
    delete ids[siteId];
    localStorage.setItem("copiedModuleIds", JSON.stringify(ids));
  },
  clear: () => localStorage.removeItem("copiedModuleIds"),
};

export const insertScriptIntoHead = (url: string, targetDocument: Document) => {
  let script = targetDocument.createElement("script");
  script.src = url;
  script.defer = true;

  targetDocument.head.appendChild(script);

  return new Promise<Event>((resolve, reject) => {
    const onLoad = (event: Event) => {
      script.removeEventListener("load", onLoad);
      resolve(event);
    };

    script.addEventListener("load", onLoad);

    const onError = (event: ErrorEvent) => {
      script.removeEventListener("error", onError);
      reject(event);
    };

    script.addEventListener("error", onError);
  });
};

const checkIsWeatherWebcamModule = (
  currentModule?: Module
): currentModule is Module<WeatherWebcamModuleSettings> =>
  currentModule?.type === "WeatherWebcamModule";

export const getWebcamParentLayout = (
  modules: Modules,
  { parentId }: TranslatedModule<WebcamModuleSettings>
): WeatherWebcamLayoutVariant | undefined => {
  const parentModule = parentId ? modules.byId[parentId] : undefined;
  if (!checkIsWeatherWebcamModule(parentModule)) return;
  return parentModule.settings.layoutVariant;
};

export const getImageSubModule = ({
  languageId,
  modules,
  moduleId,
  pageId,
}: {
  modules: Modules;
  pageId: string | null;
  moduleId: string;
  languageId: Language;
}) => {
  const images = getModulesByParentId<ImageModuleSettings>(
    modules,
    moduleId,
    pageId,
    "ImageModule"
  );

  if (!images[0]) return undefined;

  return getTranslatedModule(images[0], languageId);
};

export const creatableGlobalModules: ModuleType[] = [
  "PopUpModule",
  "QuickEnquiryModule",
];

export const getIsOfferingsLayoutWithColumns = (layout: OfferingsLayout) => {
  const offeringsLayoutsWithColumns: OfferingsLayout[] = [
    "layout_1",
    "layout_2",
    "layout_3",
  ];

  return offeringsLayoutsWithColumns.includes(layout);
};

export const convertObjectValuesToStrings = (input: {
  [key: string]: number | string | undefined;
}) =>
  keys(input).reduce<{ [key: string]: string }>((acc, key) => {
    const value = input[key];
    return value === undefined
      ? acc
      : {
          ...acc,
          [key]: String(value),
        };
  }, {});

export const appendQueryToUrl = (
  url: string,
  params: { [key: string]: number | string | undefined }
): string => {
  const searchString = new URLSearchParams(
    convertObjectValuesToStrings(params)
  ).toString();
  const queryCharacter = url.includes("?") ? "&" : "?";
  return url + (searchString ? queryCharacter : "") + searchString;
};

export const getModuleSettingsCloseLink = ({
  siteId,
  pageId,
  moduleType,
  languageId,
  parentId,
}: {
  siteId: string;
  pageId: string;
  moduleType: ModuleType | undefined;
  languageId: Language;
  parentId: string | null | undefined;
}) =>
  moduleType === "PopUpModule"
    ? getURL(siteId, "pages", pageId, languageId, "pop-ups")
    : getURL(
        siteId,
        "pages",
        pageId,
        languageId,
        "modules",
        parentId ?? undefined
      );

const getHorizontalMenuMinWidth = (
  menuItems: MainMenuItem[],
  avgCharWidth: number
): number => {
  const gapBetweenMenuItems = 62;
  return menuItems.reduce<number>(
    (sum, { title }) => sum + title.length * avgCharWidth + gapBetweenMenuItems,
    0
  );
};

const getActionButtonsWidth = (actionLinks: ActionLinks) =>
  keys(actionLinks).reduce<number>((count, type) => {
    const link = actionLinks[type];
    return link ? count + 1 : count;
  }, 0) * 132;

export const getCompactMenuBreakpoint = (
  menuItems: MainMenuItem[],
  actionLinks: ActionLinks
): number => {
  const logoWidth = 120;
  const languageMenuWidth = 105;

  const breakpoint =
    logoWidth +
    getHorizontalMenuMinWidth(menuItems, 13) +
    languageMenuWidth +
    getActionButtonsWidth(actionLinks);

  return Math.max(breakpoint, 1024);
};

const getInHeaderNavMinWidth = (
  menuItems: MainMenuItem[],
  actionLinks: ActionLinks
): number => {
  const logoWidth = 180;
  return (
    logoWidth +
    getHorizontalMenuMinWidth(menuItems, 13) +
    getActionButtonsWidth(actionLinks)
  );
};

const getSplitMenuMinWidth = (menuItems: MainMenuItem[]) => {
  // South Tyrol Logo and language menu
  const sidesWidth = 200;
  const logoWidth = 200;

  return sidesWidth + getHorizontalMenuMinWidth(menuItems, 10) + logoWidth;
};

const getWideMenuMinWidth = ({
  menuItems,
  actionLinks,
  layoutVariant,
  topHeaderVariant,
  hasHeaderImage,
}: {
  menuItems: MainMenuItem[];
  actionLinks: ActionLinks;
  layoutVariant: HeaderLayoutVariant;
  topHeaderVariant: TopHeaderVariant;
  hasHeaderImage: boolean;
}): number => {
  if (layoutVariant === "all-in-header") {
    switch (topHeaderVariant) {
      case "below-header-nav":
        return getHorizontalMenuMinWidth(menuItems, 13);
      case "in-header-nav":
        return getInHeaderNavMinWidth(menuItems, actionLinks);
      default:
        return 1024;
    }
  }

  return !hasHeaderImage
    ? getCompactMenuBreakpoint(menuItems, actionLinks)
    : getSplitMenuMinWidth(menuItems);
};

export const getWideMenuBreakpoint = (params: {
  menuItems: MainMenuItem[];
  actionLinks: ActionLinks;
  layoutVariant: HeaderLayoutVariant;
  topHeaderVariant: TopHeaderVariant;
  hasHeaderImage: boolean;
}) => Math.max(getWideMenuMinWidth(params), 1024);

export const getInlineSize = (
  entries: ResizeObserverEntry[]
): number | undefined => entries[0]?.borderBoxSize[0]?.inlineSize;

export const getActiveLogoId = (
  logoIds: string[],
  logoId: string | undefined
): string | undefined =>
  logoId !== undefined && logoIds.includes(logoId) ? logoId : logoIds[0];

/**
 * Generate a random alphanumeric id with a length of 8 characters and only
 * letters as first characters (for compatibility with HTML id attributes).
 */
export const getShortId = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const lettersLength = charactersLength - 10;

  const randomValues = new Uint8Array(8);
  crypto.getRandomValues(randomValues);

  return Array.from(randomValues)
    .map((value, index) =>
      characters.charAt(
        // Only letters (not numbers) are allowed as first character of an HTML id.
        Math.floor(
          // Divide by 255 to get a base multiplier from 0 to 1.
          (value / 255) * (index === 0 ? lettersLength : charactersLength)
        )
      )
    )
    .join("");
};

export const checkIsString = (value: unknown): value is string =>
  typeof value === "string";

/**
 * Round a float number to a maximum amount of decimal places
 * See {@link https://stackoverflow.com/a/11832950}
 */
export const round = (value: number, decimalPlaces: number) =>
  Math.round((value + Number.EPSILON) * 10 ** decimalPlaces) /
  10 ** decimalPlaces;

export const threeSizes = <const>["small", "medium", "big"];

export const taskStates = <const>["ready", "pending", "failed", "successful"];

export const taskTypes = <const>["publish", "crawl", "issues-check"];

export const threeSizesSelectOptions: SelectOption<ThreeSizes>[] = [
  {
    value: "small",
    label: "klein",
  },
  {
    value: "medium",
    label: "mittel",
  },
  {
    value: "big",
    label: "groß",
  },
];

/**
 * Limit a number between min and max (inclusive)
 */
export const limit = ({
  value,
  min,
  max,
}: {
  value: number;
  min: number;
  max: number;
}) => Math.min(Math.max(value, min), max);

const convertToNavLinkWithIcon = (
  actionLinks: ActionLinks,
  type: ActionLinkType
): NavLinkWithIcon | undefined => {
  const actionLink = actionLinks[type];
  return actionLink && getNavLinkWithIconFromActionLink(actionLink, type);
};

export const getQuickLinksWithIcon = (actionLinks: ActionLinks) =>
  quickLinkTypes
    .map((type) => convertToNavLinkWithIcon(actionLinks, type))
    .filter(isDefined);

export const getBookingEnquiryLinksWithIcon = (actionLinks: ActionLinks) =>
  enquiryBookingLinkTypes
    .map((type) => convertToNavLinkWithIcon(actionLinks, type))
    .filter(isDefined);

export const getLanguageMenuItems = ({
  siteLanguages,
  pages,
  pageId,
  useFullLanguageNames,
  isPreview,
}: {
  siteLanguages: SiteLanguage[];
  pages: PageState;
  pageId: string;
  useFullLanguageNames: boolean;
  isPreview: boolean;
}): LanguageMenuItem[] => {
  const fullLanguageNames: { [lang in Language]: string } = {
    de: "deutsch",
    it: "italiano",
    en: "english",
    fr: "français",
    da: "dansk",
    es: "español",
    nl: "nederlands",
    pl: "polski",
    ru: "русский",
  };

  return getHrefLangAttributes({
    siteLanguages,
    pages,
    pageId,
    fallbackToHome: true,
    isPreview,
  }).map<LanguageMenuItem>(({ href, hrefLang, rel }) => ({
    children: useFullLanguageNames ? fullLanguageNames[hrefLang] : hrefLang,
    href,
    hrefLang,
    rel,
  }));
};

export const onTransitionEnd = (node: HTMLElement, done: () => void) =>
  node.addEventListener("transitionend", done, false);

export const overrideImagesAspectRatio = (
  imagesModule: TranslatedModule<ImagesModuleSettings>
): TranslatedModule<ImagesModuleSettings> => ({
  ...imagesModule,
  settings: {
    ...imagesModule.settings,
    mediaAspectRatio: 1.7778,
  },
});

const getQuickLink = (
  actionLinks: ActionLinks,
  type: ActionLinkType
): QuickLink | undefined => {
  const link = actionLinks[type];
  return (
    link && {
      href: link.href,
      label: link.label,
      type,
    }
  );
};

export const getVouchersSpecialsLinks = (actionLinks: ActionLinks) =>
  quickLinkTypes
    .map<QuickLink | undefined>((type) => getQuickLink(actionLinks, type))
    .filter(isDefined);

export const getEnquiryBookingLinks = (actionLinks: ActionLinks) =>
  enquiryBookingLinkTypes
    .map<QuickLink | undefined>((type) => getQuickLink(actionLinks, type))
    .filter(isDefined);

interface Size {
  width: number;
  height: number;
}

/**
 * Fits a rectangle into anothers rectangles bounds
 * and return its width
 */
export const fitRectWidth = (rect: Size, bounds: Size): number => {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;

  const isHigherRatio = rectRatio > boundsRatio;

  // First case of ternary: rect is more landscape than bounds - fit to width
  // Second case of ternary: Rect is more portrait than bounds - fit to height
  return isHigherRatio
    ? bounds.width
    : rect.width * (bounds.height / rect.height);
};

export class PageNotFoundError extends Error {
  constructor(id: string) {
    super(`Page ${id} not found`);
    this.name = "PageNotFoundError";
  }
}

class HomePageNotFoundError extends Error {
  constructor(id?: string) {
    super(id ? `Home page (${id}) not found` : "Home page not found");
    this.name = "HomePageNotFoundError";
  }
}

export class ModuleNotFoundError extends Error {
  constructor(id: string) {
    super(`Module ${id} not found`);
    this.name = "ModuleNotFoundError";
  }
}

export class ColorSchemeNotFoundError extends Error {
  constructor(id: string) {
    super(`Color scheme ${id} not found`);
    this.name = "ColorSchemeNotFoundError";
  }
}

export class IssueNotFoundError extends Error {
  constructor(id: string) {
    super(`Issue ${id} not found`);
    this.name = "IssueNotFoundError";
  }
}

export class SEOURLNotFoundError extends Error {
  constructor(id: string) {
    super(`SEO URL ${id} not found`);
    this.name = "SEOURLNotFoundError";
  }
}

export const logoSizeMultipliers: { [key in ThreeSizes]: number } = {
  small: 0.8,
  medium: 1,
  big: 1.4,
};

export const checkIsDragDropData = <T extends keyof DragDropTypes>(
  ref: DataRef,
  type: T
): ref is MutableRefObject<DragDropTypes[T]> =>
  (<MutableRefObject<DragDropTypes[T] | undefined>>ref).current?.type === type;
