import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import {
  ButtonModuleSettings,
  ColorScheme,
  OverlayModuleSettings,
  StoreState,
  TextStyle,
  TranslatedModule,
} from "../../types/index.js";
import { getTranslatedButtonModules } from "../../utils/utils.js";
import MultilineText from "../MultilineText.js";
import ButtonModule from "./ButtonModule.js";

interface Props {
  translatedModule: TranslatedModule<OverlayModuleSettings>;
  pageId: string;
  scheme: ColorScheme;
  isPreview: boolean;
  isSlider: boolean;
}

interface StateProps {
  buttons: TranslatedModule<ButtonModuleSettings>[];
}

const getStyles = (
  scheme: ColorScheme,
  textStyle: TextStyle,
  type: "primary" | "secondary"
): NestedCSSProperties => {
  const backgroundColor = scheme[type].background;

  const stylePlain: NestedCSSProperties = {
    color: scheme[type].text,
  };
  const styleBackground: NestedCSSProperties = {
    ...stylePlain,
    boxShadow:
      `7px 3px 0 ${backgroundColor}, -7px 3px 0 ${backgroundColor}, ` +
      `7px 0 0 ${backgroundColor}, -7px 0 0 ${backgroundColor}`,
    backgroundColor: backgroundColor,
  };
  return textStyle === "background" ? styleBackground : stylePlain;
};

const OverlayModule: FunctionComponent<Props & StateProps> = ({
  translatedModule: {
    translation: {
      settings: { title, subtitle },
    },
    settings: { verticalAlign, horizontalAlign, textStyle, fontSize },
  },
  isPreview,
  scheme,
  isSlider,
  buttons,
}) => {
  if (!title && !subtitle && buttons.length === 0) return null;

  return (
    <div
      className={ClassNames(
        "ImageOverlay",
        `ImageOverlay--padding-${isSlider ? "large" : "small"}`,
        `FlexAlignH--${horizontalAlign}`,
        `FlexAlignV--${verticalAlign}`
      )}
    >
      <div
        className={ClassNames(
          "ImageOverlay__Inner",
          `ImageOverlay__Inner--size-${fontSize}`
        )}
      >
        <div
          className={ClassNames(
            "ImageOverlay__Text",
            `ImageOverlay__Text--${textStyle}`,
            {
              "ImageOverlay__Text--offset-left":
                textStyle === "background" && horizontalAlign === "left",
              "ImageOverlay__Text--offset-right":
                textStyle === "background" && horizontalAlign === "right",
            }
          )}
        >
          {title && (
            <div className="ImageOverlay__Title">
              <span
                className={ClassNames(
                  "ImageOverlay__TitleInner",
                  style(getStyles(scheme, textStyle, "primary"))
                )}
              >
                <MultilineText text={title} />
              </span>
            </div>
          )}
          {subtitle && (
            <div className="ImageOverlay__Subtitle">
              <span
                className={style(getStyles(scheme, textStyle, "secondary"))}
              >
                <MultilineText text={subtitle} />
              </span>
            </div>
          )}
        </div>
        {buttons.length > 0 && (
          <div
            className={ClassNames(
              "ButtonGroup",
              `ButtonGroup--align-${horizontalAlign}`
            )}
          >
            {buttons.map((button) => (
              <ButtonModule
                key={button.id}
                scheme={scheme}
                isPreview={isPreview}
                translatedModule={button}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (
  { modules }: StoreState,
  { translatedModule, pageId }: Props
): StateProps => {
  const {
    id: moduleId,
    translation: { languageId },
  } = translatedModule;

  const buttons = getTranslatedButtonModules({
    modules,
    languageId,
    pageId,
    moduleId,
  });

  return {
    buttons,
  };
};

export default connect(mapStateToProps)(OverlayModule);
