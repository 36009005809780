import { FunctionComponent } from "react";

interface Props {
  data: Object;
}

const JsonLd: FunctionComponent<Props> = ({ data }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org",
        ...data,
      }),
    }}
  />
);

export default JsonLd;
