import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import {
  Accommodation,
  ActionLinks,
  ColorScheme,
  Language,
} from "../types/index.js";
import { getActionAndContactLinks } from "../utils/utils.js";
import Icon from "./Icon.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  actionLinks: ActionLinks;
  accommodation: Accommodation;
  scheme: ColorScheme;
  languageId: Language;
}

const HeaderActionBottomBar: FunctionComponent<Props> = ({
  accommodation,
  actionLinks,
  isPreview,
  scheme,
  languageId,
}) => {
  const items = getActionAndContactLinks(
    accommodation,
    actionLinks,
    languageId
  );
  if (!items.length) return null;

  return (
    <div
      className={ClassNames(
        "HeaderActionBottomBar",
        style({
          backgroundColor: scheme.main.background,
          color: scheme.main.text,
        })
      )}
    >
      <ul className="HeaderActionBottomBar__List">
        {items.map(({ children, icon, href }, index) => (
          <li className="HeaderActionBottomBar__ListItem" key={index}>
            <SmartLink
              isPreview={isPreview}
              anchorAttrs={{
                href,
                className: "HeaderActionBottomBar__LinkItem",
              }}
            >
              <Icon glyph={icon} className="HeaderActionBottomBar__Icon" />
              <span className="HeaderActionBottomBar__Text">{children}</span>
            </SmartLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderActionBottomBar;
