import { NestedCSSProperties, NestedCSSSelectors } from "typestyle/lib/types";
import { APISite } from "../../server/types/index.js";
import { Fonts, ThreeSizes } from "../types/index.js";
import { round } from "./utils.js";

type FontType = "title" | "body" | "dynamic";

const getFontSizeRules = ({
  isPreview,
  baseFontSize,
  fontScale,
  sizeSetting,
  smallerOnNarrow,
}: {
  isPreview: boolean;
  baseFontSize: number;
  fontScale: number;
  sizeSetting: ThreeSizes;
  smallerOnNarrow: boolean;
}): NestedCSSProperties => {
  const fontSize = getFontSize({ baseFontSize, fontScale, sizeSetting });
  const rules: NestedCSSProperties = {
    fontSize: round(fontSize * (smallerOnNarrow ? 0.75 : 1), 2) + "em",
    $nest: smallerOnNarrow
      ? {
          [isPreview ? ".Query--large &" : "@media (min-width: 1024px)"]: {
            fontSize: round(fontSize, 2) + "em",
          },
        }
      : {},
  };
  return rules;
};

const getFontScale = (fonts: Fonts, family: string) =>
  fonts.byFamily[family]?.scale ?? 1;

const getFontSize = ({
  baseFontSize,
  fontScale,
  sizeSetting,
}: {
  baseFontSize: number;
  fontScale: number;
  sizeSetting: ThreeSizes;
}) => {
  const sizesMap: { [key in ThreeSizes]: number } = {
    small: 0.95,
    medium: 1,
    big: 1.05,
  };

  return (
    Math.round(baseFontSize * fontScale * sizesMap[sizeSetting] * 100) / 100
  );
};

export const getDynamicTitleFontsCSS = ({
  fonts,
  site,
  isPreview,
}: {
  isPreview: boolean;
  fonts: Fonts;
  site: APISite;
}): NestedCSSProperties => {
  interface FontSizeBySelector {
    [selector: string]: { size: number | undefined; type: FontType };
  }

  const fontSizeBySelector: FontSizeBySelector = {
    ".ImageOverlay__Title": { size: 3.5, type: "title" },
    ".ImageOverlay__Subtitle": { size: 1.6, type: "dynamic" },
    ".Module__Title": { size: 2, type: "title" },
    "h1.Module__Title": { size: 2.4, type: "title" },
    ".Module__Subtitle": { size: 1.7, type: "dynamic" },
    ".MultiBox__Tile__Headings": { size: undefined, type: "title" },
    ".FooterModule__Title": { size: 1.3, type: "title" },
    ".Bs-Collapse__HeaderWrap": { size: 1, type: "title" },
    ".ModuleConsentBox__Title": { size: 1.5, type: "title" },
    ".Module__BodyText": { size: 1.1, type: "body" },
    ".AccommodationFeaturesModule__List": { size: 1.1, type: "body" },
    ".Module__BodyText h2": { size: 1.5, type: "dynamic" },
    ".Module__BodyText h3": { size: 1.17, type: "dynamic" },
  };

  const nestedRules = Object.entries(
    fontSizeBySelector
  ).reduce<NestedCSSSelectors>(
    (carry, [selector, { size: baseFontSize, type: fontType }]) => {
      const isTitleFont =
        (site.titleFontForSubtitle && fontType === "dynamic") ||
        fontType === "title";
      const fontScale = getFontScale(
        fonts,
        isTitleFont && site.titleFontFamily
          ? site.titleFontFamily
          : site.fontFamily
      );

      carry[selector] = {
        fontFamily:
          isTitleFont && site.titleFontFamily
            ? `"${site.titleFontFamily}", sans-serif`
            : undefined,
        ...(baseFontSize
          ? getFontSizeRules({
              baseFontSize,
              fontScale,
              isPreview,
              sizeSetting:
                fontType === "body" ? site.fontSize : site.titleFontSize,
              smallerOnNarrow: fontType !== "body",
            })
          : {}),
      };
      return carry;
    },
    {}
  );

  return { $nest: nestedRules };
};
