import ClassNames from "classnames";
import { FunctionComponent, useRef } from "react";
import {
  PortalWidgetSettings,
  WidgetInstance,
  WindowState,
} from "../types/index.js";
import HgvWidgetContainer from "./HgvWidgetContainer.js";

interface Props {
  settings: PortalWidgetSettings;
  className?: string;
}

const PortalWidget: FunctionComponent<Props> = ({ className, settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    if (!ref.current) return;

    const { BookingSüdtirol } = window as unknown as WindowState;
    widgetInstanceRef.current = BookingSüdtirol.Widgets.Portal(
      ref.current,
      settings
    );
  };

  return (
    <div className={ClassNames("Module__Wrapper", className)}>
      {settings.id && (
        <HgvWidgetContainer
          widgetInstance={widgetInstanceRef.current}
          initWidget={initWidget}
          settings={settings}
          enableLazyload={true}
        >
          <div className="PortalModule__Container" ref={ref} />
        </HgvWidgetContainer>
      )}
      {!settings.id && <div className="PortalModule__Empty">Portalwidget</div>}
    </div>
  );
};

export default PortalWidget;
