import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { style } from "typestyle";
import globalTranslations from "../../i18n/index.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  PeerTvModuleSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
  getTranslations,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<PeerTvModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const PeerTvModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id: moduleId,
    translation: {
      languageId,
      settings: { title, subtitle, scriptUrl, caption, websiteUrl },
    },
    settings: { textAlign, size },
  },
  scheme,
  isFirstOnPage,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const i18n = getTranslations(languageId, globalTranslations);
  const prevScriptUrlRef = useRef<string>();
  const supportedLanguage = getSupportedLanguage(languageId, [
    "de",
    "it",
    "en",
  ]);
  const requiredFieldsFilled = Boolean(scriptUrl && caption && websiteUrl);

  useEffect(() => {
    if (
      !isLazyloaded ||
      !scriptUrl ||
      prevScriptUrlRef.current === scriptUrl ||
      !requiredFieldsFilled
    ) {
      return;
    }

    const script = document.createElement("script");
    script.src = scriptUrl;
    ref.current?.insertAdjacentElement("afterbegin", script);
    prevScriptUrlRef.current = scriptUrl;
  }, [isLazyloaded, scriptUrl, requiredFieldsFilled]);

  return (
    <ModuleWithHeadings
      className="PeerTvModule"
      id={moduleId}
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
      title={title}
      subtitle={subtitle}
    >
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper">
          <ModuleHeadings
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            textAlign={textAlign}
            title={title}
            subtitle={subtitle}
          />
        </div>
        <div
          className={ClassNames("PeerTvModule_Content", "Module__Wrapper", {
            "PeerTvModule_Content--small": size === "small",
            "PeerTvModule_Content--big": size === "big",
            "PeerTvModule_Content--full-width": size === "fullWidth",
          })}
          ref={ref}
        >
          {requiredFieldsFilled ? (
            <p>
              <a
                className={style({
                  color: scheme.main.title,
                })}
                href={websiteUrl}
                rel="noopener"
                target="_blank"
              >
                {caption}
              </a>{" "}
              {i18n.on}{" "}
              <a
                className={style({
                  color: scheme.main.title,
                })}
                href={"https://www.peer.tv/" + supportedLanguage}
                rel="noopener"
                target="_blank"
              >
                Peer.tv
              </a>
            </p>
          ) : (
            <Icon glyph="peer-tv" className="PeerTvModule__Icon" />
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps);

export default connector(PeerTvModule);
