import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ColorScheme,
  ContainerQueries,
  ImageModuleSettings,
  ImagesModuleSettings,
  OverlayModuleSettings,
  TranslatedModule,
} from "../types/index.js";
import Icon from "./Icon.js";
import ImageModule from "./Modules/ImageModule.js";
import { getBreakpointImageColumnsCount } from "./Modules/ImagesModule.js";
import OverlayModule from "./Modules/OverlayModule.js";
import Slider from "./Slider.js";

interface Props {
  pageId: string;
  images: TranslatedModule<ImageModuleSettings>[];
  isPreview: boolean;
  isInsideHeader: boolean;
  scheme: ColorScheme;
  translatedModule: TranslatedModule<ImagesModuleSettings>;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
  activeModuleId: string | undefined;
  subModuleIsActive: boolean;
  slidesToShow: number;
  queries: ContainerQueries;
}

type ArrowComponent = FunctionComponent<{
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

const PrevArrow: ArrowComponent = ({ onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--prev ContentSlider__Control"
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-left" />
  </button>
);

const NextArrow: ArrowComponent = ({ onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--next ContentSlider__Control"
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-right" />
  </button>
);

const ImageSlider: FunctionComponent<Props> = ({
  images,
  isPreview,
  translatedModule: {
    settings: {
      mediaAspectRatio,
      autoPlay,
      autoPlayDelay,
      sliderEffect,
      columnsCount,
      gap,
    },
  },
  scheme,
  pageId,
  overlayModule,
  activeModuleId,
  subModuleIsActive,
  slidesToShow: slidesToShowProp,
  isInsideHeader,
  queries,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const [maxSelectedIndex, setMaxSelectedIndex] = useState(0);
  const [currentIndexes, setCurrentIndexes] = useState([0, 1]);
  const slidesToShow =
    sliderEffect === "fade"
      ? 1
      : getBreakpointImageColumnsCount(queries, slidesToShowProp);

  const showArrows = images.length > slidesToShow;

  useEffect(() => {
    slider?.on("select", () => {
      const currentIndex = slider?.selectedScrollSnap() ?? 0;
      setMaxSelectedIndex((maxSelectedIndex) =>
        currentIndex > maxSelectedIndex ? currentIndex : maxSelectedIndex
      );
      setCurrentIndexes([currentIndex - 1, currentIndex, currentIndex + 1]);
    });
  }, [slider]);

  const runAutoplay = useCallback(() => {
    slider?.canScrollNext() ? slider.scrollNext() : slider?.scrollTo(0);
  }, [slider]);

  const { play, stop } = useRecursiveTimeout(runAutoplay, autoPlayDelay * 1000);

  useEffect(() => {
    slider?.on("pointerDown", stop);
  }, [stop]);

  useEffect(() => {
    (!autoPlay || subModuleIsActive) && stop();
    !subModuleIsActive && autoPlay && play();
  }, [play, stop, autoPlay, subModuleIsActive]);

  return (
    <div className="SliderModule">
      {showArrows && (
        <PrevArrow
          onClick={() => {
            stop();
            if (!slider) return;
            const slidesCount = slider.slideNodes().length;
            slider.canScrollPrev()
              ? slider.scrollPrev()
              : slider.scrollTo(slidesCount);
          }}
        />
      )}
      <Slider
        containerClassName={ClassNames("SliderModule__Container", {
          [`SliderModule__Container--gap-${gap}`]: sliderEffect === "slide",
        })}
        slideClassName="SliderModule__SlideOuter"
        isPreview={isPreview}
        onSliderLoaded={setSlider}
        slidesToShow={slidesToShow}
        sliderEffect={sliderEffect}
        activePreviewSlideId={activeModuleId}
        slideIds={images.map(({ id }) => id)}
      >
        {images.map((image, index) => (
          <div className="SliderModule__Slide" key={image.id}>
            {(index <= maxSelectedIndex + columnsCount ||
              sliderEffect === "slide") && (
              <ImageModule
                key={image.id}
                aspectRatio={mediaAspectRatio}
                translatedModule={image}
                width={1920}
                lazyLoad={
                  !(
                    (index === 0 && isInsideHeader) ||
                    currentIndexes.includes(index)
                  )
                }
                sizes="100vw"
                isPreview={isPreview}
                isSlider={true}
                pageId={pageId}
                scheme={scheme}
                showOverlay={!overlayModule}
              />
            )}
          </div>
        ))}
      </Slider>
      {showArrows && (
        <NextArrow
          onClick={() => {
            stop();
            slider?.canScrollNext()
              ? slider?.scrollNext()
              : slider?.scrollTo(0);
          }}
        />
      )}

      {overlayModule && (
        <OverlayModule
          isPreview={isPreview}
          scheme={scheme}
          isSlider={true}
          translatedModule={overlayModule}
          pageId={pageId}
        />
      )}
    </div>
  );
};

const useRecursiveTimeout = (callback: () => void, delay: number) => {
  const [isRunning, setIsRunning] = useState(false);
  const stop = useCallback(() => setIsRunning(false), [setIsRunning]);
  const play = useCallback(() => setIsRunning(true), [setIsRunning]);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isRunning) return;
    let id: NodeJS.Timeout;

    const tick = () => {
      if (!isRunning) return clearTimeout(id);
      savedCallback.current();
      requestAnimationFrame(() => (id = setTimeout(tick, delay)));
    };
    requestAnimationFrame(() => (id = setTimeout(tick, delay)));

    return () => {
      if (id) clearTimeout(id);
      stop();
    };
  }, [isRunning, delay, stop]);

  return { play, stop };
};

export default ImageSlider;
